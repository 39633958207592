<!-- HTML -->
<template>
<div class='arkly-panel-container' ref='arklyPanelContainer'>
	<!-- Content -->
	<div class='panel-content' ref='panelContentNotIndex'>

		<div class='h-center section' id='search' v-if='!isMobile'>
			<search
				v-if='!isMobile'
				color='black'
				size='medium'
				:class="{'fixed-search': $route.params.id == 'Location'}"
				:placeholder="textContent['property_view']['search-bar']['label']"
			/>
		</div>

		<div class='section' id='street-view' v-show='!isMobile'>
			<street-view 
				v-if='coordinates'
				:coordinates='coordinates'
				:hasZoom='false'
			/>
		</div>

		<div class='section' id='info'>	

			<div id='location-info' ref='locationInfo'>
				<h5 style="white-space: pre-line"><strong> {{ fullAddress }} <br> {{  }}</strong></h5>
				<span v-if='attributes'>
					<flood-info
						:floodRisk='floodRisk(controls,textContent)'
						:floodZone='floodZone'
						:groundElevation='attributes.groundElevation.mean'
						:floodElevation='attributes.staticBaseFloodElevation'
					/>
				</span>		
			</div>
				
			
			<div id='attribute-actions'>
				<nav-menu v-if="textContent['buttons']"/>
			</div>	

			<div class='h-center' v-show="$route.name != 'puertoRico'" id='recommended-solution' ref='recommendedSolution'>
				<h6> {{ textContent['property_view']['recommended']['label'] }} </h6> 
				<btn 
					label="recommended-solution"
					variant='arkly' 
					width='72px !important' 
					icon='arrow-down-right'
					@click='goToRecommendedSolution'
				/>
				</div>
		</div>

		<insurance v-show="$route.name != 'puertoRico'" class='section' style='containerHeight' ref='insuranceContent'/>

		<mitigation v-show="$route.name != 'puertoRico'" class='section' style='containerHeight'/>	

		<sidepanel-footer v-if="textContent['brand'] && textContent['footer']"/>

	</div>

</div>
</template>

<!-- JavaScript -->
<script>
import InlineSvg from 'vue-inline-svg'
import Search from '@/components/Search'
import StreetView from '@/components/StreetView.vue'
import Button from '@/components/Button'
import Mitigation from '@/components/sidepanel/MitigationContent'
import Insurance from '@/components/sidepanel/InsuranceContent'
import FloodInfo from '@/components/arkly/FloodInfo'
import Share from '@/components/arkly/Share'
import BugReport from '@/components/BugReport'
import Footer from '@/components/sidepanel/Footer'
import NavMenu from '@/components/arkly/UserActions'
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { nextTick } from "vue"
import { event } from 'vue-gtag'

export default {
	name: 'arkly-sidepanel',
	props: {
		isPuertoRico: {
			type: Boolean,
		},
		test: {
			type: String,
		},
	},
	components: {
		'inline-svg': InlineSvg,
		'search': Search,
		'street-view': StreetView,
		'flood-info': FloodInfo,
		'btn': Button,	
		'mitigation': Mitigation,
		'insurance': Insurance,
		'bug-report': BugReport,
		'share': Share,
		'sidepanel-footer': Footer,
		'nav-menu': NavMenu,
	},
	data() {
		return{
			headerAtTop: false,
			originalHeaderTop: 410,
			residentialIsTrue: true,
			notResidentialIsTrue: false,
			residentialIsTrueInsurance: false,
			residentialIsTrueElevate: false,
			currentView: 'home',
			isPanelVisible: this.isSidePanelVisible,
			containerHeight: {},
		}
	},
	mounted() {	

		if (!this.isRouteLocation) {
			this.isPanelVisible = true
		}
		this.addComponentRef('location-info', this.$refs.locationInfo) 
		this.addComponentRef('recommended-solution', this.$refs.recommendedSolution)
		this.addComponentRef('panel-content-not-tutorial', this.$refs.panelContentNotIndex)
		if (this.isMobile) {
			this.addComponentRef('panel-content', this.$refs.panelContentNotIndex)
		}
		nextTick(() => {
			this.containerHeight = {'min-height': `${window.innerHeight - (this.componentRefs['header'].getBoundingClientRect()).bottom}px`}
		})
	},
	created () {
		if (this.isMobile) {
			window.addEventListener('scroll', this.handleScroll);
		}
	},
	unmounted () {
		if (this.isMobile) {
			window.removeEventListener('scroll', this.handleScroll);
		}
	},
	computed: {
		recommendedAction() {
			if (this.attributes) {
				if (this.attributes.useType == 'Residential'){
					this.residentialIsTrue = true
					this.notResidentialIsTrue = false
					var insuranceCost = 0
					if (this.meanNFIP) {
						insuranceCost = this.meanNFIP
					}
					var houseLiftingCost = 0
					if (this.houseLiftingLoan) {
						houseLiftingCost = this.houseLiftingLoan.yearlyCost
					}
					if (insuranceCost <= houseLiftingCost){
						//console.log('insurance is cheaper')
						this.residentialIsTrueInsurance = true
						this.residentialIsTrueElevate = false
					} else {
						//console.log('lifting is cheaper')
						this.residentialIsTrueInsurance = false
						this.residentialIsTrueElevate = true
					}
				} else {
					this.notResidentialIsTrue = true
					this.residentialIsTrueInsurance = false
					this.residentialIsTrueElevate = false
					this.residentialIsTrue = false

				}
			}
		},
		BFE() {
			var currBFE = this.attributes.staticBaseFloodElevation
			if (currBFE == -9999){
				return ""
			} else {
				return "Base Flood Elevation: " + currBFE + " ft"
			}
		},
		loanCost() {
			if (this.houseLiftingLoan) {
				return 	this.abbreviateNumbers(this.houseLiftingLoan.yearlyCost, 2, false)
			} else {
				return
			}
		},
		floodZoneDescription() {
			if (this.floodZone) {
				if (this.floodZone.startsWith('A') || this.floodZone.startsWith('V')){
					return this.floodZone.split('|')[0] + ' (High-Risk Flood Zone)'
				} else {
					return this.floodZone.split('|')[0] + ' (Non-High-Risk Flood Zone)'
				}
			} 
		},
		borderRadius() {
			if (this.isMitigationVisible) {
				return '0'
			} else {
				return '0.5rem'
			}
		},
		expandDirection() {
			if (this.isPanelVisible) {
				event('panel_visibile', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('panel_visibility','location ID: ' + window.location.href.split('/').slice(-1) + ', expand sidepanel')
				return null 
			} else {
				event('panel_closed', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('panel_visibility','location ID: ' + window.location.href.split('/').slice(-1) + ', hide sidepanel')
				return 'rotate(180deg)'
			}
		},
		...mapState(useConfigStore, ['isRouteLocation','textContent', 'componentRefs','isSidePanelVisible', 'controls', 'isMobile', 'sidePanel']),
		...mapState(useSurfStore, ['updateSurf','coordinates', 'location', 'fullAddress', 'attributes', 'FFE', 'meanNFIP', 'meanNFIPstr', 'houseLiftingLoan', 'AAL', 'abbreviateNumbers', 'floodZone']),
	}, 
	watch: {
		containerHeight(newHeight) {

		},
		'sidePanel': {
			handler: function(newValue) {

			},
			deep: true,
		},
		'$refs': {
			handler: function(result) {
				//console.log(this.$refs.search.getBoundingClientRect())
				//console.log(this.$refs)
			},
			deep: true,
		},
		'$route': {
			handler: function(result) {

			},
			deep: true,
		},
		fullAddress: function(newAddress) {
			//console.log(newAddress)
		},
		isPanelVisible: function(newVisibility){
			//console.log('is panel vis call with value ',newVisibility)
			this.setSidePanelVisibility(newVisibility)
			
		},
		isSidePanelVisible: function(newVisibility) {
			this.isPanelVisible = newVisibility
		},
	},
	methods: {
		goToRecommendedSolution() {
			event('recommended_solution', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('recommended_solution','location ID: ' + window.location.href.split('/').slice(-1))	
			this.componentRefs['insure-your-risk'].scrollIntoView()
		},
		openPanel(view) {
			switch(view) {
				case 'flood-zone':
					this.setSidePanelView('flood-zone')
					break;
				case 'flood-risk':
					event('flood_risk_subpage', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
					confection.submitEvent('flood_risk_subpage','location ID: ' + window.location.href.split('/').slice(-1))
					this.setSidePanelView('flood-risk')
					break;
				case 'insurance':
					event('insurance_subpage', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
					confection.submitEvent('insurance_subpage','location ID: ' + window.location.href.split('/').slice(-1))
					this.setSidePanelView('insurance')
					break;
				case 'lift-house':
					event('mitigation_subpage', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
					confection.submitEvent('mitigation_subpage','location ID: ' + window.location.href.split('/').slice(-1))
					this.setSidePanelView('lift-house')
					break;
				default:
					break;
			}
		},
		closePanel() {
			event('subpage_close', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('subpage_close','location ID: ' + window.location.href.split('/').slice(-1) + ', subpage closed: ' + this.currentView)
			this.currentView = 'home'
		},
		...mapActions(useConfigStore, ['goHome','setSidePanelVisibility', 'setSidePanelView', 'setTutorialVisibility', 'checkout', 'addComponentRef', 'goToRocketFlood', 'scheduleExpertMeeting']),	
		...mapActions(useSurfStore, ['floodRisk']),	
	},
}
</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/arkly.scss";

$blue: #7084EC;
$red: #D34D45;
$light-red: #E9ABA9;
$yellow: #D2B360;

hr {
	width: 100%;
}

/*
h1 {
	//font-family: $header-family;
	font-size: 30px;
}

h2 {
	//font-family: $header-family';
	margin: 0;
	text-shadow: none;
}

h3 {
	//font-family: $header-family';
	font-size: 16px;
	margin: 0px;
}
*/

p {
	margin: 0;
}

strong {
	font-weight: bold;
}

.info-bubble {
	fill: white;
	height: 1.2rem;
	float: inline-start;
	margin-top: 1px !important;
	padding-right: 1px !important;
}
////////////////////////////////////////////////////
// Collapse Button
///////////////////////////////////////////////////
.collapse-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	min-width: 24px;
	height: 120px;
	border-radius: 0 $border-radius $border-radius 0;
	background-color: white;
	//filter: brightness(100%);
	grid-area: collapse;
	z-index: 0;
	grid-area: collapse;
}

.collapse-btn:hover {
	filter: brightness(80%);
}	

.collapse-icon {		
	fill: $grey;
	width: 14px;
	height: auto;
}

.collapse-icon svg {
	fill: white;
}

///////////////////////////////////////////////////
// Section 
//////////////////////////////////////////////////

.section {
	max-width: 100%;
	max-height: 100%;
	background-color: $sidepanel-color;
	border-radius: 5px;
}


///////////////////////////////////////////////////
// Panel Content
///////////////////////////////////////////////////

.panel-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: $padding;
	background-color: transparent; //$sidepanel-color;
	//border-top-right-radius: $border-radius;
	border-bottom-right-radius: 0;
	max-width: $sidepanel-width;
	//height: 100%;
	max-width: 100%;
	width: 400px;
	//padding: 0 20px;
	grid-area: controls;
	overflow-y: auto;
	overflow-x: clip;
	overscroll-behavior-y: none;
}

.btn-container button {
	width: $default-btn-width;
}


///////////////////////////////////////////
// Info
//////////////////////////////////////////

#search {
	border-radius: $border-radius;
	height: auto;
	width: 100%;
	grid-area: search;
}

#street-view {
	height: 100%;
	//max-height: 300px;
	width: 100%;
	border: 0px solid black;
	border-radius: $border-radius;
	grid-area: street;
}

#location-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: $padding;
	text-align: left;
	grid-area: location;
}

#attribute-actions {
	position: relative;
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: center;
	align-items: space-between;
	grid-area: actions;
}

#recommended-solution {
	display: flex;
	justify-content: space-around;
	align-items: center;
	grid-area: rec;
}

#recommended-solution h6 {
	white-space: nowrap;
	color: black;
}

#recommended-solution .btn-container {
	width: auto;
}

#info {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr repeat(3, auto);
	grid-gap: 10px;
	width: 100%;
	padding-bottom: 5px;
	grid-area: info;
	grid-template-areas:
		" street   "
		" location "
		" actions  "
		" rec      ";
	padding: 20px;
}



@media only screen and (max-width: $mobile-breakpoint) {


	#info {
		display: grid;
		flex-direction: column;
		grid-template-columns: 1fr auto;
		grid-template-rows: 1fr repeat(2, auto);
		grid-template-areas:
			" street   street  "
			" location actions "
			" rec      rec     ";
		grid-gap: 15px;
		width: 100%;
		padding: 20px;
		padding-bottom: 10px;
		padding-top: 0px;
		margin-top: -10px;
	}

	#header {
		position: fixed;
		top: 0;
		height: 136px;
	}	

	.collapse-btn {
		display: none;
	}

	.panel-content {
		width: 100vw;
		max-width: 100vw;
		//margin: 10px;
		overflow-y: visible;
		padding: 0;
	}

	#street-view {
		max-height: 200px;
	}

	.panel-content {
		overflow-y: visible;
	}

	.sidepanel-container {
		position: relative;
		height: auto;
		min-width: 0;
		width: 100vw;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
		grid-template-areas: 
			" header   "
			" controls ";
		z-index: 99;
		margin: 0;
		padding: 0;
		overflow-x: visible;
	}

	.sidepanel-container > * {
		min-width: 0 !important;
	}

	.sidepanel-container .section {
		align-items: center;
	}

}


</style>
