<!-- HTML -->
<template>
<div class='container' :style="footerStyle">
	<!--<hr v-if='isMobile && mapConfig.arklyRoutes.includes($route.name)'>-->
	
	<div :class="(variant == 'arklyHome') ? 'list-homepage' : 'list'">
		<p class='link' @click='goToTerms'> 
			{{ textContent['footer']['terms-of-use']['label'] }}
		</p>
		<p class='link' @click='goToPrivacy'> 
			{{ textContent['footer']['privacy-policy']['label'] }}
		</p>
		<p class='link' @click='goToHighTide'> 
			{{ textContent['footer']['about']['label'] }}
		</p>	
		<p class='link' @click='goToContact'> 
			{{ textContent['footer']['contact']['label'] }}
		</p>
		<p class='link' :style="(variant != 'arklyHome') ? 'margin-left: 84px' : ''"> 
			
			<inline-svg	
				class='facebook'
				:src="require('@/assets/icons/facebook.svg')"
				title='facebook'
				@click='goToFacebook'
			/>
		</p>
		<!--
		<select v-if="$route.name == 'puertoRicoHome'" v-model='currentLanguage'>
			<option value='en-US'>English</option>
			<option value='es-ES'>Español</option>
		</select>-->
	</div>
	<!--	
	<p>State: {{ connected }}</p>

	 <button @click="connect()">Connect</button>
	 <button @click="disconnect()">Disconnect</button>
	-->
	<div class='arkly-logo logos' v-if="(variant != 'hightide')">
			<inline-svg
				title='Arkly Logo'
				:src="require('@/assets/icons/arkly-logo-watermark-trademark.svg')"
				@click="goHome('footer')"
			/>
		</div>
	<span class="horizontal-pair-spaced">
		<div class='hightide-logo logos'>
			<inline-svg
				title='HighTide Logo'
				:src="require('@/assets/icons/Powered-by-HighTide.svg')"
				@click="goToHighTide"
			/>
		</div>
		<div class='bcorp-logo logos'>
			<inline-svg
				title='B Corp Logo'
				:src="require('@/assets/icons/B-Corp-Logo-Black-RGB.svg')"
				@click="goToBCorp"
			/>
		</div>
	</span>
		<div class='hightide-logo logos'>
			<inline-svg
				title='Echelon Logo'
				:src="require('@/assets/icons/Echelon-Data-Partner.svg')"
				@click="goToEchelon"
			/>
		</div>
	<div v-if = "$route.name == 'puertoRico' || $route.name == 'puertoRicoHome'" class='ccrs-logo'>
		<p style="margin: 40px auto;">{{ textContent['footer']['partner-text']['label'] }}</p>
		<inline-svg
				v-if = "userLanguage == 'es-ES'"
				title='CCRS Logo'
				:src="require('@/assets/icons/CCRS_cobranded_logo_color_esp.svg')"
				@click="goToCCRS"
			/>
		<inline-svg
				v-if = "userLanguage == 'en-US'"
				title='CCRS Logo'
				:src="require('@/assets/icons/CCRS_cobranded_logo_color_eng.svg')"
				@click="goToCCRS"
			/>
	</div>

	<div v-if="(variant == 'hightide')" class='copyright'>
		<p> &copy; {{ textContent['brand']['copywriteTopHighTide'] }}</p>
		<p> DBA HighTide Intelligence. {{ textContent['brand']['copywrite'] }} </p>
	</div>
	<div v-else class='copyright'>
		<p> &copy; {{ textContent['brand']['copywriteTop'] }} </p>
		<p> {{ textContent['brand']['copywrite'] }} </p>
	</div>
</div>
</template>

<!-- JavaScript -->
<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import InlineSvg from 'vue-inline-svg'

export default {
	name: 'sidepanel-footer',
	components: {
		'inline-svg': InlineSvg,
	},
	data() {
		return {
			curveIcon: 'sidepanel-curve',
		}
	},
	mounted() {
		//console.log(this.$route.name)
	},
	props: {
		variant: {
			type: String,
			default: 'default'
		},
	},
	computed: {
		footerStyle() {
			if (this.variant == 'arklyHome') {
				var width =  '100vw'
				var color = 'white'
			} else {
				//var width = '400px'
				var color = '#F2F4F5'
			}
			return {'background-color' : color, 'width' : width}
		},
		/*
		connected() {
			return socketState.connected
		},
		*/
		currentLanguage: {
			get() {
				return this.userLanguage
			},
			set(newLanguage) {
				this.setUserLanguage(newLanguage)
			},
		},
		curveSvgSRC() {
			return require(`../../assets/icons/${this.curveIcon}.svg`)
		},
		...mapState(useConfigStore, ['mapConfig','textContent', 'isMobile', 'userLanguage']),
		...mapState(useSurfStore, ['rocketflood']),
	},
	methods: {
		...mapActions(useConfigStore, ['goHome','goToEchelon','goToBCorp','goToCCRS','goToHighTide', 'goToTerms', 'goToPrivacy', 'goToContact', 'goToFacebook', 'setUserLanguage'])
	}
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/arkly.scss";

.link {
	cursor: pointer;
}

.link:hover {
	color: #6177ED;
	//font-weight: bold;
}

.horizontal-pair {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	width: auto;
}

.horizontal-pair-spaced {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	width: 100%;
	max-width: 350px;
}

.facebook {
	padding-top: 1px;
	padding-left: 5px;
	float: left;
	height: 25px;
	width: 30px;
}

.facebook:hover {
	//height: 30px;
	cursor: pointer;
	opacity: 75%;
}

#sidepanel-curve {
	min-width: 405px;	
	height: auto;
	fill: white;
	color: white;
}

#sidepanel-curve svg {
	min-width: 405px;
	color: white;
}

.logos {
	//padding: 0 20px 20px 20px;
	margin-top: 40px;
	display: flex;
	gap: 20px;
	cursor: pointer;
	width: auto;
	justify-content: center;
	align-items: center;

}
.logos svg:hover {
	//height: 120px;
	//color: grey;
	opacity: 75%;
	//filter: invert(58%) sepia(92%) saturate(4322%) hue-rotate(214deg) brightness(96%) contrast(93%);
}

.arkly-logo svg {
	color: black;
	height: 100%;
	width: 80px;
}

.bcorp-logo svg {
	margin-top: 15px;
	height: 75px;
	width: 100%;
	color: black;

}

.hightide-logo svg {
	height: 100%;
	width: 240px;

}

.container .arkly-logo svg:hover {
	color: #6177ED;
}
.container .ccrs-logo {
	height: 100%;
	max-width: 400px;
}

.container .ccrs-logo * {
	max-width: fit-content;
}

.container .ccrs-logo svg {
	height: 115px;
	cursor: pointer;
}

.container .ccrs-logo svg:hover {
	//height: 120px;
	//color: grey;
	opacity: 75%;
	//filter: invert(58%) sepia(92%) saturate(4322%) hue-rotate(214deg) brightness(96%) contrast(93%);
}

.container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 400px;
	height: auto;
	min-height: min-content; 
	//background-color: white;
	padding: 20px 0;
}

.container .list {
	//background-color: white;
	width: 200px;
}

.list-homepage {
	width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}
.list-homepage .link {
	margin-left: 80px;
	margin-top: 20px;

}

.list-homepage .horizontal-pair {
	justify-content: center;
	display: flex;
	align-items: flex-end;
	//margin-top: 20px;

}

@media only screen and (max-width: $mobile-breakpoint) {
	#sidepanel-curve {
		width: 100%;
	}

	.container {
		width: 100vw;
	}
}

.copyright {
	margin-top: 40px;
}
</style>
