<template>
<div class='privacy-container'>

</div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import Footer from '@/components/Footer.vue'
import Button from '@/components/Button.vue'

export default {
	name: 'Privacy',
	components: {
		'inline-svg': InlineSvg,
		'foot': Footer,
		'btn': Button,
	},
	data() {
		return {
			
		}
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>

@import "../style/oruga.scss";
@import "../style/style.scss";

.privacy-container {
	padding: 20px;
}

</style>
