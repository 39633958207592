<template>
	<!-- Sidebar Button -->
		<span 	
			class='container'	
			:class="{ 'float-right': floatRight }"
			ref='container'
			:style="buttonWidth"	
		>
			<div 
				class='button'  
				v-on:click='showPanel'
				ref='button'
				:class="{ 'is-active': isPanelVisible }"
				@mouseover='isLabelVisible = true'
				@mouseleave='isLabelVisible = false'
			>

				<div v-if='false' class='icon-container'>
				<inline-svg 
					:src="getLogoPath(icon)" 
					class='icon'	
				/>
				</div>
				<div class='label v-center' ref='label'>
						{{ label }}
				</div>
			</div>
		<div 
			class='panel' 
			v-if='isPanelVisible'
			:style='panelDimensions'	
			ref='panel'
		>
			<slot />	
		</div>
	</span>
	
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import Search from '@/components/Search.vue'
import { mapState } from 'pinia'
import { useConfigStore } from '@/store/config'

export default {
	name: 'Sidebar',
	components: {
		InlineSvg,	
		Search,
	},
	props: {
		label: {
			type: String,
			default: '',
		},
		selected: {
			type: String,
		},
		icon: {
			type: String,
			default: undefined,
		},
		type: {
			type: String,
			default: 'select',
		},
		options: {
			type: Array,
			default: undefined,
		},
		range: {
			type: Object,
			default: undefined,
		},
		floatRight: {
			type: Boolean,
			default: false,
		},
		collapsedWidth: {
			type: Number,
			default: 68
		},
		expandedWidth: {
			type: Number,
			default: 220
		}
	},
	mounted() {
		this.setPanelDimensions()
		window.addEventListener('resize', this.setPanelDimensions )	
	},
	data() {
		return {
			currentSelection: this.selected,
			panelDimensions: {},
			sliderValue: 0,
			isLabelVisible: false,
			isPanelVisible: false,
			buttonWidth: {},
		}
	},
	computed: {
		currentLabel(){
			if (this.isLabelVisible) {
				return /* this.label + ': ' + */ this.currentSelection
			} else {
				return this.currentSelection
			}
		}, 
		...mapState(useConfigStore, ['navbarConfig', 'topbarConfig'])
	},
	methods: {
		getLabelWidth(text) {
				
			const labelWidth = this.getTextWidth([this.label])
			const labelMargin = window.getComputedStyle(this.$refs.label)
			return this.getTextWidth([text])
		},
		getTextWidth(listOfStrings, padding = 0){
			if (this.type == 'select') {
				if (listOfStrings) {
					const getLongestText = (arr) => arr.reduce(
						(savedText, text) => 
						(text.length > savedText.length ? text : savedText),
						'',
					)

					const styleAttributes = window.getComputedStyle(this.$refs.button)
					const longestOption = getLongestText(listOfStrings)
					const text = longestOption 
					const font = styleAttributes['font']	
					const canvas = document.createElement("canvas")
					const context = canvas.getContext("2d")
					context.font = font
					context.text = longestOption
					let { width } = context.measureText(longestOption)
					const spacing = 32 
					width = width + spacing
					return width + padding
				}
			}  else {
				return 300
			}
		},
		setPanelDimensions(){
			if (this.$refs.container){
				const topbarDimensions = this.$refs.container.parentElement.getBoundingClientRect()
				const top = topbarDimensions.height
				

				// Calculate the 'left' position of the panel, so that it lines up
				// with the middle of the button
				const { x, width } = this.$refs.container.getBoundingClientRect()
				// Find the middle of the button
				const middle = width/2 + x
				const left =  middle - (this.getTextWidth(this.options)/2)

				this.panelDimensions =  {
					'position': 'absolute !important',
					'left': left + 'px !important',
					'top': top + 'px !important',
					'width': this.getTextWidth(this.options) + 'px !important',
				}
			}
		},
		updateSlider(selection){
			this.currentSelection = this.range[selection]
		},
		selectOption(option) {
			this.currentSelection = option
			this.isPanelVisible = false
			this.$emit('selection', {'label': this.label.toLowerCase(), 'value': option})
		},
		clickOutsidePanel: function(e) {
			if (this.$refs.panel){
				const el = this.$refs.panel
				if (!(el == e.target || el.contains(e.target) || this.$refs.container.contains(e.target))) {
					this.isPanelVisible = false
				}
			}
		},
		showPanel() {
			this.isPanelVisible = !this.isPanelVisible
			if (this.isPanelVisible == true){
				document.addEventListener("mouseup", this.clickOutsidePanel)
			} else {
				document.removeEventListener("mouseup", this.clickOutsidePanel)
			}
		},

		redirect(path) {
			this.$router.push({name: path, params: { type: this.$route.params.type, id: this.$route.params.id}})
		},
		getLogoPath(name) {
			var logo = require.context('../../assets/icons', false, /\.svg$/)
			return logo('./' + name + ".svg")
		},
	},
	watch: {
		sliderValue(currentValue){
			this.selectOption(this.range[currentValue])
		},
		topbarConfig(newValue) {
			this.setPanelDimensions
		},
		navbarConfig(newValue) {
			this.setPanelDimensions
		}
	}
}

</script>

<style lang="scss" scoped>
@import "../../style/oruga.scss";
@import "../../style/style.scss";

.o-tip {
	z-index: 9999;
}
.container {
	display: block;
	width: fit-content;
	height: $topbar-height;
}

.container .button {
	background-color: transparent;
}

.container .button:hover {
	background-color: $light-blue;
}

.panel .button {	
	margin: 5px 10px;
}

.is-active {
	background-color: $light-blue !important;
	color: $dark-blue !important;
}

.is-selected {
	color: #fdffff;
	background-color: $blue;
}

.float-right {
	margin-left: auto;
}

.panel {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: auto;
	background-color: #fff;
	inline-size: fit-content;
	padding-top: 5px;
	border-radius: 3px 3px;
}

.slider-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
	width: 100%;
	color: $blue;
}

.slider {
	padding: 20px;
	margin: 0 0 0 10px !important;
	width: 100%;
}

.tooltip{
	overflow: visible;
	z-index: 9999;
}

.label{
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 2px 0 0 5px;
	white-space: nowrap;
	width: fit-content;
	inline-size: fit-content;
	text-transform: capitalize;
}

.label * {
	line-height: 0px;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	width: 30px;
	max-width: 30px;
	min-width: 30px;
	max-height: 20px;
	background-color: transparent;
}

.icon-container {
	display: block;
	width: 30px;
}


@media only screen and (max-width: 800px) {
	.button {
		//margin: 10px 0px 10px 10px;
	}
}


@media only screen and (max-width: 480px) {
	.panel {
		left: 0px;
		width: 100vw;
	}
}

</style>

