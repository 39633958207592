<template>
<div id="street-view">
	<div id='street-view' v-show='isAddressValid'>
	<div ref="streetView">
		<div class="mapboxgl-ctrl-top-left" >
			<div class="mapboxgl-ctrl mapboxgl-ctrl-group" >
				<button 
					v-if='hasZoom'
					v-on:click="zoomIn"
					class="mapboxgl-ctrl-zoom-in" 
					type="button" title="Zoom in" 
					aria-label="Zoom in"
					v-bind:disabled="zoom === 3" 
					>
					<span 
						class="mapboxgl-ctrl-icon" 
						aria-hidden="true"
						/>
				</button>
					<button 
						v-if='hasZoom'
						v-on:click="zoomOut"
						class="mapboxgl-ctrl-zoom-out" 
						type="button" title="Zoom out" 
						aria-label="Zoom out"
						v-bind:disabled="zoom === 0"
						>
						<span class="mapboxgl-ctrl-icon" aria-hidden="true"></span>
					</button>
			</div>
		</div>
	</div>
	</div>
	<div class='h-center not-found-div' v-show='!isAddressValid'>
		<strong style='font-size: 25px;'> {{ textContent['property_view']['street-view-not-found'] ? textContent['property_view']['street-view-not-found']['label'] : ''}} </strong>
	</div>
</div>

</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { Loader } from "@googlemaps/js-api-loader"

export default {
	name: 'StreetView',
	props: {
		'coordinates': {

		},
		'hasZoom': {
			default: true,
		}
	},
	data: function() {	
		return {
			isAddressValid: true,
			loader: null,
			streetView: null,
			sv: null,
			panorama: null,
			marker: null,
			pov: null,
			pano: null,
			panoLat: null,
			panoLng: null,
			zoom: 1,
		}
	},
	mounted() {

		this.addComponentRef('street-view', this.$refs.streetView)
		
		if ((!this.isMobile) && !(window.location.href.includes('localhost'))) {	

			this.loader = new Loader({
				apiKey: this.streetViewConfig.apiKey,
				version: "weekly"
			})

			if (this.$route.name == 'govSub') {
				if (this.$route.params.type == 'property') {
					this.initializeMap(this.coordinates)
				}
			} else {
				this.initializeMap(this.coordinates)
			}
			
		}
		
	},
	watch: {
		'$route': {
			handler: function(result) {
				if (result.params.type == "property" && !this.isMobile && !(window.location.href.includes('localhost'))) {
					setTimeout(() => {
						this.initializeMap(this.coordinates)
					},100)
				}
			},
			deep: true,
		},
		coordinates: function(newCoordinates) {
			if (!this.isMobile) {
				setTimeout(() => {
				this.changeLocation(newCoordinates)
				},200)
			}
		},
		isAddressValid(newState) {
			if (!this.isMobile) {
				this.initializeMap(this.coordinates)
			}
		},
	},
	computed: {
		...mapState(useConfigStore, ['isRouteLocation','isMobile','textContent', 'streetViewConfig']),
	},
	methods: {
		changeLocation(coordinates) {
			// Set position to new coordinates
			// Heading will be updated with event listener
			
			if (this.panorama) {
				this.panorama.setPosition({ lat: coordinates.lat, lng: coordinates.lng })
			}

			
		},
		initializeMap: async function(coordinates) {
			const streetViewContainer = this.$refs.streetView
			this.loader.load().then(() => {

				this.sv = new google.maps.StreetViewService()

				this.panorama = new google.maps.StreetViewPanorama(
					streetViewContainer,
					{
						position: { lat: coordinates.lat, lng: coordinates.lng },
						source: google.maps.StreetViewSource.OUTDOOR,
						addressControl: false,
						linksControl: false,
						panControl: false,
						enableCloseButton: false,
						fullscreenControl: false,
						zoomControl: false,
						gestureHandling: 'cooperative',
						scrollwheel: false,
					}
				)
				
				// Watch when location is changed, and update heading
				this.panorama.addListener('position_changed', () => {
					if (this.panorama.getLocation()) {
						var newPanoLat = this.panorama.getLocation().latLng.lat()
						var newPanoLng = this.panorama.getLocation().latLng.lng()
						const heading = this.getHeading(newPanoLat,newPanoLng,this.coordinates.lat,this.coordinates.lng)
						if (this.isAddressValid){
							setTimeout(() => {
								this.panorama.setPov({ heading: heading, pitch: 0})
								}, 100)
						}
					}
				})

				this.panorama.addListener('status_changed', () => {
					//this.isAddressValid = true
					let panoStatus = this.panorama.getStatus()

					if (panoStatus == 'OK') {
						this.isAddressValid = true
					} 

					if (panoStatus == 'ZERO_RESULTS') {
						this.isAddressValid = false
					}
				})

				// Set the initial Street View camera to the center of the map
				try {
					this.sv.getPanorama({ location: { lat: coordinates.lat, lng: coordinates.lng }, preference: 'best', source: google.maps.StreetViewSource.OUTDOOR, radius: 50 })
							.then(this.processSVData)
							.catch(e => {})
							// Look for a nearby Street View panorama when the map is clicked.
							// getPanorama will return the nearest pano when the given
							// radius is 50 meters or less.
						}
				catch(e) {
					console.log(e)
				}

			})
		},
		processSVData({ data }){	
			this.isAddressValid = true
			const location = data.location
			
			let panoLat = location.latLng.lat()
			let panoLng = location.latLng.lng()

			const heading = this.getHeading( panoLat, panoLng, this.coordinates.lat, this.coordinates.lng)
			
			this.panorama.setPov({
				heading: heading,
				pitch: 0,
			})

			this.panorama.setVisible(true)
		},
		zoomIn() {
			if (this.zoom == 3){
				return
			}else {
				++this.zoom
				this.panorama.setZoom(this.zoom)
				return
			}
		},
		getHeading(lat1, lon1, lat2, lon2) {
			var lat1 = lat1 * Math.PI / 180
			var lat2 = lat2 * Math.PI / 180
			var dLon = (lon2 - lon1) * Math.PI / 180

			var y = Math.sin(dLon) * Math.cos(lat2)
			var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon)

			var brng = Math.atan2(y, x)

			return (brng * 180 / Math.PI)
		},
		zoomOut() {
			if (this.zoom == 0){
				return
			}else {
				--this.zoom 
				this.panorama.setZoom(this.zoom)
				return
			}
		},
		...mapActions(useConfigStore, ['addComponentRef']),
	},

}


</script>

<style>
.mapboxgl-ctrl-top-left {
  top: auto !important;
  left: auto !important;
}

#street-view {
	height: calc(100vh - 545px);
  max-height: 360px;
  min-height: 130px;
  width: 100%;
  max-width: 100%;
}

#street-view > * {
  height: calc(100vh - 545px);
  max-height: 360px;
  min-height: 130px;
  width: 100%;
  max-width: 100%;
}

.not-found-div {
	align-items: center;
	font-size: 24px !important;
}


</style>
