<template> 
<div class='arkly-map'>
	<div :class="mapBoxClass" id='map-container' ref='map'>	
		<modal v-model="criticalAssetModal">
			<div class='criticalAssetPopup'>
				<h6>Asset Metadata<br></h6>
				<p>Asset Source: {{ currAsset.source }}<br></p>
				<p v-if="currAsset.type">Asset Type: {{ currAsset.type }}<br></p>
				<p v-if="currAsset.subType">Asset Sub Type: {{ currAsset.subType }}<br></p>
				<p v-if="currAsset.DORUC">Joined Parcel DORUC: {{ currAsset.DORUC }}<br></p>
				<div class='assetName'>
					<p>Asset Name: </p>
					<o-field style="margin-bottom: 0px;">
						<o-input class='editableField' id='test' v-model="currAsset.name" type="textarea"/>
					</o-field>
				</div>
				<div class='assetName'>
					<p style="width: 100px;">Asset Class: </p>
					<v-select
						label="Select"
						v-model='currAsset.class'
						:options='criticalAssetClasses'
						variant="outlined"
						:clearable='false'
						:searchable='false'
						style="width:100%"
					></v-select>
				</div>
				<div v-if=assetOverBuilding class='assetName'>
					<p style="width: 200px;">Asset is a building: </p>
					<v-select
						label="Select"
						v-model='currAsset.isBuilding'
						:options="['Yes','No']"
						variant="outlined"
						:clearable='false'
						:searchable='false'
						style="width:100%"
					></v-select>
				</div>
				<div v-if="currAsset.id" class='criticalAssetButtons'>
					<btn variant="arkly" label="saveChanges" outlined size='medium' v-on:click="saveMetadataChanges">Save Metadata Changes</btn>
					<btn v-if="(currAsset.id.includes('-pts-') || currAsset.id.includes('-PT-')) && !currAsset.id.includes('UserGen-')" variant="arkly" label="joinToBuilding" outlined size='medium' v-on:click="joinToBuilding">Move asset</btn>
					<btn v-if="(!currAsset.id.includes('-local-') && !currAsset.id.includes('-LC-')) && currAsset.layerSource != 'point'" variant="arkly" label="includeAsset" outlined size='medium' v-on:click="includeAsset">Include in analysis</btn>
					<btn v-if="currAsset.layerSource != 'point'" variant="arkly" label="removeAsset" outlined size='medium' v-on:click="removeAsset">Hide</btn>
				</div>
			</div>
		</modal>
		<modal v-model="confirmSelectionModal">
			<div class='criticalAssetPopup'>
				<h6>Confirm New Asset Location<br></h6>
				<div v-if="selectingNewLocation">
				Asset Name: {{ currAsset.name }}<br>
				Asset Source: {{ currAsset.source }}<br>
				</div>
				<div v-else>
				Asset Name: {{ currNewAsset.name }}<br>
				Asset Source: {{ currNewAsset.source }}<br>
				</div>
				Click Location:<br>
				Latitude: {{ currAsset.newLat }}<br>
				Longitude: {{ currAsset.newLong }}<br>
				<p v-if="underClickAddress">Address of building under click: {{ underClickAddress }}<br></p>
				<p>Is this where the asset should be?<br>
				Confirming an asset move includes the moved asset in all subsequent vulnerability assessments</p>
				<div class='criticalAssetButtons'>
					<btn variant="arkly" label="confirmLocation" outlined size='medium' v-on:click="confirmLocation">Yes</btn>
					<btn variant="arkly" label="denyLocation" outlined size='medium' v-on:click="denyLocation">No</btn>
					<btn v-if="selectingNewLocation" variant="arkly" label="denyLocation" outlined size='medium' v-on:click="cancelAssetMove">Cancel asset move</btn>
					<btn v-else variant="arkly" label="denyLocation" outlined size='medium' v-on:click="cancelAssetMove">Cancel adding new asset</btn>
				</div>
			</div>
		</modal>
		<modal v-model="undoActionModalLocal">
			<div class='criticalAssetPopup'>
				<h6>Asset Action Undone<br></h6>
				Asset Modified: {{ undoneAssetName }}<br>
				Action Undone: {{ actionUndone }} <br>
				<div class='criticalAssetButtons'>
					<btn variant="arkly" label="okUndo" outlined size='medium' v-on:click="undoActionModalLocal = !undoActionModalLocal">Ok</btn>
				</div>
			</div>
		</modal>
		<modal v-model="newAssetModalLocal">
			<div class='criticalAssetPopup'>
				<h6>Asset Metadata<br></h6>
				<h6 v-if=incorrectInput style="color:red;">Please Enter an Asset Name and Select a Class<br></h6>
				<div class='assetName'>
					<p>Asset Name: </p>
					<o-field style="margin-bottom: 0px;">
						<o-input class='editableField' id='test' v-model="currNewAsset.name" type="textarea"/>
					</o-field>
				</div>
				<div class='assetName'>
					<p style="width: 100px;">Asset Class: </p>
					<v-select
						label="Select"
						v-model='currNewAsset.class'
						:options='criticalAssetClasses'
						variant="outlined"
						:clearable='false'
						:searchable='false'
						style="width:100%"
					></v-select>
				</div>
				<div class='assetName'>
					<p>Asset Type: </p>
					<o-field style="margin-bottom: 0px;">
						<o-input class='editableField' id='test' v-model="currNewAsset.type" type="textarea"/>
					</o-field>
				</div>
				<div class='assetName'>
					<p>Asset Subtype: </p>
					<o-field style="margin-bottom: 0px;">
						<o-input class='editableField' id='test' v-model="currNewAsset.subtype" type="textarea"/>
					</o-field>
				</div>
				<btn variant="arkly" label="addNewAsset" outlined size='medium' v-on:click="addNewAsset">Add Asset</btn>
				
			</div>
		</modal>
		<map-control 
			:map='map'
			position='top-left'
			isFirst
			withoutMargin	
		>
			<search
				color='black'
				size='medium'
				isMapControl
				:logo="isItArklyLogo"
				addRef='false'
				v-if="isRouteLocation || isMobile"
				:style="{margin: (!isMobile) ? '5px' : '0px', width: (isRouteLocation && !isMobile) ? '376px' : 'calc(100vw - 10px)'}"
				placeholder='Search for another address... '
				arklyLogo
			/>
		</map-control>

		<map-control 
			:map='map'
			position='bottom-right'
			withoutMargin
		>
			<map-legend
				v-show="map"
				:style='legendStyle'
				:units='controls.units'
				:stormCategory='controls.stormCategory'
				:year='controls.year'
				:map='map'
				:zoom='mapConfig.zoom'
				:layerLabel='mapConfig.currentLayer'
			/>

		</map-control>
		
		<map-control 
			:map='map'
			position='bottom-right'
			:style="{position: isMobile ? 'relative' : 'static', bottom: isMobile ? '-15px' : '0px'}"
		>
			<timeline 
				v-if="$route.meta.platform == 'arkly'"
				id='timeline' 
				:years='settings.years' 
				:class="{'tl-fullscreen': isRouteLocation}"
				ref='timeline'
			/>
		</map-control>

		<map-control 
			:map='map'
			position='top-right'
			isFirst
		>

			<settings/>	
		</map-control>

		<map-control 
			:map='map'
			position='top-left'
			withoutMargin
		>
			<sidepanel v-if="!isMobile || $route.params.id != 'Location'">
				

				<arkly-panel-content 
					:isPuertoRico='isPuertoRico'
					v-if="($route.meta.platform == 'arkly') && !isMobile"
				/>
				
				<info-table
					v-else-if="$route.params.view == 'economic' && coordinates != null && !isMobile && this.$route.params.id != 'Location' && this.surfData.attributes.area"
					:input='surfData'
					:dataSource='controls.dataSource'
					:year='controls.year'
					:stormCategory='controls.storm'
					:useType='controls.useType'
				/>

				<socioeconomic-table
					v-else-if="$route.params.view == 'socioeconomic' && !isMobile && this.$route.params.id != 'Location'"
					:input='surfData'
					:dataSource='controls.dataSource'
					:year='controls.year'
					:stormCategory='controls.storm'
					useType='total'
					:equity='equity'
				/>
			</sidepanel>
		</map-control>	
	</div>	
</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Mapbox from "mapbox-gl"
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapControl from '@/components/map/Control'
import SidePanel from '@/components/sidepanel'
import ArklyPanelContent from '@/components/sidepanel/ArklyContent'
import InfoTable from '@/components/govSub/InfoTable'
import SocioeconomicTable from '@/components/govSub/SocioeconomicTable'
import Search from '@/components/Search'
import Timeline from '@/components/arkly/Timeline'
import Legend from '@/components/map/Legend'
import InlineSvg from '@/components/SvgLoader'
import Settings from '@/components/arkly/Settings'
import { getAuthToken, getTokenExpirationDate, getUser, loginUser } from '@/helpers/auth.js'
import { event } from 'vue-gtag'
import Modal from '@/components/Modal'
import Button from '@/components/Button'
import decode from 'jwt-decode'

export default {
	name: 'arkly-map',
	components: {
		'btn': Button,
		'search': Search,
		'map-legend': Legend,
		'timeline': Timeline,
		'sidepanel': SidePanel,
		'map-control': MapControl,
		'inline-svg': InlineSvg,
		'arkly-panel-content': ArklyPanelContent,
		'info-table': InfoTable,
		'socioeconomic-table': SocioeconomicTable,
		'settings': Settings,
		'modal': Modal,
	},
	props: {
		startCoordinates: {
			type: Object,
			default: {
				'lng': -81.760254,
				'lat': 27.994402
			},
		},
	},
	data() {
		return{			
			runningFunction: undefined,
			newLong: undefined,
			newLat: undefined,
			underClickAddress: undefined,
			assetOverBuilding: false,
			confirmSelectionModal: false,
			newAssetModalLocal: false,
			undoActionModalLocal: false,
			selectingNewLocation: false,
			criticalAssetModal: false,
			incorrectInput: false,
			currAsset: {
				id: undefined,
				_id: undefined,
				name: undefined,
				ogName: undefined,
				location: undefined,
				DORUC: undefined,
				source: undefined,
				layerSource: undefined,
				surf: undefined,
				class: undefined,
				ogClass: undefined,
				type: undefined,
				subtype: undefined,
				newLong: undefined,
				newLat: undefined,
				cityAndCounty: undefined,
				isBuilding: 'Yes',
				time: undefined,
			},
			currNewAsset: {
				id: undefined,
				_id: undefined,
				name: undefined,
				ogName: undefined,
				location: undefined,
				DORUC: undefined,
				source: undefined,
				layerSource: undefined,
				surf: undefined,
				class: undefined,
				ogClass: undefined,
				type: undefined,
				subtype: undefined,
				newLong: undefined,
				newLat: undefined,
				cityAndCounty: undefined,
				isBuilding: 'Yes',
				time: undefined,
			},
			mouseMoveEvent: undefined,
			mouseOverLocation: undefined,
			defaultZoom: undefined,
			currentZoom: this.zoom,
			mapbox: null,
			Mapbox: Mapbox,
			map: null,
			marker: {},
			timer: null,
			popup: null,
			windowWidth: null,
			dataSource: 'NOAA2022',
			useType: 'total',
			expectedLoss: {},
		}
	},
	created() {
		// SETUP MAP CONFIG
		this.setMapConfigVersion()
		this.setDataSource(this.mapConfig.dataSources[this.$route.meta.region])
	},
	mounted(){
		if (this.$route.params.id == 'Location') {
			this.setRouteIsLocation(true)
		} else {
			this.setRouteIsLocation(false)

		}
		
		if (this.$route.params.view == 'socioeconomic') {
			this.mapConfig.isEquityVisible = true
		} else {
			this.mapConfig.isEquityVisible = false
		}
		if (this.mapConfig.arklyRoutes.includes(this.$route.name)){
			if (this.isMobile) {
				this.moveElementCSS('-5','30','.mapboxgl-ctrl-logo','bottom')
				this.moveElementCSS('20','15','.mapboxgl-ctrl-bottom-right','bottom')
				this.moveElementCSS('-10','35','.mapboxgl-ctrl-top-right','top')
				this.moveElementCSS('0','35','.mapboxgl-ctrl-top-right','top')
			} else {
				this.moveElementCSS('-5','95','.mapboxgl-ctrl-logo','bottom')
				this.moveElementCSS('20','0','.mapboxgl-ctrl-bottom-right','bottom')
				this.moveElementCSS('0','-10','.mapboxgl-ctrl-top-right','top')
				this.moveElementCSS('35','-10','.mapboxgl-ctrl-top-right','top')
			}
		}
		
		
		const bounds = this.user ? this.user.access.bounds : []
		let coordinates = this.coordinates ? this.coordinates : this.startCoordinates
		
		Mapbox.accessToken = this.mapConfig.accessToken
		if (this.mapConfig.zoomOverRide) {
			var zoomToUse = this.mapConfig.zoomOverRide
		} else {
			var zoomToUse = this.mapConfig.layerSettings.property.startingZoom
		}
		const ending = window.location.href.split('/').slice(-1)[0]
		if (!(ending.includes('_'))) {
			if (ending.length == 6) {
				zoomToUse = this.mapConfig.layerSettings.county.startingZoom
			} else if (ending.length == 14) {
				zoomToUse = this.mapConfig.layerSettings.blockgroup.startingZoom		
			}
		}
		setTimeout(() => {
			
		const map = (window.map = new Mapbox.Map({
			container: 'map-container', 
			style: this.mapConfig.style, 
			center: coordinates, 
			zoom: zoomToUse, 
			antialias: false,
			attributionControl: false,
			preserveDrawingBuffer: false,
			performanceMetricsCollection: false,
			minZoom: 6,
			//maxBounds: [
            //    [-95, 22],
            //    [-75, 32]
            //]
		}))

		map.on('render', function () {
			map.resize();
		});
		if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
			var assetFilter = this.makeCriticalAssetFilter(true)
		}
		
		map.on('load', () => {
			this.map = map
			const keys = Object.keys(this.mapConfig.layers);
			if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
				var idToPromote
				if (this.user.email == 'BDefoe-Surprenant@ecfrpc.org' || this.user.email == 'mango@hightide.ai') {
					idToPromote = '_id'
				} else {
					idToPromote = 'CA_ID'
				}
				map.addSource('point', {
					'type': 'geojson',
					'data': this.geojson,
					"promoteId": "CA_ID"
				});

				map.addLayer({
					'id': 'point',
					'type': 'circle',
					'source': 'point',
					'paint': {
						'circle-color': '#00bfff', //'#00FF80', e96afc
						'circle-radius': this.mapConfig.layerSettings['criticalAssets'].circleRadius, 
						'circle-stroke-color': this.mapConfig.layerSettings['criticalAssets'].circleBorderColor, 
						'circle-stroke-width': this.mapConfig.layerSettings['criticalAssets'].circleBorderWidth,
						'circle-stroke-opacity': ['case',['feature-state', 'qced'],0,1],
						'circle-opacity': ['case',['feature-state', 'qced'],0,this.mapConfig.layerSettings['criticalAssets'].opacity]
					}
				});
				map.setFilter('point', assetFilter)
			}
			
			keys.forEach(key => {
				this.mapConfig.layers[key].forEach(layer => {
					//console.log(key,layer)
					if (!layer.includes('CAP_') && !layer.includes('CAL_')) {
						this.addMapboxLayer(layer, this.map, this.mapConfig.layerToInsertUnder)
						map.setPaintProperty(layer,  'fill-opacity-transition',  { duration: 500 })
					}
					// doesnt do anything yet, need to use opacity to hide/show instead of visibile/none for this to work
					if (key == 'property' && layer.includes('FZs')) {
						map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacityFZs)
					} else if (key == 'property'){
						map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity[this.$route.meta.region])
					} else if (layer.includes('floodzone')) {
						map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity)
						map.setPaintProperty(layer, 'fill-color', this.mapConfig.layerSettings[key].fillColor)
					} else if (layer.includes('counties') || layer.includes('bgs') || layer.includes('blockgroup') || layer.includes('equity')) {
						if (layer.includes('FZs')) {
							map.setFilter(layer, ['all',['!=', 'county','Volusia'],['!=', 'county','Brevard']])
						}
						map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity)
						map.setPaintProperty(layer + '_outline', 'line-opacity', this.mapConfig.layerSettings[key].opacity)
						map.setPaintProperty(layer + '_outline', 'line-width',['case',['boolean', ['feature-state', 'clicked'], false],3,0])
						
					// critical asset stuff below here
					} else if (layer.includes('CAP_') && !layer.includes('CAP_LOC')){
						map.addSource(layer, {
							type: 'vector',
							url: 'mapbox://hightideintelligence.' + layer,
							"promoteId": idToPromote
						});
						map.addLayer({'id': layer,'type': 'circle', 'source': layer, 'source-layer': layer,'layout': {},
							'paint': {'circle-color': this.mapConfig.layerSettings[key].color,
								'circle-radius': this.mapConfig.layerSettings[key].circleRadius, 
								'circle-stroke-color': this.mapConfig.layerSettings[key].circleBorderColor, 
								'circle-stroke-width': this.mapConfig.layerSettings[key].circleBorderWidth}}, 
							this.mapConfig.topLayerToInsertUnder);
							
						//map.setPaintProperty(layer, 'circle-stroke-color',['match',['feature-state','CA_Match_Type'],1,'#00FF80',2,'#FFFF00',3,'#FF0000',/* other */ '#000000'])
						map.setLayoutProperty(layer, 'visibility', 'visible')
						map.setPaintProperty(layer, 'circle-stroke-color',['case',['feature-state', 'qced'],'#FFFFFF','#000000'])
						map.setPaintProperty(layer, 'circle-stroke-opacity',['case',['feature-state', 'qced'],0,1])
						map.setPaintProperty(layer, 'circle-opacity',['case',['feature-state', 'qced'],0,this.mapConfig.layerSettings['criticalAssets'].opacity])
						map.setFilter(layer, assetFilter)//['==', 'CA_class','government'])
					} else if (layer.includes('CAP_LOC_')){
						map.addSource(layer, {
							type: 'vector',
							url: 'mapbox://hightideintelligence.' + layer,
							"promoteId": idToPromote
						});
						map.addLayer({'id': layer,'type': 'circle', 'source': layer, 'source-layer': layer,'layout': {},
							'paint': {'circle-color': '#00bfff', //this.mapConfig.layerSettings[key].color,
								'circle-radius': this.mapConfig.layerSettings[key].circleRadius, 
								'circle-stroke-color': this.mapConfig.layerSettings[key].circleBorderLocalColor, 
								'circle-stroke-width': this.mapConfig.layerSettings[key].circleBorderWidth}}, 
							this.mapConfig.topLayerToInsertUnder);
							
						//map.setPaintProperty(layer, 'circle-stroke-color',['match',['feature-state','CA_Match_Type'],1,'#00FF80',2,'#FFFF00',3,'#FF0000',/* other */ '#000000'])
						map.setLayoutProperty(layer, 'visibility', 'visible')
						map.setPaintProperty(layer, 'circle-stroke-color',this.mapConfig.layerSettings[key].circleBorderLocalColor)
						map.setPaintProperty(layer, 'circle-stroke-opacity',['case',['feature-state', 'qced'],0,1])
						map.setPaintProperty(layer, 'circle-opacity',['case',['feature-state', 'qced'],0,this.mapConfig.layerSettings['criticalAssets'].opacity])
						map.setFilter(layer, assetFilter)//['==', 'CA_class','government'])
					} else if (layer.includes('CAPoly_')) {
						map.setPaintProperty(layer, 'fill-color', this.mapConfig.layerSettings[key].fillColor)
						map.setPaintProperty(layer, 'fill-outline-color', this.mapConfig.layerSettings[key].fillOutlineColor)
						map.setPaintProperty(layer, 'fill-opacity', 0)
						map.setFilter(layer, assetFilter)
					}  else if (layer.includes('CAL_')) {
						map.addSource(layer, {
							type: 'vector',
							url: 'mapbox://hightideintelligence.' + layer,
							"promoteId": idToPromote
						});
						map.addLayer({'id': layer,'type': 'line', 'source': layer, 'source-layer': layer,'layout': {},
							'paint': {'line-color': this.mapConfig.layerSettings[key].fillColor,
								'line-opacity': 0,
								'line-width': this.mapConfig.layerSettings[key].lineWidth,}}, 
							this.mapConfig.topLayerToInsertUnder);
							map.setFilter(layer, assetFilter)
					}
					
				})
			})

			const bounds = this.user ? this.user.access.bounds : []
			if (bounds) {
				//console.log('bounds: ', bounds)
			}

			// set the marker color based on product and add it to the  map
			var markerColor = '#5181c4'
			if (this.$route.meta.platform == 'arkly') {
				markerColor = '#C59901'
			}
			
			setTimeout(() => {
				this.marker = new Mapbox.Marker({ color: markerColor})
				if (coordinates){
					this.marker.setLngLat(coordinates)
					var addMarker = true
					if (this.propertyNotFound.value){
						if (this.propertyNotFound.value.includes('lng')){
							addMarker = false
						}
					}
					if (addMarker) {
						this.marker.addTo(map)
					}
				}				
			},100)
			
			// add navigation controls to the map
			const navigationControl = new Mapbox.NavigationControl({
				showCompass: false
			})

			const geolocateUser = new Mapbox.GeolocateControl({
				positionOptions: {
				enableHighAccuracy: true
				},
				// When active the map will receive updates to the device's location as it changes.
				trackUserLocation: false,
				// Draw an arrow next to the location dot to indicate which direction the device is heading.
				showUserHeading: false
			})

			map.addControl(navigationControl, 'top-right')
			map.addControl(geolocateUser, 'top-right')
			map.addControl(new Mapbox.AttributionControl({'compact':true}), 'top-right');
			
			if (!this.mapConfig.arklyRoutes.includes(this.$route.name) && !this.isMobile) {
				const DrawPolygons = MapboxDraw.modes.draw_polygon;
				var controls = this.controls
				DrawPolygons.clickOnVertex = function(state) {
					if (controls.drawModeName == 'Summary Statistics') {
						return this.changeMode('simple_select');
					} else if (controls.drawModeName == 'Building Footprints') {
						return this.changeMode('draw_polygons', { featureIds: [state.polygon.id] });
					}
				};
				var draw = new MapboxDraw({
					modes: Object.assign({
						draw_polygons: DrawPolygons,
					}, MapboxDraw.modes),
					displayControlsDefault: false
					})
				map.addControl(draw, 'top-left')
				this.addDraw(draw)	
				if (this.$route.params.view == 'socioeconomic') {
					this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'visible')
					this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity + '_outline', 'visibility', 'visible')
				} else {
					this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'none')
					this.mapConfig.map.setLayoutProperty(this.mapConfig.layers.equity + '_outline', 'visibility', 'none')
				}
			}

			
			const canvas = this.map.getCanvasContainer();
			canvas.addEventListener('mousedown', this.mouseDown, true);

			map.on('click', this.mapConfig.layers.property, this.handleClick)
			map.on('click', this.handleSelectionClick)
			if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
				map.on('click', this.mapConfig.layers.blockgroup, this.handleClick)
				map.on('click', this.mapConfig.layers.county, this.handleClick)
				map.on('click', this.mapConfig.layers.equity, this.handleClick)
				map.on('click', this.mapConfig.layers.criticalAssets, this.handlePointAssClick)
				
			}
			
			// Create a popup, but don't add it to the map yet.
			this.popup = new Mapbox.Popup({
				closeButton: false,
				closeOnClick: false
			})
			//map.on('touchstart', this.mapConfig.layers.criticalAssets.slice(-1)[0], 
			//	(event) => this.makePopup(event, 'criticalAssets')
			//)
			
			const layerTypes = Object.keys(this.mapConfig.layers);
			layerTypes.forEach(layerType => {
				if (layerType != 'floodZone') {
					
					if (layerType == 'property') {
						var enableFlag = true
					} else {
						if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
							var enableFlag = true
						} else {
							var enableFlag = false
						}
					}
					if (enableFlag) {
						this.mapConfig.layers[layerType].forEach(layer => {
							this.map.on('mousemove', layer, 
								(event) => this.makePopup(event, layerType)
							)
							this.enableOrDisableInteraction(layer,true)
						})
					}

				}
			})
			if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
				map.on('click', 'point', this.handlePointAssClick)
				map.on('mousemove', 'point', 
					(event) => this.makePopup(event, 'criticalAssets')
				)
				this.enableOrDisableInteraction('point',true)
			}
			
			if (!this.mapConfig.arklyRoutes.includes(this.$route.name)) {
				if ('minx' in bounds) { 
					const padding = this.mapConfig.latLongBoundPadding
					map.setMaxBounds([
						[ bounds['minx'] - padding, bounds['miny'] - padding ],
						[ bounds['maxx'] + padding, bounds['maxy'] + padding ]
					])
				}
			}
			if (map.getZoom() < 15) {
				this.updateZoomState(false)
			} else {
				this.updateZoomState(true)
			}
			if (this.$route.meta.platform != 'arkly') {
				if ('minx' in bounds) { 
					const padding = 0.2 
					map.setMaxBounds([
						[ bounds['minx'] - padding, bounds['miny'] - padding ],
						[ bounds['maxx'] + padding, bounds['maxy'] + padding ]
					])
				}
			}
			if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
				this.loadCA_Action()
			}
		})	
		map.on('dragend', () => {
			event('map_drag', {'event_category': 'map', 'mapCenter' : map.getCenter(), 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('map_drag','location ID: ' + window.location.href.split('/').slice(-1) + ', mapCenter: ' + map.getCenter())
			
		})
		map.on('zoomend', () => {
			this.currentZoom = map.getZoom()
			//console.log(this.currentZoom)
			event('map_zoom', {'event_category': 'map', 'mapZoom' : this.currentZoom, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('map_zoom','location ID: ' + window.location.href.split('/').slice(-1) + ', mapZoom: ' + this.currentZoom)
			if ((this.currentZoom < 13.5) && this.popup){
				this.popup.remove()
			} 
			if (this.currentZoom <= 13.5) {
				if (this.zoomedIn) {
					this.updateZoomState(false)
					if (this.controls['drawMode']) {
						this.updateToggleDrawMode(false)
					}
				}
			} else {
				if (this.zoomedIn == false) {
					this.updateZoomState(true)
				}

			}
		})

		if (this.$refs.map) {
			this.setMapDimensions(this.$refs.map.clientHeight, this.$refs.map.clientWidth)
		}

		//Set Window Width Listener, so that vue components can use reactive
		window.addEventListener('resize', () => {
			this.windowWidth = window.innerWidth
			if (this.$refs.map) {
			this.setMapDimensions(this.$refs.map.clientHeight, this.$refs.map.clientWidth)
			}
		})

		this.addComponentRef('map', this.$refs.map)
		this.defaultZoom = this.mapConfig.zoom
		this.mapConfig.map = map
		if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
			var t = setInterval(this.checkToken,5*60*1000);
			this.runningFunction = t
		}
		//console.log('done mounting map component')
	},100)

	},
	destroyed(){
		this.map.remove()
		clearInterval(runningFunction)
	},
	methods: {
		mouseLeaveMethod(){
			if (!this.selectingNewLocation && !this.addingNewAsset) {
				this.map.getCanvas().style.cursor = ''
			}
			clearTimeout(this.timer)
			if (!(this.isMobile)) {
				this.timer = setTimeout(() => {
					this.popup.remove()
				}, this.mapConfig.popupDelayRemove)
			}
		},
		mouseTouchEndMethod(){
			if (!this.selectingNewLocation && !this.addingNewAsset) {
				this.map.getCanvas().style.cursor = ''
			}
			clearTimeout(this.timer)
			if (!(this.isMobile)) {
				this.timer = setTimeout(() => {
					this.popup.remove()
				}, this.mapConfig.popupDelayRemove)
			}
		},
		enableOrDisableInteraction(layer, state){
			if (state) {
				this.map.on('mouseleave', layer, this.mouseLeaveMethod)
				this.map.on('touchend', layer, this.mouseTouchEndMethod)
			} else {
				this.map.off('mouseleave', layer, this.mouseLeaveMethod)
				this.map.off('touchend', layer, this.mouseTouchEndMethod)

			}

		},
		makePopup(mapEvent, version) {	
			var properties = mapEvent.features[0].properties
			//console.log(properties)
			var selectedFeature = mapEvent.features[0]
			var runFlag = true
			var runLoop = true
			//console.log(runFlag)
			var idField = 'id'
			if (this.user){
				if (this.user.email == 'BDefoe-Surprenant@ecfrpc.org' || this.user.email == 'mango@hightide.ai') {
					idField = '_id'
				} 
			}
			mapEvent.features.forEach(feature =>{
				//console.log(feature)
				if (runLoop) {
					if (this.removedIDs.includes(feature[idField])) {
						runFlag = false
					} else {
						runFlag = true
						runLoop = false
						properties = feature.properties
						selectedFeature = feature
					}
				}
			})
			if (this.mapConfig.govSubRoutes.includes(this.$route.name)){
				if (mapEvent.features[0].source.includes("CAL_") && !this.lineInteraction) {
					runFlag = false
				} else if (mapEvent.features[0].source.includes("CAPoly_") && !this.polyInteraction) {
					runFlag = false
				} else if ((mapEvent.features[0].source.includes("CAP_") || mapEvent.features[0].source.includes("point")) && !this.pointInteraction) {
					runFlag = false
				} 
			}
			if (runFlag && !this.selectingNewLocation  && !this.addingNewAsset){	
				this.map.getCanvas().style.cursor = 'pointer'
				
				//console.log(mapEvent.features[0])
				// Key is 'key' in aggregate tilesets and 
				let id
				if ( 'key' in properties ) {
					id = properties['key']
				} else if ( 'id' in properties ) {
					id = properties['id']
				} else if ( '_id' in properties) {
					id = properties['_id']
				} else if ( 'CA_class' in properties) {
					id = properties['index']
				} else {
					id = properties['structureId']
				} 
				
				// If the current mouse movement location is not set, set it now
				if ( this.mouseOverLocation == undefined ) {
					this.mouseOverLocation = id

				// If the current mouse location is equal to the new 
				} else if ( this.mouseOverLocation == id || version == 'criticalAssets') {

					clearTimeout(this.timer)

					const features = mapEvent.features
					if (mapEvent.features.length > 0) {
						this.mouseMoveEvent = selectedFeature
						window.clearTimeout(this.timer)
						this.timer = window.setTimeout(() => {
							//console.log(this.mouseMoveEvent)
							this.makePopupContent({'features': this.mouseMoveEvent}, this.popup).addTo(this.map)
							event('map_mouse_over', {'event_category': 'map', 'mousedLocationID' : features[0].properties['structureId'], 'location_ID' : window.location.href.split('/').slice(-1)})
							confection.submitEvent('map_mouse_over','location ID: ' + window.location.href.split('/').slice(-1) + ', moused over location ID: ' + features[0].properties['structureId'])
						}, 
							this.mapConfig.popupDelay,
						)
					}
				
				// If the location has changed from the last time the mouse moved, 
				// then reset timer and mouseOverLocation
				} else {
					this.mouseOverLocation = undefined
					window.clearTimeout(this.timer)
					this.popup.remove()
				}
			}
		},
		makePopupContent(e, popup) {
			
			const properties = e.features.properties
			const geometry = e.features.geometry
			//console.log(properties)
			//console.log(geometry)
			// Copy coordinates array.
			// need to deal with multipolygon and get center of coordinates array
			var coordinates = geometry.coordinates[0][0]
			var curr_length = geometry.coordinates[0].length
			if (geometry.type == "LineString") {
				var coordinates = geometry.coordinates
				var curr_length = geometry.coordinates.length
				var averageLat = 0
				var averageLng = 0
				for (var i = 0; i < curr_length; i++) {
					averageLng += parseFloat(geometry.coordinates[i][0])
					averageLat += parseFloat(geometry.coordinates[i][1])
				}
				averageLng = averageLng / curr_length
				averageLat = averageLat / curr_length
				coordinates = [averageLng, averageLat]
			} else if (curr_length) {
				var averageLat = 0
				var averageLng = 0
				for (var i = 0; i < curr_length; i++) {
					averageLng += parseFloat(geometry.coordinates[0][i][0])
					averageLat += parseFloat(geometry.coordinates[0][i][1])
				}
				averageLng = averageLng / curr_length
				averageLat = averageLat / curr_length
				coordinates = [averageLng, averageLat]
			} else {
				coordinates = [geometry.coordinates[0], geometry.coordinates[1]]

			}

			// Arkly Popup Description
			let id, division, address, description
			if (('id' in properties && this.$route.meta.platform == 'arkly') || (('structureId' in properties && this.$route.meta.platform == 'govSub'))) {
				// this line needs to be modified once everything moves to the short codes
				var dataKey = 'expectedLoss_' + this.controls.dataSource + '_' + this.controls.storm.replace(' ','') + '_' + this.controls.year + '_mean'
				if (this.mapboxLookupDict[dataKey] in e.features.properties) {
					var selfInsurance = e.features.properties[this.mapboxLookupDict[dataKey]]
				} else {
					var selfInsurance = e.features.properties['EL']
				}
				

				if (selfInsurance >= 1000) {
					selfInsurance = '$' + this.abbreviateNumbers(selfInsurance, 2)
				} else {
					selfInsurance = this.textContent['property_view']['low-flood-risk-desc']['label'].toLowerCase() + ' $1K'
				}

				//////////////////////////////////////////////////////
				// Puerto Rico Missing Address Fix
				//////////////////////////////////////////////////////
				let streetAddy
				if ('street' in e.features.properties) {
					streetAddy = e.features.properties.street
				} else {
					streetAddy = e.features.properties.id.split('_')[0].replaceAll('-',' ')
				}
				const street = (streetAddy != 'unknown' && streetAddy != 'Unknown' && streetAddy != 'Unknown-Street' && streetAddy != 'Unknown-Road' && streetAddy != 'Tbd') ? streetAddy : ''
				
				let isMunicipality = ''
				try {
					if (this.textContent) {	
					isMunicipality = (this.$route.name == 'puertoRico' && e.features.properties.city == 'No City') ? ' ' + this.textContent['property_view']['municipality']['label'] : ''
					}
				} catch {}

				const city = (e.features.properties.city == 'No City') ? e.features.properties.county + isMunicipality + ', ' : e.features.properties.city + ', '

				///////////////////////////////////////////////////////
				if (this.mapConfig.arklyRoutes.includes(this.$route.name)) {
					const county = (isMunicipality == '') ? e.features.properties.county : ''
					address = street + '<br>' + city + county 
					description = "<span style='font-size: 16px'>" + address + '</span>'
				} else {
					const county = (isMunicipality == '') ? e.features.properties.county : ''
					address = street + '<br>' + city + county 
					description = "<span style='font-size: 16px'>" + address + "<span style='font-weight: bold'>, " + selfInsurance +  '</span> ' + this.textContent['property_view']['map-popup-govsub']['label'] +'</span>'
				}
			// Equity Popup Description
			} else if ( 'blockgroupId' in properties) {
				id = properties['blockgroupId']
				//division = 'blockgroup'
				address = 'Blockgroup: ' + id
				var dataKey = this.controls.dataSource + '_' + this.controls.storm.replace(' ','') + '_' + this.controls.year + '_atRisk'
				var householdsAtRisk = e.features.properties[dataKey]
				description = "<span style='font-size: 16px'>" + address + ', households at risk: ' + this.abbreviateNumbers(householdsAtRisk, 2)  + '</span>'
			// critical asset popup
			} else if ( 'CA_class' in properties) {
				if (!properties['CA_Name']) {
					var assetName = 'Unknown Name'
				} else {
					var assetName = properties['CA_Name'].toLowerCase()
					if (assetName == 'none'){
						assetName = 'Unknown Name'
					} else {
						assetName = this.toTitleCase(assetName)
					}
				}
				var assetClass = properties['CA_class']
				var assetSource = this.toTitleCase(properties['CA_Source'].toLowerCase().split('_')[0].replace(/\-/g,' '))
				if (properties['CA_Source'].split('_').length > 1) {
					if (properties['CA_Source'].split('_').length > 2) {
						var assetSubtype = this.toTitleCase(properties['CA_Source'].toLowerCase().split('_')[1].replace(/\-/g,' '))
						var assetSubSubtype = this.toTitleCase(properties['CA_Source'].toLowerCase().split('_')[2].replace(/\-/g,' '))
						description = "<span style='font-size: 16px'>Asset Name: " + assetName + '<br>Asset Source: ' + assetSource + '<br>Asset Type: ' + assetSubtype + '<br>Asset Sub Type: ' + assetSubSubtype + '<br>Asset Class: ' + assetClass  + '</span>'
					} else {
						var assetSubtype = this.toTitleCase(properties['CA_Source'].toLowerCase().split('_')[1].replace(/\-/g,' '))
						description = "<span style='font-size: 16px'>Asset Name: " + assetName + '<br>Asset Source: ' + assetSource + '<br>Asset Type: ' + assetSubtype + '<br>Asset Class: ' + assetClass  + '</span>'
					}
				} else {
					description = "<span style='font-size: 16px'>Asset Name: " + assetName + '<br>Asset Source: ' + assetSource + '<br>Asset Class: ' + assetClass  + '</span>'
			
				}
				
			// Gov Sub Property Popup Description
			} else {
				if ('id' in properties) {
					if (properties['id'].length  == 6){
						id = properties['id']
						//division = 'county'
						address = properties['county'] + ' county'
						//console.log(selfInsurance,dataKey,this.mapboxLookupDict[dataKey])
					} else {
						id = properties['id']
						//division = 'blockgroup'
						address = 'Blockgroup: ' + id 
						description = "<span style='font-size: 16px'>" + address + '</span>'
					}

					var dataKey = 'expectedLoss_' + this.controls.dataSource + '_' + this.controls.storm.replace(' ','') + '_' + this.controls.year + '_mean'
					if (this.mapboxLookupDict[dataKey] in properties){
						var selfInsurance = properties[this.mapboxLookupDict[dataKey]]
					} else {
						var selfInsurance = properties['EL']
					}

					if (selfInsurance >= 1000) {
						selfInsurance = '$' + this.abbreviateNumbers(selfInsurance, 2)
					} else {
						selfInsurance = this.textContent['property_view']['low-flood-risk-desc']['label'].toLowerCase() + ' $1K'
					}
					description = "<span style='font-size: 16px'>" + address + ', aggregate expected loss: ' + selfInsurance + '</span>'
			
				}
				

				
			}

			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(e.features.lngLat - coordinates[0]) > 180) {
				coordinates[0] += e.features.lngLat.lng > coordinates[0] ? 360 : -360;
			}
			
			// Populate the popup and set its coordinates
			// based on the feature found.
			//popup.setLngLat(coordinates).setHTML(description)
			popup.setLngLat(coordinates).setHTML(description).setMaxWidth('450px')

			return popup
		},
		toTitleCase(txt) {
			return txt.replace(/(^\w|\s\w)(\S*)/g, (_,m1,m2) => m1.toUpperCase()+m2.toLowerCase())
		},
		relocate(snapFlag = false){
			//console.log('relocate map: ', this.map)
			if (this.mapConfig.map) {
				var currentZoom = this.mapConfig.map.getZoom()
				var zoomToUse = currentZoom
				if (zoomToUse <= this.defaultZoom) {
					zoomToUse = this.defaultZoom
				}
				if (snapFlag) {
					this.mapConfig.map.setCenter(this.coordinates)
				} else {
					if (Math.abs(this.mapConfig.map.getCenter()['lng']-this.coordinates['lng'])<0.1 && Math.abs(this.mapConfig.map.getCenter()['lat']-this.coordinates['lat'])<0.1) {
						//console.log("Fast zoom")
						var zoom_duration = 4000
					} else {
						//console.log("Slower zoom")
						var zoom_duration = 12000
					}

					//console.log('relocate: ', this.coordinates)

					this.mapConfig.map.flyTo({
						duration: zoom_duration,
						zoom: zoomToUse,
						center: this.coordinates,
						essential: true // this animation is considered essential with respect to prefers-reduced-motion
					})
				}
			}
		},
		markerAdded(mapEvent){
			this.marker = mapEvent.marker //provides handle for marker
		},
		mousePos(e) {
				const canvas = this.map.getCanvasContainer();
				const rect = canvas.getBoundingClientRect();
				return new Mapbox.Point(
				e.clientX - rect.left - canvas.clientLeft,
				e.clientY - rect.top - canvas.clientTop
				);
		},
		mouseDown(e) {
			// Capture the first xy coordinates
				if (this.controls.drawMode) {
					this.addToMapPoints(this.mousePos(e))
					//console.log(this.mapConfig.mapPoints)
					//console.log(this.mousePos(e))
				}
		},
		handleSelectionClick(mapEvent) {
			if (this.selectingNewLocation || this.addingNewAsset) {
				if (this.addingNewAsset) {

					var city_name
					var county_fips
					if (this.user.access.cities.length > 0) {
						city_name = this.user.access.cities[0]
					} else {
						city_name = 'N/A'
					}
					if (this.user.access.blockgroups.length > 0) {
						county_fips = this.user.access.blockgroups[0].split('-')[0] + '-' + this.user.access.blockgroups[0].split('-')[1]
					} else {
						county_fips = 'N/A'
					}
					this.currNewAsset.cityAndCounty = city_name + '|' + county_fips
					this.currNewAsset.source = "User-Generated"
					if (this.userAddedIds.length == 0) {
						this.currNewAsset.id = "UserGen-pts-" + this.user.email.split('@')[0] + "-0"
					} else {
						this.currNewAsset.id = "UserGen-pts-" + this.user.email.split('@')[0] + "-" + String(parseInt(this.userAddedIds.slice(-1)[0].split("-").slice(-1)[0]) + 1)
					}
					this.currNewAsset.location == "OG_location_lng_lat,undefined,undefined"
					this.userAddedIds.push(this.currNewAsset.id)
				}
				//console.log(mapEvent)
				//console.log(this.currAsset)
				let featuresUnderClick = map.queryRenderedFeatures(mapEvent.point);
				featuresUnderClick.forEach(layer => {
					if (layer.source.includes('properties')) {
						if (layer.properties.id) {
							this.underClickAddress = layer.properties.id
						} else if (layer.properties.structureId) {
							this.underClickAddress = layer.properties.structureId
						} else {
							console.log('no underclick address found...')
						}
					}
				})
				this.currAsset.newLat = mapEvent.lngLat.lat.toFixed(7)
				this.currAsset.newLong = mapEvent.lngLat.lng.toFixed(7)
				this.currNewAsset.newLat = mapEvent.lngLat.lat.toFixed(7)
				this.currNewAsset.newLong = mapEvent.lngLat.lng.toFixed(7)
				
				this.confirmSelectionModal = true
				//if (this.underClickAddress) {
				//	this.confirmSelectionModal = true
				//
				//} else {
				//	this.noBuildingModal = true
				//}
				
				
			}
		},
		handlePointAssClick(mapEvent) {
			//console.log(mapEvent.features)
			var properties = mapEvent.features[0].properties
			var selectedFeature = mapEvent.features[0]
			var runFlag = true
			var runLoop = true
			//const features = this.map.querySourceFeatures('CAP_LOC_florida_v5', {
            //    sourceLayer: 'CAP_LOC_florida_v5'
            //});
			var idField
			if (this.user.email == 'BDefoe-Surprenant@ecfrpc.org' || this.user.email == 'mango@hightide.ai') {
				idField = '_id'
			} else {
				idField = 'id'
			}
			mapEvent.features.forEach(feature =>{
				if (runLoop) {
					if (this.removedIDs.includes(feature[idField]) && !(feature.source.includes('point'))) {
						runFlag = false
					} else {
						runFlag = true
						runLoop = false
						properties = feature.properties
						selectedFeature = feature
					}
				}
			})
			//console.log(properties)
			if (!this.polyInteraction) {
				if (mapEvent.features[0].source.includes('CAPoly_')) {
					runFlag = false
				}
			}
			if (!this.pointInteraction) {
				if (mapEvent.features[0].source.includes('CAP_') || mapEvent.features[0].source.includes('point')) {
					runFlag = false
				}
			}
			if (!this.lineInteraction) {
				if (mapEvent.features[0].source.includes('CAL_')) {
					runFlag = false
				}
			}
			if (this.selectingNewLocation || this.addingNewAsset) {
				runFlag = false
			}
			if (runFlag) {
				let featuresUnderClick = map.queryRenderedFeatures(mapEvent.point);
				featuresUnderClick.forEach(layer => {
					if (layer.source.includes('properties')) {
						if (layer.properties.id) {
							this.assetOverBuilding = true
						} else if (layer.properties.structureId) {
							this.assetOverBuilding = true
						} else {
							console.log('no underclick address found...')
						}
					}
				})
				const currDateTime = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
				this.currAsset.time = currDateTime
				var metadataChanged = false
				//console.log(properties)
				this.metadataChangedIds.forEach(item => {
					if (item.id == properties['CA_ID']){
						metadataChanged = true
						this.currAsset.name = item.name
						this.currAsset.ogName = item.ogName
						this.currAsset.class = item.class
						this.currAsset.ogClass = item.ogClass
						this.currAsset.isBuilding = item.isBuilding
					}
				})
				if (!metadataChanged) {
					this.currAsset.class = properties['CA_class']
					this.currAsset.ogClass = properties['CA_class']
					this.currAsset.isBuilding = 'Yes'
					if (!properties['CA_Name']) {
						this.currAsset.name = 'Unknown Name'
						this.currAsset.ogName = 'Unknown Name'
					} else {
						this.currAsset.name = this.toTitleCase(properties['CA_Name'])
						this.currAsset.ogName = this.toTitleCase(properties['CA_Name'])
					}
				}
				this.currAsset.source = this.toTitleCase(properties['CA_Source'].toLowerCase().split('_')[0].replace(/\-/g,' '))
				if (properties['CA_Source'].split('_').length > 1) {
					this.currAsset.type = this.toTitleCase(properties['CA_Source'].toLowerCase().split('_')[1].replace(/\-/g,' '))
					if (properties['CA_Source'].split('_').length > 2) {
						this.currAsset.subtype = this.toTitleCase(properties['CA_Source'].toLowerCase().split('_')[2].replace(/\-/g,' '))
					} else {
						this.currAsset.subtype = undefined
					}
				} else {
					this.currAsset.type = undefined
					this.currAsset.subtype = undefined
				}
				this.currAsset.cityAndCounty = properties['city_name'] + '|' + properties['full_fips']
				this.currAsset.location = 'OG_location_lng_lat,' + String(properties['lng']) + ',' + String(properties['lat'])
				this.currAsset.layerSource = selectedFeature.source
				if (properties['CA_Source_w_Parcel']) {
					this.currAsset.DORUC = properties['CA_Source_w_Parcel'].split('_')[0]
				} else {
					this.currAsset.DORUC = undefined
				}
				if (properties['CA_Surf'] == 1 || properties['CA_Surf'] == 2) {
					this.currAsset.surf = true
				} else {
					this.currAsset.surf = false
				}
				this.currAsset.id = selectedFeature.id
				this.currAsset['_id'] = properties['_id']
				this.criticalAssetModal = true
			}
		},
		resetCurrentAsset(){
			this.criticalAssetModal = false
			this.assetOverBuilding = false
			this.currAsset = {
				id: undefined,
				_id: undefined,
				name: undefined,
				ogName: undefined,
				location: undefined,
				DORUC: undefined,
				source: undefined,
				layerSource: undefined,
				surf: undefined,
				class: undefined,
				ogClass: undefined,
				type: undefined,
				subtype: undefined,
				newLong: undefined,
				newLat: undefined,
				cityAndCounty: undefined,
				isBuilding: 'Yes',
				time: undefined,
			}
			this.currNewAsset = {
				id: undefined,
				_id: undefined,
				name: undefined,
				ogName: undefined,
				location: undefined,
				DORUC: undefined,
				source: undefined,
				layerSource: undefined,
				surf: undefined,
				class: undefined,
				ogClass: undefined,
				type: undefined,
				subtype: undefined,
				newLong: undefined,
				newLat: undefined,
				cityAndCounty: undefined,
				isBuilding: 'Yes',
				time: undefined,
			}
		},
		saveMetadataChanges(){
			console.log('MetaData Change for asset: ',this.currAsset)
			this.addCA_Action('metadataChange',this.currAsset)
			this.resetCurrentAsset()
		},
		cancelAssetMove(){
			this.confirmSelectionModal = false
			this.resetCurrentAsset()
			this.selectingNewLocation = false
			this.addingNewAsset = false
			this.map.getCanvas().style.cursor = ''
		},
		includeAsset(){
			console.log('Asset included: ',this.currAsset)
			this.criticalAssetModal = false
			this.addCA_Action('includeAsset',this.currAsset)
			this.resetCurrentAsset()
		},
		removeAsset(){
			console.log('Asset hidden: ',this.currAsset)
			if (this.currAssetLayerSource == 'point') {
				this.addCA_Action('removeJoinedAsset',this.currAsset)
			} else {
				this.addCA_Action('remove',this.currAsset)
			}
			this.resetCurrentAsset()
		},
		confirmLocation(){
			console.log('Asset moved: ',this.currAsset)
			this.confirmSelectionModal = false
			if (this.selectingNewLocation) {
				this.addCA_Action('joinToBuilding',this.currAsset)
				this.selectingNewLocation = false
			} else {
				this.addCA_Action('addNewAsset',this.currNewAsset)
				this.addingNewAsset = false
			}
			this.resetCurrentAsset()
			this.map.getCanvas().style.cursor = ''
			//this.map.setPaintProperty(this.mapConfig.layers.criticalAssets.slice(-1)[0], 'circle-stroke-opacity',['case',['feature-state', 'qced'],0,1])

		},
		denyLocation(){
			this.confirmSelectionModal = false
			this.underClickAddress = undefined

		},
		joinToBuilding(){
			this.criticalAssetModal = false
			this.selectingNewLocation = true
			this.map.getCanvas().style.cursor = 'crosshair'
		},
		addNewAsset(){
			if (!this.currNewAsset.class || !this.currNewAsset.name) {
				this.incorrectInput = true
			} else {
				this.incorrectInput = false
				this.newAssetModalLocal = false
				this.addingNewAsset = true
				this.map.getCanvas().style.cursor = 'crosshair'
			}
		},
		handleClick(mapEvent) {
			var stopFlag = false
			//console.log(mapEvent)
			if (!this.selectingNewLocation && !this.addingNewAsset){
				if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
					let featuresUnderClick = map.queryRenderedFeatures(mapEvent.point);
					//console.log(featuresUnderClick)
					featuresUnderClick.forEach(layer => {
						if (layer.source.includes('CAP_') || layer.source == 'point'){
							stopFlag = true
						}
						if (this.polyInteraction) {
							if (layer.source.includes('CAPoly_')) {
								stopFlag = true
							}
						}
						if (this.lineInteraction) {
							if (layer.source.includes('CAL_')) {
								stopFlag = true
							}
						}
					})
				}
				
				if (!stopFlag) {
					if (this.componentRefs['timeline-container']) {
						this.componentRefs['timeline-container'].className = 'timeline-container'
					}
					
					//console.log(mapEvent.features)
					const properties = mapEvent.features[0].properties	
					//console.log(properties)
					this.setRouteIsLocation(false)
					this.setSidePanelVisibility(true)
					if (this.componentRefs['timeline-container']) {
						this.componentRefs['timeline-container'].className = 'timeline-container'
					}

					let id, division, layerLabel, clickedLayer
					var equityClick = false
					if ('NOAA2022_100year_2050_atRisk' in properties) {
						layerLabel = this.mapConfig.currentLayer
						id = properties['blockgroupId']
						division = 'blockgroup'
						equityClick = true
						
					} else if ( 'useCode' in properties ) {
						layerLabel = this.mapConfig.currentLayer
						if ('structureId' in properties) {
							id = properties['structureId']
						} else {
							id = properties['id']
						}
						division = 'property'

					} else if ( 'key' in properties) {
						layerLabel = this.mapConfig.currentLayer
						id = properties['key']
						division = 'blockgroup'

					} else if ( 'blockgroupId' in properties) {
						layerLabel = this.mapConfig.currentLayer
					} else {
						if (properties['id'].length  == 6){
							id = properties['id']
							layerLabel = this.mapConfig.currentLayer
							this.defaultZoom = this.mapConfig.layerSettings.county.startingZoom
							division = 'county'
						} else {
							id = properties['id']
							layerLabel = this.mapConfig.currentLayer
							division = 'blockgroup'
						}
					}
					if (equityClick) {
						clickedLayer = 'equity'
					} else {
						clickedLayer = division
					}
					var idx = 0
					if (this.mapConfig.clickedID || this.mapConfig.clickedID == 0) {
						if (mapEvent.features[0].id != this.clickedID) {
							this.map.removeFeatureState({
								source: this.mapConfig.lastClickedLayer,
								sourceLayer:this.mapConfig.lastClickedLayer,
								id: this.mapConfig.clickedID
							});
						}
					} 
					this.map.setFeatureState({
						source: this.mapConfig.layers[clickedLayer][idx],
						sourceLayer: this.mapConfig.layers[clickedLayer][idx],
						id: mapEvent.features[0].id,
					}, {clicked: true
					});
					
					this.mapConfig.clickedID = mapEvent.features[0].id
					this.mapConfig.lastClickedLayer = this.mapConfig.layers[clickedLayer][idx]
					
					
					this.defaultZoom = this.mapConfig.layerSettings[division].startingZoom
					
					if (this.popup) {
						this.popup.remove()
					}

					this.popup = new Mapbox.Popup({
						closeButton: false,
						closeOnClick: false
					})
					
					if (this.isMobile) {
						this.makePopup(mapEvent, division)
					}
					event('map_property_click', {'event_category': 'map', 'newLocationID' : id, 'location_ID' : window.location.href.split('/').slice(-1)})
					confection.submitEvent('property_click','location ID: ' + window.location.href.split('/').slice(-1) + ', new location ID: ' + id)
					if (equityClick) {
						router.push({name: this.$route.name, params: { view: 'socioeconomic', type: division, id: id}})
					} else {
						//console.log({name: this.$route.name, params: { view: 'economic', id: encodeURIComponent(id), type: division }})
						if (id.includes('/')) {
							id = id.replace('/','forwardSlash')
						}
						router.push({name: this.$route.name, params: { view: 'economic', id: encodeURIComponent(id), type: division }})
					}
					if (!this.isMobile) {
						if (this.componentRefs['panel-content']) {
							if (this.componentRefs['panel-content'].parentElement) {
								this.componentRefs['panel-content'].parentElement.scrollTo({top: 0,left: 0,behavior: "smooth"})	
							}
						}
					}
				}
			}
		},
		checkToken(){
			if (new Date(new Date().getTime() + 20*60*1000) > getTokenExpirationDate(getAuthToken())) {
				const currUser = getUser()
				loginUser(currUser['email'], decode(currUser['hashbrown']))
			}
		},
		...mapActions(useSurfStore, ['updateAAL', 'updateEL']),
		...mapActions(useConfigStore, ['setDataSource','setYearOptions','toTitleCase','setNewAssetModalState','setUndoActionModalState','loadCA_Action','makeCriticalAssetFilter','addCA_Action','addToMapPoints','setRouteIsLocation','addMapboxLayer','moveElementCSS','changeFaviconToArkly','updateZoomState','addDraw','updateToggleDrawMode','setLocation', 'setZoom', 'setElevation', 'setSidePanelVisibility', 'setPropertyLayer', 'setDataSource', 'addComponentRef', 'setMapDimensions', 'setMapConfigVersion']),	
	},
	computed: {
		legendStyle(){
			if (this.mapConfig.arklyRoutes.includes(this.$route.name)) {
				if (this.isMobile){
					return {'margin-bottom': '5px'}
				} else {
					return {'margin-bottom': '10px'}
				}
			} else {
				return {'margin-bottom': '0px'}
			}
		},
		mapBoxClass(){
			if (!this.isMobile) {
				return 'map'
			} else {
				if (this.isRouteLocation) {
					if (this.mapConfig.arklyRoutes.includes(this.$route.name)) {
						return 'map map-no-footer'
					} else {
						return 'map map-no-footer-govsub'
					}
				} else {
					return 'map map-with-footer'
				}
			}
		},
		isItArklyLogo(){
			if (this.$route.meta.platform == 'govSub') {
				return 'hightide'
			} else {
				return 'arkly'
			}
		},
		isItArkly(){
			if (this.$route.meta.platform == 'govSub') {
				return 'false'
			} else {
				return 'true'
			}
		},
		...mapState(useConfigStore, ['userAddedIds','actionSaveDate','loadedActionsNumber','undoneAssetName','newAssetModal','undoActionModal','actionUndone','criticalAssetClasses','metadataChangedIds','geojson','removedIDs','pointInteraction','polyInteraction','lineInteraction','draw','isRouteLocation','isSidePanelVisible','isEquityVisible','mapboxLookupDict','changedBaselayer','zoomedIn','textContent', 'componentRefs','url', 'controls', 'mapConfig','baselayer','settings', 'isMobile', 'user', 'isPuertoRico','draw']),
		...mapState(useSurfStore, ['abbreviateNumbers','coordinates', 'surfData','propertyNotFound']),
	}, 
	watch: {
		undoActionModalLocal(newState){
			if (!newState){
				this.setUndoActionModalState(newState)
			}
		},
		undoActionModal(newState) {
			this.undoActionModalLocal = newState
		},
		newAssetModalLocal(newState){
			if (!newState){
				this.setNewAssetModalState(newState)
			}
		},
		newAssetModal(newState) {
			this.newAssetModalLocal = newState
		},
		pointInteraction(newState) {
			if (newState) {
				this.mapConfig.layers['criticalAssets'].forEach(layer => {
					if (layer.includes('CAP_')) { 
						this.enableOrDisableInteraction(layer,true)
					}
				})
			} else {
				this.mapConfig.layers['criticalAssets'].forEach(layer => {
					if (layer.includes('CAP_')) { 
						this.enableOrDisableInteraction(layer,false)
					}
				})
			}
		},
		polyInteraction(newState) {
			if (newState) {
				this.mapConfig.layers['criticalAssets'].forEach(layer => {
					if (layer.includes('CAPoly_')) { 
						this.enableOrDisableInteraction(layer,true)
					}
				})
			} else {
				this.mapConfig.layers['criticalAssets'].forEach(layer => {
					if (layer.includes('CAPoly_')) { 
						this.enableOrDisableInteraction(layer,false)
					}
				})
			}
		},
		lineInteraction(newState) {
			if (newState) {
				this.mapConfig.layers['criticalAssets'].forEach(layer => {
					if (layer.includes('CAL_')) { 
						this.enableOrDisableInteraction(layer,true)
					}
				})
			} else {
				this.mapConfig.layers['criticalAssets'].forEach(layer => {
					if (layer.includes('CAL_')) { 
						this.enableOrDisableInteraction(layer,false)
					}
				})
			}
		},
		mapBoxClass(newValue) {
			setTimeout(()=> {
				this.mapConfig.map.resize()
			},500)
		},
		'$route': {
			handler: function(result) {
				this.setYearOptions(this.mapConfig.yearOptions[this.$route.meta.region])	
				this.setDataSource(this.mapConfig.dataSources[this.$route.meta.region])
				
				//console.log(result)
				setTimeout(()=> {
					this.mapConfig.map.resize()
				},500)
			},
			deep: true,
		},
		changedBaselayer(newState) {
			// change map baselayer here
			if (newState == 'Mapbox') {
				event('mapbox_baselayer', {'event_category': 'map', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('mapbox_baselayer','location ID: ' + window.location.href.split('/').slice(-1))
				this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'none')
				//this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', ["interpolate",["linear"],["zoom"],16,0,16.5,1])
			} else if (newState == 'Satellite') {
				event('satellite_baselayer', {'event_category': 'map', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('satellite_baselayer','location ID: ' + window.location.href.split('/').slice(-1))
				this.map.setLayoutProperty('mapbox-satellite', 'visibility', 'visible')
				this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 1)
			}
		},
		coordinates: {
			handler: function(result) {
				//console.log(this.$route.params)
				if (this.$route.params.priorURL) {
					if (this.$route.params.priorURL == 'home') {
						this.relocate(true)
						var homeFlag = true
					} else {
						var homeFlag = false
					}
				} else {
					var homeFlag = false
				}
				
				if (homeFlag) {
					setTimeout(() => {
						if (this.marker) {
							this.marker.setLngLat(result)
							this.marker.addTo(this.mapConfig.map)
						}
					},1000)
				} else {
					this.relocate()
					if (this.marker) {
						this.marker.setLngLat(result)
						this.marker.addTo(this.mapConfig.map)
					}
				}
			},
			deep: true,
		},
		surfData: {
			handler: function(result) {
				//console.log(result)
			},
			deep: true,
		},
		isMobile(newState) {
			if (this.mapConfig.arklyRoutes.includes(this.$route.name)) {
				setTimeout(() => {
					this.mapConfig.map.resize()
				},500)
				if (newState) {
					this.moveElementCSS('-10','35','.mapboxgl-ctrl-top-right','top')
					this.moveElementCSS('95','30','.mapboxgl-ctrl-logo','bottom')
					this.moveElementCSS('0','15','.mapboxgl-ctrl-bottom-right','bottom')
				} else {
					this.moveElementCSS('35','-10','.mapboxgl-ctrl-top-right','top')
					this.moveElementCSS('30','95','.mapboxgl-ctrl-logo','bottom')
					this.moveElementCSS('15','0','.mapboxgl-ctrl-bottom-right','bottom')
				}
			}
		},
		propertyNotFound: {
			handler: function(newState) {
				if (newState.isVisible) {
					//console.log(newState)
				}
				
			},
			deep: true,
		},
	},
}
</script>

<style lang="scss">
@import "../../style/oruga.scss";
@import "../../style/arkly.scss";

.assetName{
	display: flex;
    align-items: center;
	margin-bottom: 5px;
	gap: 5px;
}

.editableField{
	min-height: 35px !important;
	resize: none !important;
	height: 50px !important;
	min-width: fit-content !important;
	width: 100% !important;
}

.mapboxgl-ctrl-group .mapboxgl-ctrl{
	display: none !important;
}

.criticalAssetPopup {
	margin: 20px;
	min-width: 600px;
}

.criticalAssetButtons {
	display: flex;
	margin-top: 20px;
	gap: 20px;
}

.arkly-map {
	display: flex;
	position: relative;
	flex-direction: column;
	height: 100%;
	width: 100%;
	//left: -24px;
	background-color: rgba(255,255,255,0.1);
	z-index: 5;
}

.map {
	height: 100%;
	width: 100%;
	min-height: 445px;
}

.map-container {
	min-height: 445px;
}

.map-no-footer {
	height: 100vh;
}

.map-no-footer-govsub {
	height: calc(100vh - 33px);
}

.map-with-footer {
	height: 67vh;
}

.mapboxgl-map{
	font-family: $body-family !important;
}

@media only screen and (max-width: $mobile-breakpoint) {
	.legend {
		right: 400px;
	}

	.arkly-map {
		left: 0;
	}



}

.tl-fullscreen {
	width: 100vw !important;
}

</style>
