<template>
<div v-if='tutorialSteps != [] && textContent.buttons'>
<ul 
	class='nav-menu' 
	:class='classes' 
	tabindex="0"
	@focusout='closeMenu'
>
	<div v-show='isMobile && !isVisible'
	class='hamburger-menu'
	>
		<inline-svg	
		:src="iconSRC('three-dots')"
		height='37'
		title='menu'
		@click='isVisible = true'
		/>
	</div>
	<div 
		v-if='textContent.buttons'
		v-show='isVisible || !isMobile' 
		class='menu-item' 
		v-for='item in menu'
	> 
		<span class='menu-item' @click='item.link' :ref='item.ref'>
			<div class='label text-wrapper' v-html='item.label'/>
			<div class='icon-wrapper'>
				<inline-svg	
					class='menu-item-icon'
					:src='iconSRC(item.icon)'
					:title='item.icon'
				/>
			</div>
		</span>
	</div> 
</ul>

<Teleport to='body'>

	<bug-report v-model='isBugReportVisible' />
	<risk-report v-model='isRiskReportVisible' />
	<tutorial 
		v-if='tutorialSteps != [] && textContent.buttons'
		v-show='tutorial.isVisible && tutorialSteps != []'
		:steps="tutorialSteps"
	/>
	<share v-model='isShareVisible' />

</Teleport>

</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import InlineSvg from 'vue-inline-svg'
import Tutorial from '@/components/arkly/Tutorial'
import BugReport from '@/components/BugReport'
import RiskReport from '@/components/RiskReport'
import Share from '@/components/arkly/Share'
import { event } from 'vue-gtag'

export default {
	name: 'Button',
	components: {
		'inline-svg': InlineSvg,
		'tutorial': Tutorial,
		'bug-report': BugReport,
		'risk-report': RiskReport,
		'share': Share,
	},
	created() {
		this.isVisible = window.innerWidth > 800
		this.isMobile = window.innerWidth < 800
		window.addEventListener('resize', () => {
			this.isMobile = window.innerWidth < 800
			this.isVisible = window.innerWidth > 800
		})
	},
	mounted() {
		this.addComponentRef('tutorial-button', this.$refs.tutorialButton[0])
		this.addComponentRef('share-button', this.$refs.shareButton[0]) 
		this.addComponentRef('bugReport', this.$refs.bugReport[0])
		this.addComponentRef('riskReport', this.$refs.riskReport[0])

	},
	data() {
		return {
			isMobile: undefined,
			//isVisible: this.isNavMenuVisible,
			isBugReportVisible: false,
			isShareVisible: false,
			isRiskReportVisible: false,
		}
	},
	methods: {
		showRiskReport(){
			this.isRiskReportVisible = true
			event('risk_report_click', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('risk_report_click','location ID: ' + window.location.href.split('/').slice(-1))
		},
		showTutorial() {
			this.setTutorialVisibility(true)
		},
		showBugReport() {
			this.isBugReportVisible = true
			event('bug_report_click', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('bug_report_click','location ID: ' + window.location.href.split('/').slice(-1))
		},
		showShare() {
			this.isShareVisible = true
			event('share_button_click', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('share_button_click','location ID: ' + window.location.href.split('/').slice(-1))
		},
		iconSRC(icon) {
			return require(`../../assets/icons/${icon}.svg`)
		},
		convertToMobile(label) {
			return label.replace('<br>', '')
		},
		closeMenu() {
			if (this.isVisible && this.isMobile) {
				this.isVisible = false
			}
		},
		...mapActions(useConfigStore, ['addComponentRef', 'setTutorialVisibility','setNavMenuVisibility']),
	},
	computed: {
		// Formatting region setting
		tutorialSteps() {
			if (this.textContent['buttons'] && this.textContent['tutorial']) {
				const steps = Object.values(this.textContent['tutorial']).filter(step => {
					return step.regions.includes(this.$route.meta.region)
				})
				return steps
			} else {
				return []
			}
		},
		menu() {
			return [
				{
					label: this.textContent['buttons']['take-a-tour-btn']['label'],
					icon: 'person',
					link: this.showTutorial,
					ref: 'tutorialButton',
				},
				{
					label: this.textContent['buttons']['share-btn']['label'],
					icon: 'share',
					link: this.showShare,
					ref: 'shareButton',
				},
				{
					label: this.textContent['buttons']['send-feedback-btn']['label'],
					icon: 'message',
					link: this.showBugReport,
					ref: 'bugReport',
				},
				{
					label: this.textContent['buttons']['risk-report-btn']['label'],
					icon: 'book',
					link: this.showRiskReport,
					ref: 'riskReport',
				},
			]
		},
		isVisible: {
			get() {
				return this.isNavMenuVisible
			},
			set(value) {
				this.setNavMenuVisibility(value)
			}
		},
		classes() {
			if (this.isMobile) {
				return ['mobile']
			} else {
				return ['']
			}
		},
		...mapState(useConfigStore, ['textContent', 'windowDimensions', 'isTutorialVisible', 'tutorial', 'isNavMenuVisible']),
	},
	watch: {
		formattedMenu: {

		},
		isMobile(newValue) {
			if (!newValue) {
				this.isVisible = true
			}
		},
		isTutorialVisible(newValue) {
			//console.log(newValue)
		}
	}
}

</script>

<style lang="scss" scoped>
@import "../../style/arkly.scss";

//////////////////////////////////
// Desktop 
/////////////////////////////////
.nav-menu {
	display: flex;
	flex-direction: row;
	gap: 4px;
	padding: 0;
}

.nav-menu .menu-item {
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: space-around;
//	padding: 0px 7.99445px;

	text-align: center;

	width: 84px;
	height: 94px;

	background: $light-sand;
	border-radius: 3.99722px;

}

.nav-menu .menu-item:hover {
	cursor: pointer;
	filter: brightness(90%);
}

.nav-menu .menu-item .label {
	/* Button label */
	//width: 50px;
	height: auto;

	/* Web/Label */
	font-family: $header-family;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;

	/* or 133% */
	display: flex;
	align-items: center;
	text-align: center;
	letter-spacing: 0.4px;

	color: #000000;
}

.nav-menu .menu-item .menu-item-icon svg{
	height: 44px;
	max-width: 34px;
	color: black;
	padding: 10px;
}

///////////////////////////////////////////
// Mobile
//////////////////////////////////////////

.hamburger-menu {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	width: 100%;
	min-width: 15px;
}

.hamburger-menu svg {
	height: 34px;
	max-width: 34px;
	color: black;
	//padding: 10px;
}

.hamburger-menu:hover svg {
	color: grey;
	filter: brightness(90%);
}

@media screen and (max-width: $mobile-breakpoint ) {
	$mobile-width: 390px;

	.nav-menu {
		position: absolute;
		right: 0px;
		top: 0;
		flex-direction: column;	
		width: auto;
		overflow: visible;
		z-index: 20;
		background-color: white;
	}

	.nav-menu .menu-item {
		/* Auto layout */
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		padding: 10px 20px;
		gap: 20px;

		width: $mobile-width;
		max-width: 390px;
		height: 72.67px;

		background: $light-sand;
		border-radius: 3.99722px;
	}

	.nav-menu .menu-item .label {
		width: auto;
		text-align: end;
		/* or 133% */
		display: flex;
		align-items: center;
		text-align: center;
		white-space: nowrap;
		padding-bottom: 0;

	}

	.nav-menu .menu-item br {
		display: none;
	}
}


</style>
