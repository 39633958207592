<template>
 
<div  
	class='mapboxgl-ctrl-group mapboxgl-ctrl custom-control' 
	:class="{'no-margin': withoutMargin, 'is-first': isFirst}"
	:style="{'height': height}"
>
		<div class='container' v-if='map != null'>
			<slot />
		</div>
</div>

</template>

<script>
import InlineSvg from 'vue-inline-svg'

class BaseControl {
	constructor({
		title = "",
		element = {}
	}) {
		this._title = title
		this._element = element
	}
	
	onAdd(){ return this._element }

	onRemove(){ return this._element.parentNode.removeChild(this._element) }
	
}

export default {
	components: {
		InlineSvg,
	},
	props: {
		'map': {
			type: Object,	
		}, 
		'position': {
			type: String,
		}, 
		'isFirst': {
			type: Boolean,
			default: false
		},
		'icon': {
			type: String,
			default: 'lift_house'
		},
		'label': {
			type: String,
			default: 'House Lifting'
		},
		'withoutMargin': {
			type: Boolean,
			default: false,
		},
		'selected': {
			type: String,
			default: '',
		},
		'isVisible': {
			type: Boolean,
			default: true
		},
		'height': {
			type: String,
			default: 'auto',
		},

	},
	data() {
		return {
			controlRef: null,
			control: null,
		}
	},
	mounted() {
		this.loadControl()
	},
	destroyed() {
		this.map.remove(this.control)
	},
	watch: {
		map: function(newValue) {
			this.loadControl()
		},
	},
	methods: {
		loadControl(){
			if (this.map != null) {			
				this.control = new BaseControl({title: 'title', element: this.$el})
				this.map.addControl(this.control, this.position)
			
				// Possible control positions as defined by Mapbox
				const possiblePositions = [
					'mapboxgl-ctrl-top-right',
					'mapboxgl-ctrl-top-left',
					'mapboxgl-ctrl-bottom-right',
					'mapboxgl-ctrl-bottom-left',
				]

				possiblePositions.forEach(position => {
					// Get a specific control Container (ie mapboxgl-ctrl-top-right)
					const controlContainer = document.getElementsByClassName(position)[0]
					// Get a list of controls in that container
					const controls = Array.from(controlContainer.children)

					// For Each control in a container, check if any of the controls
					// contain the 'is-first' className. If they do, then move that
					// control to the first position in the container
					controls.forEach(control => {
						if (control.className.includes('is-first')) {
							controlContainer.insertBefore(control, controlContainer.firstChild)
						}
					})
				})
				
			}
		},
	},
}

</script>

<style lang="scss" scoped> 
@import "../../style/mapbox.scss";
@import "../../style/style.scss";
@import '../../../node_modules/mapbox-gl/dist/mapbox-gl.css';

.no-margin {
	top: 0 !important;
	left: 0 !important;
	margin-left: 0 !important;
	margin-top: 0 !important;
}

.custom-control {	
	background-color: transparent !important;
	box-shadow: none;
}

.container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	//z-index: 30;
}

</style>
