<template>
	<!-- 
  <teleport to="head">
  </teleport>
  "($route.meta.platform != 'arkly') && ($route.fullPath == '/login')"
  :class="{ 'no-navbar': ($route.fullPath == '/' || ($route.meta.platform == 'arkly'))}"
	
	<navbar v-if="$route.fullPath.includes('/login')"/>
	-->
	<router-view 
		v-if="textContent != {} && 'property_view' in textContent"
		class='router-view'
		:class="{ 'no-navbar': (!$route.fullPath.includes('/login'))}"
	/>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'


export default {
	components: {
		//Navbar,
	},
	async created() {
		if (window.location.hostname.includes('localhost') || window.location.hostname.includes('dev')){
			this.setDevOrLocal(true)
		}
		this.cmsCollections.forEach(collection => this.loadCmsQuery(collection))
	},
	mounted() {
		this.watchWindowSize()	
		this.getUserIP()
	

	},
	computed: {
		...mapState(useConfigStore, ['screenSize', 'textContent', 'text', 'cmsCollections']),
		...mapState(useSurfStore, ['rocketflood']),
	},
	methods: {
		...mapActions(useConfigStore, ['setDevOrLocal','loadCmsQuery', 'watchWindowSize', 'getUserIP']),
		...mapActions(useSurfStore, ['setTestingEnvironment'])
	},
	watch: {
		'$route': {
			handler: function(newRoute, oldRoute) {
				// localhost favicon has to be set after App.vue is 
				// 'mounted' because it needs the individual 'view'
				// to be 'mounted' instead, because the meta.platform 
				// is based on this.$route
				if (
					window.location.hostname.includes('localhost') &&
					newRoute.meta.platform != oldRoute.meta.platform
				) {
					//this.setFavicon()
				}
			},
			deep: true,
		},
	},
	directives: {	

	}
}
	
</script>

<style lang="scss">
@import './style/mapbox.scss';
@import './style/arkly.scss';
@import './style/style.scss';

.mapboxgl-map {
	font-family: $font-family;
}

body {
	margin: 0px;
}

#bannerb4d5 .toggler{
	display: none !important;
}

#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.router-view {
	margin-top: $navbar-height;
}

.no-navbar {
	margin-top: 0 !important;
}

</style>
