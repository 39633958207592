<template>
	<div class='histogram' :style="{ height: height, width: width }">
	<h2 class='title'> 
		{{ title }}
	</h2>

	<div class='chart'>
		<barWithErrorBarChart 
			:data='chartData' 
			:options='options'
			style="width: 100%; height: 100% !important;"
			@chart:render="handleChartRender"
		/>
	</div>
</div>
</template>

<script>
import { Chart } from 'chart.js'
import { createTypedChart } from 'vue-chartjs'
import { BarWithErrorBarsController } from 'chartjs-chart-error-bars'
import { mapState } from 'pinia'
import { useConfigStore } from '@/store/config'

Chart.register(BarWithErrorBarsController)

const BarWithErrorBarChart = createTypedChart(BarWithErrorBarsController.id, BarWithErrorBarsController)

export default{
	name: 'Histogram',
	components: {
		BarWithErrorBarChart
	},
	props: {
		//expectedLoss: {},
		town: {},
		data: {}, 
		buckets: {},
		yAxisLabel: {
			type: String,
			default: 'Number of Properties',
		},
		xAxisLabel: {
			type: String,
		},
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: '100%',
		},
		percentile: {},
		title: {
			type: String,
			default: 'Households at risk of displacement'
		},
	},
	data() {
		return {
			stormColors: {
				'cat1': '#ffffcc',
				'cat2': '#ffe775',
				'cat3': '#ffc140',
				'cat4': '#ff8f20',
				'cat5': '#ff6060',
			},
			currentCategory: null,
			primaryColor: '#5082c4',
			secondaryColor: '#AFC0E4',
		}
	},
	mounted() {

		Chart.defaults.font.family = this.bodyFont.family
		Chart.defaults.font.size = this.bodyFont.size
		Chart.defaults.font.weight = '400'

	},
	computed: {
		chartData: function() {
			return {
				chartHandle: undefined,
				labels: this.labels,
				datasets: [
					{
						data: this.formattedData,
						barPercentage: 1,
						errorBarLineWidth: 3,
						errorBarWhiskerLineWidth: 3,
						errorBarWhiskerColor: this.secondaryColor,
						errorBarColor: this.secondaryColor,
						categoryPercentage: 1,
						backgroundColor: this.primaryColor,
						xAxisID: "x",
						yAxisID: "y"
					},
				],
			}
		},
		formattedData: function() {	
			const formattedArray = this.data.map(valueAtYear => {
				function calcDeviation(mean, stdev) {
					if (  mean < 0 ){
						return mean - stdev
					} else {
						return mean + stdev 
					}
				}

				if (valueAtYear['mean']) {
					return {
						y: valueAtYear['mean'],
						yMin: calcDeviation(valueAtYear['mean'], -valueAtYear['stdev']),
						yMax: calcDeviation(valueAtYear['mean'], valueAtYear['stdev'])
					}
				} else {
					return { 
						y: null,
					}
				}
			})
			
			return formattedArray
		},
		yMin: function() {
			const yMins = this.formattedData.map(y => { 
				if (y != undefined) {
					return y['y']
				} else {
					return 0
				}
			})
			return Math.min(...yMins)
		},
		options: function() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						intersect: false,
						titleAlign: 'center',
						callbacks: {
							title: (context) => {
								return null
							}, 
							label: (context) => {
								if (context.parsed.y == null ) {
									return 'No households exist within this income bracket'

								} else {
									let mean = this.abbreviateNumbers(context.parsed.y, 3)
									let lowerBound = this.abbreviateNumbers(context.parsed.yMin, 3)	
									let upperBound = this.abbreviateNumbers(context.parsed.yMax, 3)

									const addDollar = function(value) {
										if (value.includes('-')) {
											return value.replace('-', '-$')
										} else {
											return '$' + value
										}
									}

									return [`The mean discretionary income is ${addDollar(mean)}.`, `The 68% confidence interval is from ${addDollar(lowerBound)} to ${addDollar(upperBound)}.`]
								}

							},
						},
					},
				},
				scales: {
					x: {
						display: false,
						max: this.data.length - 1,
						position: 'bottom',
						grid: {
							
						},
					},
					xB: { 
						title: {
							display: true,	
							text: this.xAxisLabel,
							font: {
								size: 18,
								weight: '600'
							},
							padding: 4,
						},
						offset: false,
						grid: {
							display: true,
							offset: false,
							drawTicks: true,
							tickLength: 7,
							z: 1
						}
					},
					y: {
						title: {
							display: true,
							text: this.yAxisLabel,
							font: {
								size: 14,
								weight: '600'
							},
							padding: 4,
						},
						beginAtZero: false,
						suggestedMin: this.yMin,
						stepSize: 500,	
						ticks: {
							fontSize: 14,
							fontStyle: '400',
							padding: 10,
							callback: (value, index, values) => {
								if (value >= 0) {
									return '$' + this.abbreviateNumbers(value, 3)
								} else {
									return '-$' + this.abbreviateNumbers(Math.abs(value), 3)
									
								}
							},

						},		
					},	
				},
			}
		},
		labels: function() {
			let labels = this.buckets.map((value) => {
				return `$${this.abbreviateNumbers(value, 2)}`
			})
			return labels
		},
		colors: function() {
			
			let colors = this.buckets.map( (value, index, list) => {

			/*	const nextValue = list[index + 1] ? list[index + 1] : Infinity

				if (value <= this.expectedLoss && this.expectedLoss < nextValue && this.$route.params.type != 'city') {
					this.currentCategory = index
					return draw('diagonal', '#5082c4')
				} else { */
					return '#5082c4'
				//} 
			})

			return colors
		},
		...mapState(useConfigStore, ['bodyFont']),
	},
	methods: {
		abbreviateNumbers ( number, precision ) {

			const max = this.max
			const abbrev = {'':1, 'K':1000, 'M':1000000, 'B':1000000000}
			const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
			const order = Math.max(0, Math.min(unrangifiedOrder, Object.keys(abbrev).length -1 ))
			const suffix = Object.keys(abbrev)[order]

			if ((number / Math.pow(10, order * 3)) == 0) {
				return 0
			} else {
				return Number((number / Math.pow(10, order * 3)).toPrecision(precision)) + suffix
			}	
		},	
		handleChartRender: function(e){
			this.chartHandle = e.$context.chart
		},
		getOrdinal: function(number){
			const english_ordinal_rules = new Intl.PluralRules("en", {type: "ordinal"})
			const suffixes = {
				one: "st",
				two: "nd",
				few: "rd",
				other: "th"
			}
			const suffix = suffixes[english_ordinal_rules.select(number)];
			return (suffix)
		},
	},
	watch: {
		expectedLoss: function(newValue) {
			this.chartHandle.update()
		},
		height: function(newValue) {
			for (var id in Chart.instances) {
				Chart.instances[id].resize();
			}
		},
		width: function(newValue) {
			for (var id in Chart.instances) {
				Chart.instances[id].resize();
			}
		},	
	}
}
</script>

<style lang="scss" scoped>
@import '../../style/style.scss';

.chart {
	height: 290px !important;
	grid-area: chart;
}
.histogram {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows:
		auto
		350px;
	grid-template-areas: 
		" title  "
		" chart  ";
	min-height: 350px;  /* NEW */
	min-width: 0;   /* NEW; needed for Firefox */
	width: 100%;
	flex-direction: column;
	color: $grey;
	overflow: hidden;
}


.title {
	padding-bottom: 20px;
	grid-area: title;
}

</style>

