<template>
<span>
	<div id='arkly-footer' v-if="variant == 'arkly'">

		<div class='spacer-left' />

		<div class='content'> 
			
			<div class='list' v-if="'footer' in textContent">
				<p class='link' @click='goToTerms'> 
					{{ textContent['footer']['terms-of-use']['label'] }}
				</p>
				<p class='link' @click='goToPrivacy'> 
					{{ textContent['footer']['privacy-policy']['label'] }}
				</p>
				<p class='link' @click='goToHighTide'> 
					{{ textContent['footer']['about']['label'] }}
				</p>		
				<p class='link' @click='goToContact'> 
					{{ textContent['footer']['contact']['label'] }}
				</p>
			</div>

			<div id='arkly-logo'>
				<inline-svg
					title='Arkly Logo'
					:src="require('@/assets/icons/arkly-logo.svg')"
				/>
					<p class='copyright'> &copy; 2023 Arkly, Inc. {{textContent['brand']['copywrite'] }} </p>
			</div>

			<select v-if="$route.name == 'puertoRicoHome'" v-model='currentLanguage'>
				<option value='en-US'>English</option>
				<option value='es-ES'>Español</option>
			</select>
		</div>

		
	</div>
	<div id="dashboard-footer" :style='style' v-else>
		<div class="center copyright">	
			<div class="copyright"> 
				© 2022 Astra Intelligence, Inc.  
			</div>
			<div class="version"> Version: {{ version  }} </div>
		</div>
	</div>
</span>	


</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import InlineSvg from 'vue-inline-svg'

export default {
	name: 'Footer',
	components: {
		'inline-svg': InlineSvg,
	},
	props: {
		variant: {
			type: String,
			default: undefined,
		},
		borderRadius: {
			type: String,
			default: '0px;'
		},
	},
	mounted() {
		if (this.$route.name != 'puertoRicoHome') {
			this.currentLanguage = 'en-US'
		}
	},
	data() {
		return {
			curveIcon: 'sidepanel-curve',
		}
	},
	computed: {
		currentLanguage: {
			get() {
				return this.userLanguage
			},
			set(newLanguage) {
				this.setUserLanguage(newLanguage)
			},
		},
		style() {
			return {'border-radius': this.borderRadius, 'overflow': 'hidden'}
		},
		iconSRC(icon) {
			return require(`../assets/icons/${icon}.svg`)
		},
		version() {
			if (process.env.VUE_APP_VERSION){
				return process.env.VUE_APP_VERSION
			} else {
				return ''
			}
		},
		...mapState(useConfigStore, ['url', 'textContent', 'setTutorialVisibility', 'userLanguage']),
	},
	watch: {
		textContent(newContent) {
			console.log(newContent)
		},
		userLanguage(newLanguage) {
			console.log(newLanguage) 
		},
	},
	methods: {
		...mapActions(useConfigStore, ['goToEchelon','goToBCorp','goToHighTide', 'goToTerms', 'goToPrivacy', 'goToContact', 'goToFacebook', 'setUserLanguage'])
	}
}
</script>

<style lang="scss" scoped>
//@import "../style/style.scss";
@import "../style/arkly.scss";

.facebook {
	padding-top: 8px;
	float: left;
	height: 25px;
}

.facebook:hover {
	height: 30px;
}

#dashboard-footer .version { 
	display: flex;
	align-items: center;
	justify-content: center;
}

#dashboard-footer .copyright { 
	font-size: 14px; 
	background-color: #fff;
}

#dashboard-footer p { font-size: 12px; }

#dashboard-footer .copyright { grid-column: 2/-2; }


#dashboard-footer .footer-info {
    font-size: 15px;
    padding-left: 10px;
    grid-column: -2/-1;
}

#dashboard-footer {
    display: grid;
    position: relative;
    height: $footer-height;
    background-color: $white;
    overflow: hidden;
	padding-top: 10px;
    padding-bottom: 15px;
    grid-template-columns: 1fr auto 1fr;
    box-shadow: 0 2px 4px 1px rgba(0,0,0,0.2);
    z-index: 1;
	perspective: 1px;	
	border-radius: 20px;
}

////////////////////////////////////////////////////
// Arkly
////////////////////////////////////////////////////
/*
#arkly-footer {
	width: 100vw;
	height: 40vh;

}
*/

.facebook {
	padding-top: 8px;
	float: left;
	height: 25px;
}

.facebook:hover {
	height: 30px;
}

#arkly-logo {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	width: min-content;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	color: white;
	grid-area: logo;
}

#arkly-logo svg {
	height: auto;
	width: 100px;
	padding: 10px;
}

#sidepanel-curve {
	min-width: 100vw;
	min-height: 200px;
	fill: white;	
	color: rgba(0, 0, 0, 0.8);
}

#sidepanel-curve svg {
	min-width: 100vw;
	color: white;
	//filter: url(#blur);
	//backdrop-filter: blur(20px);
}

#sidepanel-curve svg.background {
	filter: url(#blur);
}

#arkly-footer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100vw;
	height: calc(100vw * (915/1491));
	max-height: 100vh; 
	padding: $padding-medium;
	background-color: white !important;	
	z-index:10;
	//background-image: url('../assets/images/footer-background-1.png');
	background-position: bottom;
	//background-position-y: calc(-400px * (915/1491));
	background-size: 100%;
}

#arkly-footer .content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	height: 150px;

}

#arkly-footer .list {
	//background-image: url('../assets/images/footer-background.png');
	//background-size: 100vw;
	//background-position-y: -200px;
	//background-position-x: center;
	//background-repeat: no-repeat;
	//padding-bottom: 3000px;
	//background-color: rgba(0, 0, 0, 0.8);
	width: min-content;
	height: 100%;
	min-height: 100%;
	overflow: clip;
	color: white;
	//filter: url(#blur);
}

#arkly-footer p {
	text-align: left;
	white-space: nowrap;
}

#arkly-footer .link:hover {
	font-weight: bold;
}


@media only screen and (max-width: $mobile-breakpoint) {
	#arkly-footer {
		padding: $padding;
		background-size: cover;
		height: 650px;
	}

	#arkly-footer .content {
		flex-direction: column;
		align-items: flex-start;
		min-height: 150px;
	}

	#arkly-footer .spacer-left {
		min-height: auto !important;
		padding-bottom: 80px;
	}

	#sidepanel-curve {
		width: 100%;
	}

	.container {
		width: 100vw;
	}
}

</style>
 
