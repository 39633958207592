<!-- HTML -->
<template>
	<div class='sidepanel-container' v-show='coordinates && !isRouteLocation && !isMobile' ref='panel' :class="{'hidden': !isPanelVisible}" :style="{'margin-top': topMargin, 'scroll-behavior': 'smooth'}">
		<!-- Header -->
		
		<div :style="{visibility: isPanelVisible ? 'visible' : 'hidden'}" id='header' ref='header'>
		</div>
		

		<!-- Content -->
		<span class='panel-content' v-show='isPanelVisible' ref='panelContent'>
			<slot />
		</span>

		<!-- Hide Panel Button -->
		<div class='collapse-btn' 	
				ref='collapseBtn'
				v-on:click='isPanelVisible = !isPanelVisible'
				:style="{'margin-top':isPanelVisible ? '0px' : '-40px', 'margin-left':isPanelVisible ? '10px' : '-5px'}"
			>
				<inline-svg
					:style="{'transform': expandDirection}"
					class='collapse-icon'
					:src="require('@/assets/icons/chevron_left.svg')"
				/>
			</div>

	</div>
</template>

<!-- JavaScript -->
<script>
import InlineSvg from 'vue-inline-svg'
import Search from '@/components/Search'
import StreetView from '@/components/StreetView.vue'
import Button from '@/components/Button'
import BugReport from '@/components/BugReport'
import Footer from '@/components/sidepanel/Footer'
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { event } from 'vue-gtag'

export default {
	name: 'SidePanel',
	components: {
		'inline-svg': InlineSvg,
		'search': Search,
		'street-view': StreetView,
		'btn': Button,	
		'bug-report': BugReport,
		'sidepanel-footer': Footer,
	},
	data() {
		return{
			contentStyle: undefined,
			headerAtTop: false,
			originalHeaderTop: 410,
			residentialIsTrue: true,
			notResidentialIsTrue: false,
			residentialIsTrueInsurance: false,
			residentialIsTrueElevate: false,
			currentView: 'home',
			headerHeight: undefined,
			mapTop: 0,
			mapHeight: undefined,
			isPanelVisible: this.isSidePanelVisible,
			topMargin: '0px'
		}
	},
	mounted() {

		if (this.mapConfig.govSubRoutes.includes(this.$route.name)) {
			this.topMargin = '-10px'
		}
		this.calcContentStyle()
		if (this.$route.params.id != 'Location') {
			this.isPanelVisible = true
		}

		//console.log('panel-content: ', this.$refs.panelContent.children[0])

		//this.addComponentRef('search', this.$refs.search)

		this.addComponentRef('side-panel', this.$refs.panel)

		// set sidepanel height, regardless of header
		if (!this.isMobile) {
			this.$refs.panel.style.height = `calc(100vh - ${this.mapTop}px)`
		}
		this.addComponentRef('header', this.$refs.header) 
		this.addComponentRef('panel-content', this.$refs.panelContent.children[0])

	},
	created () {
		if (this.isMobile) {
			window.addEventListener('scroll', this.handleScroll);
		}
	},
	unmounted () {
		if (this.isMobile) {
			window.removeEventListener('scroll', this.handleScroll);
		}
	},
	computed: {
		icon() {
			if (this.$route.name == 'arkly') {
				return 'arkly-beta'
			} else {
				return 'blue-logo-with-pelican'
			}
		},
		BFE() {
			var currBFE = this.attributes.staticBaseFloodElevation
			if (currBFE == -9999){
				return ""
			} else {
				return "Base Flood Elevation: " + currBFE + " ft"
			}
		},
		floodRisk() {
			if (this.AAL) {
				if (this.AAL['NOAA']) {
					var currentFloodRisk = this.abbreviateNumbers(this.AAL['NOAA'][this.controls.year]['mean'], 2)
					return '$' + this.abbreviateNumbers(this.AAL['NOAA'][this.controls.year]['mean'], 2)
				} else {
					var currentFloodRisk = this.AAL['NOAA2022'][this.controls.year]['mean']
					if (currentFloodRisk<1000) {
						return "Less than $1K "
					} else{
						return '$' + this.abbreviateNumbers(this.AAL['NOAA2022'][this.controls.year]['mean'], 2)
					}
				}
				
			} else {
				return ''
			}
		},
		loanCost() {
			if (this.houseLiftingLoan) {
				return 	this.abbreviateNumbers(this.houseLiftingLoan.yearlyCost, 2)
			} else {
				return
			}
		},
		floodZoneDescription() {
			if (this.floodZone.startsWith('A') || this.floodZone.startsWith('V')){
				return this.floodZone.split('|')[0] + ' (High-Risk Flood Zone)'
			} else {
				return this.floodZone.split('|')[0] + ' (Non-High-Risk Flood Zone)'
			}
		},
		borderRadius() {
			if (this.isMitigationVisible) {
				return '0'
			} else {
				return '0.5rem'
			}
		},
		expandDirection() {
			if (this.isPanelVisible) {
				event('panel_visible', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('panel_visibility','location ID: ' + window.location.href.split('/').slice(-1) + ', expand sidepanel')
				return null 
			} else {
				event('panel_closed', {'event_category': 'sidepanel', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('panel_visibility','location ID: ' + window.location.href.split('/').slice(-1) + ', hide sidepanel')
				return 'rotate(180deg)'
			}
		},
		...mapState(useConfigStore, ['isRouteLocation','componentRefs','isSidePanelVisible', 'controls', 'isMobile', 'sidePanel', 'windowHeight', 'mapConfig']),
		...mapState(useSurfStore, ['coordinates', 'location', 'fullAddress', 'attributes', 'FFE', 'meanNFIP', 'meanNFIPstr', 'houseLiftingLoan', 'AAL', 'abbreviateNumbers', 'floodZone', 'propertyNotFound']),
	}, 
	watch: {
		'$refs': {
			handler: function(result) {
				//console.log(this.$refs.search.getBoundingClientRect())
				//console.log(this.$refs)
			},
			deep: true,
		},
		'$route': {
			handler: function(result) {
				//console.log(result)
				//console.log(this.isPanelVisible)

			},
			deep: true,
		},
		fullAddress: function(newAddress) {
			//console.log(newAddress)
		},
		isPanelVisible: function(newVisibility){
			//console.log(newVisibility)
			this.setSidePanelVisibility(newVisibility)
		},
		isSidePanelVisible: function(newVisibility) {
			//console.log(newVisibility)
			this.isPanelVisible = newVisibility
		},
		isMobile(){
			setTimeout(()=> {
				this.addComponentRef('header', this.$refs.header) 
				this.addComponentRef('panel-content', this.$refs.panelContent.children[0])
			},500)
		},
		windowHeight: function (newHeight) {
			//console.log(newHeight)
			this.calcContentStyle()
		},
		propertyNotFound: {
			handler: function(newState) {
				//console.log('isNotFound: ', newState)
				this.setSidePanelVisibility(newState)
				this.setRouteIsLocation(true)
			},
			deep: true,
		},
	},
	methods: {
		calcContentStyle() {
			if ('map' in this.componentRefs) {
				const mapDimensions = (this.componentRefs.map.getBoundingClientRect())
				this.mapTop = mapDimensions.top
				this.mapHeight = mapDimensions.height
				try {
					this.headerHeight = (this.$refs.header.getBoundingClientRect()).height
				} catch {
					this.headerHeight = 0
				}

				this.contentStyle = `calc(${this.mapHeight}px - ${this.headerHeight}px)`
			}
		},
		...mapActions(useConfigStore, ['setRouteIsLocation','goHome','setSidePanelVisibility', 'setSidePanelView', 'setTutorialVisibility', 'checkout', 'addComponentRef', 'goToRocketFlood', 'scheduleExpertMeeting']),	
	},
}
</script>

<!-- CSS -->
<style lang="scss">
@import "../../style/arkly.scss";

$blue: #7084EC;
$red: #D34D45;
$light-red: #E9ABA9;
$yellow: #D2B360;

hr {
	width: 100%;
}

h1 {
	//font-family: $header-family';
	font-size: 30px;
}

h2 {
	//font-family: $header-family';
	margin: 0;
	text-shadow: none;
}

h3 {
	//font-family: $header-family';
	font-size: 16px;
	margin: 0px;
}

p {
	margin: 0;
}

strong {
	font-weight: bold;
}

.info-bubble {
	fill: white;
	height: 1.2rem;
	float: inline-start;
	margin-top: 1px !important;
	padding-right: 1px !important;
}
////////////////////////////////////////////////////
// Collapse Button
///////////////////////////////////////////////////
.collapse-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	min-width: 24px;
	height: 120px;
	border-radius: $border-radius;
	background-color: white;
	//filter: brightness(100%);
	grid-area: collapse;
	z-index: 0;
	margin: 10px;
	grid-area: collapse;
}

.collapse-btn:hover {
	cursor: pointer;
	filter: brightness(80%);
}	

.collapse-icon {		
	fill: $grey;
	width: 14px;
	height: auto;
}

.collapse-icon svg {
	fill: white;
}

///////////////////////////////////////////////////
// Header
//////////////////////////////////////////////////

#search {
	border-radius: $border-radius;
	height: auto;
	width: 100%;
	grid-area: search;
}

#header > .company-logo {
	height: 80px;
	width: auto;
	grid-area: logo;
}


.close-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	max-width: 100%;
	z-index: 999;
	margin: 10px;
	grid-area: close;
}

#header {
	position: relative;
	top: 0;
	width: 100%;
	height: 0px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto auto;
	grid-template-areas: 
		" close  logo   .      "
		" search search search ";
	//padding: 10px 20px;
	background-color: $sidepanel-color;
	z-index: 99999;
	height: auto;
	grid-area: header;
}

///////////////////////////////////////////////////
// Panel Content
///////////////////////////////////////////////////

.panel-content {
	/*
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: $padding;
	background-color: transparent; // $sidepanel-color;
	//border-top-right-radius: $border-radius;
	border-bottom-right-radius: 0;
	max-width: $sidepanel-width;
	height: 100%;
	width: 400px;
	//padding: 0 20px;
	scroll-behaviour: smooth;
	grid-area: controls;
	z-index: 9;
	*/
	max-width: 100%;
	overflow-y: scroll;
	overflow-x: clip;
	overscroll-behavior-y: none;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}


.panel-content .btn-container {
	width: $default-btn-width;
}

.panel-content .btn-container btn {
	width: 100% !important;
}

.sidepanel-container .section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: $padding;
}

.sidepanel-container {
	position: fixed;
	//top: 10px;
	top: 10px;
	left: 10px;
	display: grid;
	height: calc(100vh - 10px);
	//height: 100%;
	//min-height: calc(100vh - 10px);
	min-width: $sidepanel-width;
	width: $sidepanel-width;
	grid-template-columns: 1fr 24px;
	grid-template-rows: auto 1fr;
	grid-template-areas: 
		" header   collapse "
		" controls collapse ";
	background-color: transparent;	
	padding: 0;
	height: calc(100vh - 10px);
	//max-height: 1000px;
	z-index: 99;
	//margin-left: 10px;
	scroll-behavior: smooth;
}

.sidepanel-container.hidden {
	position: fixed;
	//top: 40px;
	min-width: 24px;
	width: 24px;
	padding-top: 40px;
	grid-template-columns: 0 24px;
	grid-template-rows: 1fr auto 1fr;
	grid-template-areas: 
		" . collapse "
		" . .  "
		" . . ";
}

.top-half.hidden {
	display: none;
}

.bottom-half.hidden {
	display: none;
}



@media only screen and (max-width: $mobile-breakpoint) {

	#header {
		position: fixed;
		top: 0;
		height: 0px;
	}	

	.collapse-btn {
		display: none;
	}

	.panel-content {
		width: 100vw;
		max-width: 100vw;
		margin: 0;
		overflow-y: visible;
	}

	#street-view {
		max-height: 200px;
	}

	.panel-content {
		overflow-y: visible;
	}

	.sidepanel-container {
		position: relative;
		height: auto;
		min-width: 0;
		width: 100vw;
		left: 0;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		grid-template-areas: 
			" search   ";
		z-index: 99;
		margin: 0;
		padding: 0;
		overflow-x: visible;
	}

	.sidepanel-container > * {
		min-width: 0 !important;
	}

	.sidepanel-container .section {
		align-items: center;
	}

	.panel-content .btn-container {
		width: calc(100vw - 60px);
	}

}


</style>
