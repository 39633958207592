<template>
<div 
	ref='legend' 
	class='arkly-legend-container' 
	:style="{width: isMobile ? 'fit-content' : 'auto', right: isMobile ? '10px' : '10px', bottom: isMobile ? '40px' : '30px'}" 
	:class="{'container-vertical': direction == 'vertical' }"
	v-if="$route.meta.platform == 'arkly'"
>
	<div class='feature'>
		<o-checkbox id="floodZoneCheckBox" v-model="isFloodZoneVisible" />
		<div class='fema' v-if='!isMobile' ref='fema'/>
		<h2> {{ textContent['property_view']['FEMA-flood-zone']['label'] }} </h2>
	</div>
	<div class='feature'>
		<o-checkbox id="AALCheckBox" v-model="isColorScaleVisible" />
		<div class='scale' v-if='!isMobile' ref='scale'>	
			<span class='color' v-for='color in colorScale.colors.slice()' >
				<div class='color' :style="{'background-color': color}"/>
			</span>
		</div>
		<h2> {{ textContent['property_view']['annual-flood-risk-legend']['label'] }} ($) </h2>
	</div>
</div>
<div 
	class='govsub-legend-container'
	:style="{'margin-bottom': isMobile ? '-15px' : '5px'}" 
	v-else
>
	<div class='legend-title'>
		<h4 v-if="!mapConfig.isEquityVisible"> 
			Damage From {{ controls.storm }} Flood in {{ controls.year }} ({{ controls.units }}) 
		</h4>
		<h4 v-else> 
			Households at Risk During <br>
			a {{ controls.storm }} Flood in {{ controls.year }}  
		</h4>
	</div>
	<div class='scale' ref='scale'>
		<div class='blank'/>
		<span class='label' v-for='label in colorScale.labels'>
			<div class='label'> {{label}} </div>
		</span>

		<span class='color' v-for='color in colorScale.colors'>
			<div class='color' :style="{'background-color': color}"/>
		</span>
	</div>
</div>
</template>

<script>
import chroma from 'chroma-js'
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { event } from 'vue-gtag'

export default {
	components: {

	},
	props: [
		'map', 
		'layerLabel', 
		'units',
		'stormCategory',
		'year',
		'direction'
	],
	data() {
		return {
			zoom: 0,
			legendPurpose: 'Property',
			scaleFactor: {
				'property': 1,
				'blockgroup': 10,
				'county': 10,
			},
			isColorScaleVisible: true,
			layerTypes: undefined,
		}
	},
	mounted() {
		this.zoom = this.map.getZoom()
		this.map.on('zoom', () => {
			this.zoom = this.map.getZoom()
		})
		this.layerTypes = Object.keys(this.mapConfig.layers);
		//this.setFill('mounted')
		this.layerTypes.forEach(key => {
			if (key != 'floodZone'){
				this.mapConfig.layers[key].forEach(layer => {
					var pr_layer_not_in_pr = (!this.$route.name == 'puertoRico' && layer.includes('puertorico'))
					if (!pr_layer_not_in_pr) {
						this.map.setLayerZoomRange(layer, this.mapConfig.layerSettings[key].zoomRange[0], this.mapConfig.layerSettings[key].zoomRange[1])
					}
				})
			}
		})
		
		if (this.user && !this.mapConfig.arklyRoutes.includes(this.$route.name)) {
			const blockgroupAccess = this.user.access.blockgroups
			if (blockgroupAccess.length > 0){
				this.mapConfig.layers.blockgroup.forEach(layer => {
					this.map.setFilter(layer, ['in', this.mapConfig.blockgroupGovSubIdField].concat(blockgroupAccess))
				})
				this.mapConfig.layers.equity.forEach(layer => {
					this.map.setFilter(layer, ['in', this.mapConfig.equityGovSubIdField].concat(blockgroupAccess))
				})
				// Filter properties if they are in the matching blockgroups
				this.mapConfig.layers.property.forEach(layer => {
					this.map.setFilter(layer, ['in', 'blockgroup'].concat(blockgroupAccess))
				})
				
			}
			const countyAccess = this.user.access.counties 
			if (countyAccess.length > 0){
				// Filter any county that isn't in countyAccess
				this.mapConfig.layers.county.forEach(layer => {
					this.map.setFilter(layer, ['in', this.mapConfig.countyGovSubIdField].concat(countyAccess))
				})
				this.mapConfig.layers.blockgroup.forEach(layer => {
					this.map.setFilter(layer, ['in', ['slice', ['get', this.mapConfig.blockgroupGovSubIdField], 0, 6]].concat(countyAccess))
				})
				
				// Filter properties if they are in counties matching countyAccess
				var filterList = ["any"]
				countyAccess.forEach(currCounty => {
					filterList = filterList.concat([['in', ['slice', ['get', 'blockgroup'], 0, 6]].concat(currCounty)])
				})
				this.mapConfig.layers.property.forEach(layer => {
					this.map.setFilter(layer, filterList)
				})
				
			}
			
			const cityAccess = this.user.access.cities
			if (cityAccess.length > 0){
				if (blockgroupAccess.length > 0) {
					this.mapConfig.layers.property.forEach(layer => {
						this.map.setFilter(layer, ['all',['in', 'blockgroup'].concat(blockgroupAccess),['in', 'city'].concat(cityAccess)])
					})
				} else {
					this.mapConfig.layers.property.forEach(layer => {
						this.map.setFilter(layer, ['in', 'city'].concat(cityAccess))
					})
				}
				
			}
			
		}
		this.$emit('units', this.units)
		this.addComponentRef('legend', this.$refs.legend)
		this.setFillControls()
		//console.log('Done mounting legend component')

	},
	computed: {
		isFloodZoneVisible: {
			get() {
				return this.controls.isFloodZoneVisible
			},
			set(value) {
				this.setFloodZoneVisibility(value)
			}
		},
		division: function(){
			if (this.zoom <= this.mapConfig.layerSettings.blockgroup.zoomRange[0]) {
				this.legendPurpose = 'County Damage'
				this.currentUnits = '$'
				return 'county'
			} else if (this.zoom <= this.mapConfig.layerSettings.property.zoomRange[0]) {
				this.legendPurpose = 'Blockgroup Damage'
				return 'blockgroup'
			} else {
				this.legendPurpose = 'Property Damage'
				return 'property'
			}
		},
		equityColorScale: function() {
			// set steps for color scale
			const steps = this.fill.equity.steps	
		
			let labels = steps.map(label => {
				return this.abbreviateNumbers(label, 3)	
			})
			labels.unshift('0')
			
			let colors = chroma.scale('Reds').colors(steps.length)	
			
			return {'steps': steps, 'labels': labels, 'colors': colors, 'areaLabel': 'area'}
		},
		colorScale: function() {
			if (this.mapConfig.isEquityVisible) {
				return this.equityColorScale 
			} else {
				let currentColorScale = this.economicColorScale(this.mapConfig.layerSettings[this.division].colorScaleFactor)
				return currentColorScale
			}
		},
		fill() {
			return this.mapConfig.fill
		},
		...mapState(useConfigStore, ['isRouteLocation','mapboxLookupDict','textContent', 'mapConfig', 'controls', 'user', 'isMobile', 'mapSearchOptions']),
		...mapState(useSurfStore, ['equity']),
	},
	methods:  {
		...mapActions(useConfigStore, ['toggleEquityVisibility','addComponentRef', 'setMapSearchOptions', 'setFloodZoneVisibility', 'abbreviateNumbers']),
		economicColorScale: function(xFactor, layerName = 'florida') {
			// set steps for color scale
			let steps
			if (this.controls.units != "$"){
				steps = [0, 5, 10, 50, 100, 500]
				xFactor = 1
			} else {
				steps = [0, 10000, 100000, 500000, 1000000, 10000000]
			}
			
			if (!xFactor) {
				xFactor = 1
			}
			if (xFactor != 1){
				steps = steps.map(step => step*xFactor)
			}

			let labels = steps.map(label => {
				return this.abbreviateNumbers(label, 3)	
			})
			
			let colors = chroma.scale('Reds').colors(steps.length-1)
			
			return {'steps': steps, 'labels': labels, 'colors': colors, 'areaLabel': 'area'}
		},
		fillColors: function(colorScale, layerType, layerName) {
			
			let equation, layerLabel, equationEL
			if (this.$route.name == 'puertoRico' && !layerName.includes('florida')) {
				layerLabel = this.mapboxLookupDict[this.layerLabel]
			} else if (this.$route.name == 'puertoRico' && layerName.includes('florida')) {
				layerLabel = this.mapboxLookupDict[this.layerLabel.replace('NOAA2022Meter2High', 'NOAA2022')]
			} else if (layerName.includes('florida')) {
				layerLabel = this.mapboxLookupDict[this.layerLabel.replace('NOAA2022Meter2High', 'NOAA2022')]
			} else if (this.$route.meta.region == 'FL' && layerName.includes('puertorico')) {
				layerLabel = this.mapboxLookupDict[this.layerLabel.replace('NOAA2022', 'NOAA2022Meter2High')]
			} else if (layerType != 'equity') {
				layerLabel = this.mapboxLookupDict[this.layerLabel]
			} else {
				layerLabel = this.layerLabel
			}
			
			if (this.controls.units != '$') {
				equation = ["/", ['get', layerLabel], ['get', colorScale.areaLabel]]
				equationEL = ["/", ['get', 'EL'], ['get', colorScale.areaLabel]]
			} else {
				equation = ['get', layerLabel]
				equationEL = ['get', 'EL']
			}
			let varNameForLastYearDamage = this.mapboxLookupDict[this.mapConfig.lastYearVariableName[this.$route.meta.region]]
			//console.log(varNameForLastYearDamage)
			var fill =  [
							'case',
							['==', ['get', varNameForLastYearDamage], 0],
							'white',
							["has", layerLabel],
							[
							'step',
							equation,
							'rgba(255,255,255, 1)',
							colorScale.steps[0],
							colorScale.colors[0],
							colorScale.steps[1], 
							colorScale.colors[1],
							
							colorScale.steps[2], 
							colorScale.colors[2],
							
							colorScale.steps[3],
							colorScale.colors[3], 
							colorScale.steps[4],
							colorScale.colors[4],
							/*
							0,
							colorScale.colors[0],
							2000,
							colorScale.colors[4],
							*/
							],
							[
							'step',
							equationEL,
							'rgba(255,255,255, 1)',
							colorScale.steps[0],
							colorScale.colors[0],
							colorScale.steps[1], 
							colorScale.colors[1],
							
							colorScale.steps[2], 
							colorScale.colors[2],
							
							colorScale.steps[3],
							colorScale.colors[3], 
							colorScale.steps[4],
							colorScale.colors[4],
							/*
							0,
							colorScale.colors[0],
							2000,
							colorScale.colors[4],
							*/
							]
						]
			
			//if (layerName.includes('FZs') && layerName.includes('properties') && (!this.layerLabel.includes('100year') || !this.layerLabel.includes('2020'))){
			//	fill = 'yellow'
			//	fill = 'black'
			//	fill = 'white'
				//fill = 'grey'
			//}

			//console.log(this.$route,'Fill colors called', layerName, this.layerLabel, layerLabel, fill)
			return fill
		},
		searchOptionFillColors: function(options) {

			if (options) {
				const fill = [
					'case',
					[
						'in', 
						['get', 'structureId'], 
						['literal', this.mapSearchOptions]
					]		
				].concat(options)
			
				return fill
			} else {
				if (option == 'fill') {
					return this.fillColors(this.economicColorScale(1))
				} else {
					return ['match', ['get', 'damageInLastYear'], 0, 0.3, 1]
				}
			}

		},
		equityColors: function(colorScale) {
			const equation = ['get', this.layerLabel.replace('expectedLoss_','').replace('mean', 'atRisk')]	
			const fill =  [
						'interpolate',
						['linear'],
						equation,
						colorScale.steps[0],
						colorScale.colors[0],
						colorScale.steps[1], 
						colorScale.colors[1],
						colorScale.steps[2], 
						colorScale.colors[2],
						colorScale.steps[3],
						colorScale.colors[3], 
						colorScale.steps[4],
						colorScale.colors[4],
					]

			//console.log(fill)
			return fill
		},
		setFillControls(){
			if ( 'equity' in this.mapConfig.layers) {
				this.map.setPaintProperty(this.mapConfig.layers.equity, 'fill-opacity', this.mapConfig.layerSettings.equity.opacity)
				this.map.setPaintProperty(this.mapConfig.layers.equity, 'fill-color', this.equityColors(this.equityColorScale))
			}
			var layersToRun = ['property','blockgroup','county']
			layersToRun.forEach(key =>{
				this.mapConfig.layers[key].forEach(layer => {
					this.map.setPaintProperty(layer, 'fill-color', this.fillColors(this.economicColorScale(this.mapConfig.layerSettings[key].colorScaleFactor,layer), key, layer))
				})
			})
		},
		setFillDivision(){
			//console.log('division set fill called')
		},
		setFillRoute(){
			//console.log('route set fill called')
		},
		setFillLayerLabel(){
			//console.log('layer label set fill called')
		},
		setFill(callLocation) {	
			//console.log('set fill called from: ', callLocation)
			if ( 'equity' in this.mapConfig.layers) {
				this.map.setPaintProperty(this.mapConfig.layers.equity, 'fill-opacity', this.mapConfig.layerSettings.equity.opacity)
				this.map.setPaintProperty(this.mapConfig.layers.equity, 'fill-color', this.equityColors(this.equityColorScale))
				if (!this.isRouteLocation) {
					if (this.mapConfig.isEquityVisible) {
						this.toggleEquityVisibility(true)
					} else {
						this.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'none')
					}
				} else {
					if (!this.mapConfig.isEquityVisible) {
							this.map.setLayoutProperty(this.mapConfig.layers.equity, 'visibility', 'none')
					}
				}
			}
			
				
			if (this.isColorScaleVisible) {
				var colorScaleVisible = 'visible'
			} else {
				var colorScaleVisible = 'none'
			}
			if (this.isFloodZoneVisible) {
				var floodZoneVisToUse = 'visible'
			} else {
				var floodZoneVisToUse = 'none'
			}
			this.layerTypes.forEach(key => {
				this.mapConfig.layers[key].forEach(layer => {
					if (layer.includes(this.$route.meta.regionFull)){
						if (key != 'equity' && key != 'criticalAssets'){
							if (this.mapConfig.isEquityVisible && key != 'floodZone') {
								this.map.setLayoutProperty(layer, 'visibility', 'none')
							} else {
								this.map.setLayoutProperty(layer, 'visibility', colorScaleVisible)
							}
							if (key == 'property') {
								if (this.mapSearchOptions && Array.isArray(this.mapSearchOptions) && this.mapSearchOptions.length == 0) {
									if (layer.includes('FZs')) {
										this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacityFZs)
										//if (this.layerLabel.includes('100year') && this.layerLabel.includes('2020')){
										//	this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacityFZs)
										//} else {
										//	this.map.setPaintProperty(layer, 'fill-opacity', 0.3)
										//}
									} else {
										if (this.$route.name == 'puertoRico') {
											this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity.PR)
										} else {
											this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity.FL)
										}
									}
									this.map.setPaintProperty(layer, 'fill-color', this.fillColors(this.economicColorScale(1,layer),'property',layer))
								} else {
									this.map.setPaintProperty(layer, 'fill-color', this.searchOptionFillColors(['white', 'white']))
									this.map.setPaintProperty(layer, 'fill-opacity', this.searchOptionFillColors([1,0.3]))

								}
							} else if (key != 'floodZone') {
								if (layer.includes('FZs')){
									if (!this.layerLabel.includes('100year') || !this.layerLabel.includes('2020')) {
										this.map.setPaintProperty(layer, 'fill-opacity', 0)
										this.map.setPaintProperty(layer + '_outline', 'line-opacity', 0)
									} else {
										this.map.setPaintProperty(layer, 'fill-opacity', this.mapConfig.layerSettings[key].opacity)
										this.map.setPaintProperty(layer + '_outline', 'line-opacity', this.mapConfig.layerSettings[key].opacity)
									} 
								}
								this.map.setPaintProperty(layer, 'fill-color', this.fillColors(this.economicColorScale(this.mapConfig.layerSettings[key].colorScaleFactor,layer), key, layer))
							} else if (key == 'floodZone') {
								this.map.setLayoutProperty(layer, 'visibility', floodZoneVisToUse)
							}
						}
					}
				})
			})
		},	
	},
	watch: {
		mapSearchOptions(newOptions) {
			//console.log('search options: ', newOptions)
		},
		isFloodZoneVisible(newValue) {
			event('floodzone_toggle', {'event_category': 'map', 'floodzoneToggle' : newValue, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('floodZone_toggle','location ID: ' + window.location.href.split('/').slice(-1) + ', flood zone toggle: ' + newValue)
			this.setFill('isFloodZoneVisible')
		},
		isColorScaleVisible(newVisible) {
			event('aal_toggle', {'event_category': 'map', 'aalToggle' : newVisible, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('AAL_toggle','location ID: ' + window.location.href.split('/').slice(-1) + ', AAL toggle: ' + newVisible)
			this.setFill('isColorScaleVisible')
		},
		division(newValue){
			if (!this.mapConfig.isEquityVisible) {
				event('map_division_' + newValue, {'event_category': 'map', 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('map_division_' + newValue,'location ID: ' + window.location.href.split('/').slice(-1))
				this.setFillDivision()
				this.$emit('mapDivision', newValue)
			}
		},
		layerLabel: function(newValue) {
			this.setFillLayerLabel()
		},
		units: {
			handler: function(newValue) {	
				this.setFill('units')
			},
			deep: true
		},
		controls: {
			handler: function(currentControls){
				this.setFillControls()	
			},
			deep: true
		},
		$route: {
			handler: function(newRoute){
				this.setFillRoute()	
			},
			deep: true
		},
		isBlockgroup: function(newValue){
			
		},
	},
}
	

</script>

<style lang="scss" scoped> 
@import "./../../style/style.scss";
@import './../../../node_modules/mapbox-gl/dist/mapbox-gl.css';

.arkly-legend-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	height: auto;
	width: auto;
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 4px;
	padding: 6px;
	gap: 6px;
	z-index: 1;
}

.arkly-legend-container h2 {
	font-size: 15px;
	font-family: $header-family !important;
	font-weight: bold !important;
	white-space: nowrap !important;
	color: rgb(44, 62, 80);
	margin: 0;
	margin-right: 6px;
}

.arkly-legend-container > .feature {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: center;
	width: 100%;	
}

.arkly-legend-container > .feature > .fema {
	display: block;
	background-color: $primary;
	width: 50px !important;
	height: 20px;
	width: 100%;
	border: none;
	margin-right: 6px;
	padding: 0;
}

.arkly-legend-container .scale {
	display: grid;
	grid-template-columns: repeat(10, 5px);
	grid-template-rows: auto 20px;
	grid-auto-flow: column !important;
	gap: 0 0;
	border: none;
	padding: 0;
	margin: 0;
	margin-right: 6px;
}

.arkly-legend-container .blank {
	display: block;
	width:100%;
	grid-column: 1/2;
	grid-row: 3/4;
}

.govsub-legend-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: auto;
	height: auto;
	background-color: white;
	border-radius: 4px;
	padding: 3px;
	margin-right: 5px;
}

.govsub-legend-container > .govsub-legend-title >  h4 {
	display: flex;
	font-size: 15px;
	flex-align: column;
	align-items: center;
	justify-content: flex-start;
	grid-area: 1/2/1/-1;
	height: auto;
	width: auto;
	margin: 0;
	white-space: nowrap;
}

.govsub-legend-container .scale {
	display: grid;
	grid-template-columns: repeat(12, 20px);
	grid-template-rows: 20px 20px;
	gap: 0 0;
	border: none;
	padding: 5px;
	margin: 0;
}

.govsub-legend-container .blank {
	display: block;
	width:100%;
	grid-column: 1/2;
	grid-row: 2/3;
}

.govsub-legend-container .scale .label {
	justify-content: center;
}

.container-vertical {
	flex-direction: column !important;	
}

.container-vertical .legend-title {
	padding-top: 0;
}

.container-vertical .legend-title > h4 {
	font-weight: normal;
	font-size: 0.8rem;
}

.label .label {
	font-weight: normal;
	font-size: 0.8rem;
	margin-top: 4px;
}

.scale .label {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 3px;
	grid-column-end: span 2;
	grid-row-start: 1;
	grid-row-end: 1;
}

.color {
	display: block; 
	width: 100%;
	height: 100%;
	grid-column-end: span 2 !important;
	grid-row-start: 2 !important;
	grid-row-end: 3 !important;
}
</style>
