<template>
  <Transition name="slide-fade">
    <div id="loadingScreen" v-if="isLoading"> <!---->
      <inline-svg
          v-if="variant == 'arkly'"
          title='Arkly Logo'
          :src="require('@/assets/icons/arkly-logo-watermark-trademark.svg')"
          style="width:200px;height:200px;margin-top:30vh"
      /> 
      <inline-svg
          v-if="variant == 'hightide'"
          title='HighTide Logo'
          :src="require('@/assets/icons/ArklyProLogo.svg')"
          style="width:200px;height:250px;margin-top:24vh"
      /> 
      <h5 class="loading" :style="loadingStyle">{{ textContent['property_view']['loading-screen']['label'] }}</h5>
      <h5 class="slowInternet" v-if="slowInternet" v-html="textContent['property_view']['loading-screen-slow']['label']"></h5>
    </div>
  </Transition>
</template>
  
<script>
import { useConfigStore } from '@/store/config'
import { mapState } from 'pinia'
export default {
  name: "LoadingScreen",
  props: ["isLoading","variant","slowInternet"],
  computed: {
    loadingStyle(){
      if (this.variant == 'hightide') {
        return {'position': 'absolute', 'left': 'calc(50vw - 54px)','top': 'calc(24vh + 230px)'}
      } else {
        return {'position': 'absolute', 'left': 'calc(50vw - 54px)','top': 'calc(30vh + 210px)'}
      }
    },
  ...mapState(useConfigStore, ['textContent']),
}, 
};

</script>
  
<style>
  #loadingScreen {
      top: 0px;
      position: fixed !important;
      z-index: 999990;
      background-color: white;
      height: 100vh;
      width: 100vw;
  }

  .slowInternet{
    margin-top: 60px;
    font-size: 20px;
  }

  .loader {
    background-color: #63ab97;
    bottom: 0;
    color: white;
    display: block;
    font-size: 32px;
    left: 0;
    overflow: hidden;
    padding-top: 10vh;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
  }
  
  .fadeout {
    animation: fadeout 2s forwards;
  }
  
  @keyframes fadeout {
    to {
      opacity: 0;
      visibility: hidden;
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    /*transform: translatey(-20px);*/
    opacity: 0;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 20px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 20px;
    }
  }
  </style>