<!-- HTML: This is Where You Put the HTML Code -->
<template>
	<div class="flood-table" ref="panelContent">
		
		<search
			v-if="!isMobile"
			color='black'
			size='medium'
			logo='hightide'
			class='search'
			placeholder='Search for another address... '
		/>
		<div class='section location' v-if='equity && input'>
			<div class='address'>
				<span v-for="info in title">
					<h1> {{info}} </h1>
				</span>
				<h2> Number of Households: {{ equity.attributes.totalHouseholds }}  </h2>	
			</div>
		</div>

		<div class='section chart-container histogram'>
			<histogram
				v-if='equity != undefined'
				class='chart safariBullshitWrapper'
				:buckets='histogramData.buckets'
				:data='histogramData.data'
				:stdevs='histogramData.stdevs'
				:percentile="percentile"	
				:town='input.location.blockgroup'
				title='Mean Flood Risk Adjusted Discretionary Income by Income Bracket'
				yAxisLabel='Discretionary Income'
				xAxisLabel='Income Bracket'
				width='97%'
				height='100%'
			/>
		</div>

		<div class='section chart-container lossOverTime'>
			<properties-at-risk
				v-if='equity != undefined'
				class='chart safariBullshitWrapper'
				:propertiesAtRisk='propertiesAtRisk'
				:projection="dataSource.replace('2022', '')"
				:max='maxPropertiesAtRisk'
				buildingCategory='total'
				:title='`Households at Risk of Displacement from a ${controls.storm} Storm`'
				yAxisLabel='Households at Risk'
				xAxisLabel='Decade'
				:year='year'
				:storm="controls['storm']"
				width='95%'
				height='100%'
			/>
		</div>
		<sidepanel-footer v-if="textContent['brand'] && textContent['footer']" variant="hightide"/>

	</div>
</template>

<script>
import Search from '@/components/Search'
import LossOverTime from '@/components/govSub/LossOverTime'
import NumberOfPropertiesAtRisk from '@/components/govSub/NumberOfPropertiesAtRisk'
import Histogram from '@/components/govSub/Histogram'
import StreetView from '@/components/StreetView'
import BugReport from '@/components/BugReport'
import axios from 'axios'
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import Footer from '@/components/sidepanel/Footer'

export default {
	name: 'InfoTable',
	props: [
		'input',
		'year',
		'stormCategory',
		'dataSource',
		'useType',
		'firstFloorElevation',
	],
	components: {
		Search,
		LossOverTime,
		Histogram,
		StreetView,
		BugReport,
		'properties-at-risk' : NumberOfPropertiesAtRisk,
		'sidepanel-footer': Footer,
	},
	data() {
		return {
			expectedLoss: {},
			startingExpectedLoss: this.input.expectedLoss,
			startingAAL: null,
			histogramData: null,
			percentile: null,
			houseLiftingCost: null,
			averageAnnualExpectedLoss: null,
			isLoading: false,
			percentileInstance: null, 
			axiosController: null,
			axiosInstance: null,
		}
	},
	computed: {
		title() {
			if (this.$route.params.type != "county") {
				return [
					'Blockgroup: ' + (this.equity ? this.equity._id : this.$route.params.id),
					'County: '+ this.input.location.address.county 
				]
			} else {
				return [this.input.location.address.county + ' County']
			}
		},
		economicLoss(){
			if (this.$route.params.type == "city") {
				return this.input.economicLoss
			} else {
				return this.input.expectedLoss
			}
		},
		coordinates(){
			if (this.input.location != null){
				return {'lat': this.input.location.coordinates.lat, 'lng': this.input.location.coordinates.lng}
			}
		},
		histogramData() {
			
			//console.log(this.equity['income'])
			const keys = Object.keys(this.equity['income'][this.controls.dataSource][this.currentStorm][this.controls.year])
			const values = Object.values(this.equity['income'][this.controls.dataSource][this.currentStorm][this.controls.year]).map(value => value.mean ? value.mean : undefined)
			const stdevs = Object.values(this.equity['income'][this.controls.dataSource][this.currentStorm][this.controls.year]).map(value => value.stdev ? value.stdev : 0)
			const maxValue = Number(keys[keys.length -1].split('-')[1].replace('k', '000'))
			
			let buckets = keys.map(key => {return Number(key.split('-')[0].replace('k', '000'))})
			return {'buckets': buckets.concat([maxValue]), 'values': values , 'stdevs': stdevs, 'data': Object.values(this.equity['income'][this.controls.dataSource][this.currentStorm][this.controls.year])}
		},
		histogramValues(){
			return this.histogramData['total']['NOAA'][this.stormCategory][this.year]['mean'].map(x => x['count'])
			
		},
		propertiesAtRisk() {
			const keys = Object.keys(this.equity['atRisk'][this.controls.dataSource][this.currentStorm])
			const values = Object.values(this.equity['atRisk'][this.controls.dataSource][this.currentStorm])
			return {'labels': keys, 'values': values}
			
		},
		highlightedValue(){
			if (this.$route.params.type == "city") {
				return -1 
			} else {
				return this.economicLoss['total']['NOAA'][this.stormCategory][this.year]['mean']
			}
		},
		staticBaseFloodElevation() {
			const elevation = this.input.attributes.staticBaseFloorElevation
			if (elevation == '-9999') {
				return 'N/A'
			} else {
				return elevation + ' ft'
			}
		},
		...mapState(useConfigStore, ['url', 'controls', 'currentStorm', 'isMobile','textContent']),
		...mapState(useSurfStore, ['EL', 'maxEL', 'AAL', 'maxAAL', 'maxPropertiesAtRisk', 'equity']),
	},
	methods: {
		firstFloorElevationChanged(event) {
			parseFloat(event.target.value)
		},
		...mapActions(useConfigStore, ['addComponentRef']),	
		...mapActions(useSurfStore, ['updateEquity']),
	},
	mounted() {
		this.axiosInstance = axios.create()
		this.axiosController = new AbortController()
		this.updateEquity(this.$route.params.id)
		this.addComponentRef('panel-content', this.$refs.panelContent)
		//this.updateAAL(0.93, true) 
	},
	watch: {
		input:{
			handler: function(newValue){
				this.axiosController.abort()
				this.axiosController = new AbortController()
				
				//this.updateHistogram()
				//this.updatePercentileRank()
			},
		deep: true,
		},
	},
}

</script>

<!-- CSS -->
<style lang="scss" scoped>
@import "../../style/style.scss";
$offwhite: #F2F4F5;

.search {
	width: 100%;
}
.section {
	max-width: 100%;
	max-height: 100%;
	background-color: white;
	border-radius: 5px;
}

.address {
	padding: 20px;
}
h1 {
	font-weight: normal;
}
h2 {
	font-weight: normal !important;
}

.location {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto 1fr auto;
}

.location * {
	width: 100%;
}

.attributes {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	width: 100%;
	height: 100%;
	padding: 8px;
}

.attribute {
	display: inline;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 4px;
	font-family: $header-family;
	font-weight: normal; 
	font-size: 1rem;
}

.attribute * {
	display: inline;
	font-weight: normal;
}

.attributes h2, .attributes h3 {
	margin: 0px;
}

.info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.info *:nth-child(n+2) {
	margin-top: 0px;
}

.control {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: auto;
}

// nth-child(n+2) allows you to select every item that isn't 
// the first child. Unlike :not(:first-child) it is accepted in 
// IE
.control *:nth-child(n+2) {
	margin-left: 10px;
}

.control div {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: auto;
}

.control-label {
	padding-bottom: 4px;
}

.chart-container {
	display: inline-block;
	max-height: 370px;
	max-width: 100%;
	min-width: 376px;
	min-height: 370px;
	overflow: hidden;
	padding: 10px;
	box-sizing:border-box;  /** add this **/
	-moz-box-sizing:border-box; /** add this **/
	-webkit-box-sizing:border-box; /** add this **/
	-ms-box-sizing:border-box; /** add this **/
}

.chart {
	width: 100%;
	height: 100%;
}

.house-lifting-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 30px;
}

.print-report {
	padding: 50px;
	min-height: 0px !important;
}

.flood-table {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	background-color: transparent; //$sidepanel-color;
	//border-top-right-radius: $border-radius;
	border-bottom-right-radius: 0;
	//height: 100%;
	max-width: 100%;
	width: 400px;
	//padding: 0 20px;
	grid-area: controls;
	overflow-y: auto;
	overflow-x: clip;
	overscroll-behavior-y: none;
}

@media only screen and (max-width: 800px) {

	.address {
		padding: 20px;
		padding-top: 0px;
	}
	.flood-table {
		padding: 0px;
		width: 100%;
		background-color: $offwhite;
	}

	.section {
		width: 100%;
		padding: 20px;
	}
	.histogram {
		background-color: #F2F4F5;
		//color: #F2F4F5;
	}
}

</style>

