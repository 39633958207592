<!-- HTML -->
<template> 
<div :class="[isSidePanelVisible | !isRouteLocation ? 'timeline-container':'timeline-container tl-fullscreen']" style='padding-bottom:5px' ref="timelineContainer">
	<div class='title'>
		<inline-svg @mouseover="turnOnPopup" @mouseleave="turnOffPopup"
			:src="require('@/assets/icons/info.svg')" 
			class='info v-center'  
		/>
			<div @mouseover="turnOnPopup" @mouseleave="turnOffPopup" class='timeline-info-popup' :style="{visibility: infoPopupVisible ? 'visible' : 'hidden'}"> {{ textContent['property_view']['time-machine-info-box']['label'] }} <a href="https://oceanservice.noaa.gov/hazards/sealevelrise/sealevelrise-tech-report.html" target="_blank" rel="noopener noreferrer"> NOAA's 2022 sea level rise technical report.</a>
		</div>
		<h4>{{ textContent['property_view']['time-machine-label']['label'] }} </h4> 
	</div>	
	<div class='range-slider' ref='timeline'>
		<o-field class='timeline'>
			<o-slider 
				v-if='timelineWidth'
				rootClass='indicator' 	
				trackClass='year-track'
				:tooltip="false"
				variant='red'
				:min="0" 
				:max="maxSliderPosition" 
				v-model='currentSliderPosition'
				@touchend='setCurrentYear'
				@mouseup='setCurrentYear'	
			/>
		</o-field>

		<div class='ticks' :style='ticksStyle' ref='ticks'>
			<div 
				class='tick' :class="{'before-selected-year': index <= indexOfCurrentYear}" 
				v-for='(year, index) in yearLabels'
			> 
				{{ year }} 
			</div>
		</div>
	</div>	
</div>
</template>

<!-- JavaScript -->
<script>
import InlineSvg from 'vue-inline-svg'
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { event } from 'vue-gtag'
import _ from 'lodash'

export default {
	name: 'timeline',
	components: {
		InlineSvg,
	},
	props: {
		years: {
			type: Array,
		},
		height: {
			type: Number,
			default: 100,
		},
	},
	data() {
		return{
			currentSliderPosition: 1,
			maxSliderPosition: 10000,
			currentYear: this.years[this.currentIndexOfYear],
			progress: undefined,
			timelineWidth: undefined,
			timelineLeft: undefined,
			tickPositions: undefined,
			infoPopupVisible: false,
		}
	},
	computed: {	
		// yearLabels and ticksStyle must be changed in sync, so that
		// the grid is fitted to which labels being hidden or shown
		yearLabels() {
			var stateToUse = 'FL'
			if (this.$route.name == 'puertorico') {
				stateToUse = 'PR'
			}
			if (this.timelineWidth < 300) {
				return this.years.map((year, index) => {
					if (index == 0) {
						return this.textContent['property_view']['time-machine-label']['title']
					// If the index is a multiple of 2 or 3
					} else if (index == (this.years.length - 1) ) {
						if (this.controls.timeLineUnit == 'year') {
							return String(year)
						} else {
							return String(this.settings.yearFootDict[stateToUse][year]) + 'ft'
						}
					} else {
						return ''
					}
				})
			} else if (this.timelineWidth < 400) {
				return this.years.map((year, index) => {
					if (index == 0) {
						return this.textContent['property_view']['time-machine-label']['title']
					} else if (this.isOdd(index)) {
						return ''
					} else {
						if (this.controls.timeLineUnit == 'year') {
							return String(year)
						} else {
							return String(this.settings.yearFootDict[stateToUse][year]) + 'ft'
						}
					}
				})
			} else {
				return this.years.map((year, index) => {
					if (index == 0) {
						return this.textContent['property_view']['time-machine-label']['title']
					}  else {
						if (this.controls.timeLineUnit == 'year') {
							return String(year)
						} else {
							return String(this.settings.yearFootDict[stateToUse][year]) + 'ft'
						}
					}
				})
			}
		},
		ticksStyle() {
			if (this.timelineWidth < 300) {
				return { 'grid-template-columns': `1fr repeat(${this.years.length - 2}, auto) 1fr` }
			} else if (this.timelineWidth < 400) {
				const gridColumns = this.years.reduce((acc, year, index) => {
					if (this.isOdd(index)) {
						acc = acc + ' auto'
					} else {
						acc = acc + ' 1fr'
					}
					return acc
				}, '')
				return { 'grid-template-columns': gridColumns }
			} else {
				return { 'grid-template-columns': `repeat(${this.years.length}, 1fr)` }
			}
		},
		...mapState(useConfigStore, ['settings','isRouteLocation','isSidePanelVisible','url', 'controls', 'mapConfig', 'mapConfig', 'componentRefs', 'mapWidth', 'isMobile', 'indexOfCurrentYear', 'textContent']),
		...mapState(useSurfStore, []),
	},
	mounted() {

		this.addComponentRef('timeline', this.$refs.timeline)
		this.addComponentRef('timeline-container', this.$refs.timelineContainer)
		
		// set listener to recalculate timelineWidth every time
		// that the screen changes sizes
		window.addEventListener('resize', this.setTimelineWidth )	

		this.setTimelineWidth()
		this.setTimelineLeft()

		if (this.mapConfig.arklyRoutes.includes(this.$route.name)) {
			setTimeout(() => {
				this.setCurrentYear(this.controls.year)
			},100)
		}
		
		
	},
	updated() {
		this.calculateTickPositions()
		this.setProgress()
	},
	watch: {
		'$route': {
			handler: function(newRoute) {
				//console.log(this.componentRefs['timeline-container'].className,this.isSidePanelVisible)
				if (this.isRouteLocation) {
					this.componentRefs['timeline-container'].className = 'timeline-container tl-fullscreen'
				} else {
					this.componentRefs['timeline-container'].className = 'timeline-container'

				}
				setTimeout(() => {
					this.setTimelineWidth()
					this.setTimelineLeft() 
					this.setPosition()
					setTimeout(() => {
						this.setCurrentYear(this.controls.year)
					},100)
				},100)
			},
			deep: true,
		},
		isRouteLocation(newState) {
			//console.log(this.componentRefs['timeline-container'].className)
			setTimeout(() => {
				this.setTimelineWidth()
				this.setTimelineLeft() 
				this.setPosition()
				setTimeout(() => {
					this.setCurrentYear(this.controls.year)
				},100)
			},100)
			//this.setCurrentYear(this.controls.year)
		},
		// change from mobile to desktop, and vice versa,
		// will change timeline dimensions, so we need to 
		// recalculate them
		isMobile(newState) {
			setTimeout(() => {
				this.setTimelineWidth()
				this.setTimelineLeft() 
				this.setPosition()
				setTimeout(() => {
					this.setCurrentYear(this.controls.year)
				},200)
			},100)
		},
		isSidePanelVisible(newState){
			//console.log('recalculating')
			if (!newState) {
				this.componentRefs['timeline-container'].className = 'timeline-container tl-fullscreen'
			} else {
				this.componentRefs['timeline-container'].className = 'timeline-container'

			}
			setTimeout(() => {
				this.setTimelineWidth()
				this.setTimelineLeft() 
				this.setPosition()
			},10)
		},
		'ticksStyle': {
			handler: function(result) {
				this.calculateTickPositions()
			},
			deep: true,
		},
		timelineWidth(newWidth){
			// If the timeline size changes, then the tick marks
			// need to be recalculated 
			this.calculateTickPositions()
			//and the position of the thumb needs to change
			this.setPosition()
		},
		// Initial Marker position is set after tickPositions
		// is calculated
		tickPositions(newValue) {
			this.setPosition()
		},
	},
	methods: {
		turnOnPopup(){
			this.infoPopupVisible = true
		},
		turnOffPopup(){
			setTimeout(() => {this.infoPopupVisible = false}, 1500);
		},
		// How far to the left of the screen the timeline starts
		setTimelineLeft() {
			if (this.$refs.timeline) {
				//console.log('timeline left', this.timelineLeft)
				this.timelineLeft = parseInt(this.$refs.timeline.getBoundingClientRect().x)
				//console.log('timeline left', this.timelineLeft)
			}
		},
		// How wide the timeline is
		setTimelineWidth() {
			if (this.$refs.timeline) {
				//console.log('timeline width',this.timelineWidth)
				this.timelineWidth = parseInt(this.$refs.timeline.clientWidth)
				//console.log('timeline width',this.timelineWidth)
			}
		},
		setTimelineDimensions() {

		},
		setProgress() {			
			//this.setCurrentYear()
		},
		setCurrentYear: _.debounce(function(yearToSet) {
			const newXPosition = this.years.reduceRight((acc, year, index) => {
				// Have to use a ratio, because oruga timeline resets currentSliderPosition
				// to zero when the max is changed
				const tickPositionRatio = parseInt(
					(this.tickPositions[index]/this.timelineWidth)*this.maxSliderPosition
				)
				if ( this.currentSliderPosition <= tickPositionRatio) {
					acc = { 'x': tickPositionRatio, 'year': year }
				}
				return acc
			}, { 'x': this.tickPositions[-1], 'year': this.years[-1] })
			// Move the slider to the closest year
			// change 'currentYear' to newly selected year
			// if the slider was dragged off the end of the thing and returned an undefined year, fix it
			if (!newXPosition.year) {
				this.currentSliderPosition = this.maxSliderPosition
				this.currentYear = this.settings.years.slice(-1)[0]
				this.setYear(this.settings.years.slice(-1)[0])

			} else {
				this.currentSliderPosition = newXPosition.x
				this.currentYear = newXPosition.year
				this.setYear(newXPosition.year)

			}
			//console.log('new slider position: ' + newXPosition.year)
			event('timeline_click', {'event_category': 'map', 'year_clicked': this.currentYear, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submit('timeline_click','location ID: ' + window.location.href.split('/').slice(-1) + ', new timeline year: ' + this.currentYear)
			
		}, 1),
		setPosition() {
			if (this.timelineWidth > 0) {
				const oldSliderPosition = this.currentSliderPosition
				this.currentSliderPosition = parseInt(
					(this.tickPositions[this.indexOfCurrentYear]/this.timelineWidth)*this.maxSliderPosition
				)
				this.currentYear = this.years[this.indexOfCurrentYear]
				//console.log("Old slider position: " + oldSliderPosition)
				//console.log("New slider position: " + this.currentSliderPosition)
			}
		},
		// Function used to find the position of the tick marks, since they
		// are not a part of the actual range slider. Instead, the ticks 
		// and labels are overlayed on top using css grid. This allows
		// for more customization of the ticks and labels, such as hiding some
		calculateTickPositions: function() {
			// Find how far the timeline is from the left of the screen, 
			// if it hasn't been calculated already
			//if (!this.timelineLeft) {
				this.timelineLeft = this.$refs.timeline.getBoundingClientRect().x
			//}	

			// If the ticks have been rendered
			if (this.$refs.ticks.children) {
				this.tickPositions = Array.from(this.$refs.ticks.children).map((tick, index) => {
					const { x, y, width, height } = tick.getBoundingClientRect()
					if (index < this.years.length) {
						return parseInt(x + width - this.timelineLeft)
					} else {
						return parseInt(x - this.timelineLeft)
					}
				})
			}
		},
		setTimelineDimensions(){
			if (this.$refs.timeline){			
				// Calculate the 'left' position of the panel, so that it lines up
				// with the middle of the button
				const { x, y, width, height } = this.$refs.timeline.getBoundingClientRect()					
				this.timelineDimensions = { x, y, width, height }
			}			
		},
		isOdd(num) {
			return num % 2 == 1
		},
		...mapActions(useSurfStore, ['updateAAL', 'updateEL']),
		...mapActions(useConfigStore, ['setYear', 'setFloodZoneVisibility', 'getComponentRef','addComponentRef']),
	},
}
</script>

<!-- CSS -->
<style lang="scss" >
@import "../../style/arkly.scss";

$timeline-color: blue; //rgba(153, 26, 18, 0.2);
$timeline-background-color: rgba(255, 255, 255, 0.9);
.tl-fullscreen {
	width: 100vw !important;
}

.timeline-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(100vw - 390px);
	height: auto;
	background-color: transparent;
	padding: 0 10px;
	z-index: 999;
}

.timeline-container .title {
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	gap: 3px;
	height: 20px;
	color: white;
	padding: 0;
	padding-left: 5px;
}

.timeline-container .title h4 {
	height: auto;
	font-family: $header-family !important;
	white-space: nowrap;
	font-weight: 600;
	margin: 0;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

 }

 .timeline-container .title .info {
	 width: 20px;
	 margin-bottom: 5px;
}

 .timeline-container .title .info svg {
	width: 20px;
	margin-bottom: 1px;
}

.range-slider {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(9, 1fr);
	width: 100%;
	height: 100%;
}

.range-slider .ticks {
	display: grid;
	grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr auto 1fr;
	grid-template-rows: 1fr;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 2px 0;
	grid-column: 1/-1;
	grid-row: 1/2;
	pointer-events: none;
	z-index: 2;
	line-height: 1.2;
}

.range-slider .ticks .tick:last-child {
	border-right: none;
}

.range-slider .ticks .tick {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-width: 20px;
	height: 6px;
	font-weight: 700;
	border-right: 1px solid black;
	pointer-events: none;
	font-size: 12px;
	padding-left:10px;
	padding-right:10px;
}

.range-slider .ticks .before-selected-year {
	color: white;
}

$track-height: 20px;
$thumb-height: 20px;
$progress-color: red;

.timeline {
	display: grid;
	grid-column: 1/-1;
	grid-row: 1/2;
	grid-template-rows: 1fr;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	width: 100%;
	border-radius: 4px;//50px;
	margin: 0;
	margin-bottom: 0 !important;
	z-index: 1;
	opacity: 0.9 !important;
}

.indicator {
	width: 100%;
	height: 100%;
	margin: 0 !important;
}

.timeline .o-slide__thumb {
	width: 15px !important;
	height: 31px !important;
	z-index: 3;
}	

.timeline .o-slide__fill {
	border-radius: 4px 4px 4px 4px  !important;//999em 50px 50px 999em  !important;
	color: $timeline-color !important;
}

.timeline .o-slide .o-slide__thumb {
	background: white !important;
}

.timeline .o-slide__track {
	height: 30px !important;
	background: $timeline-background-color !important;
	border-radius: 4px !important;//50px !important;
}

.timeline .o-slide {
	grid-row: 1/2 !important;
}

.timeline .o-slide__tick-label {
	display: block;
	top: -8px !important;
	left: 40px !important;	
	font-size: 0.8rem !important;
	font-weight: bold !important;
	color: white;
}


.timeline .o-slide__tick--hidden {
	position: absolute;
	transform: translate(-50%, -50%);
	width: var(--oruga-slider-tick-width, 1px);
	background: var(--oruga-slider-tick-background, transparent);
	border-radius: var(--oruga-slider-tick-radius, 4px);
}

@media only screen and (max-width: $mobile-breakpoint) {

	.range-slider .ticks .tick {
		min-width: 15px;
	}

	.timeline-container .title .info {
	 margin-bottom: 0px;
}
}

@media only screen and (max-width: $mobile-breakpoint) {
	.timeline-container {
		width: 100vw;
		padding: 10px;	
	}

	.timeline .o-slide__tick-label {
		display: block;
		top: 0px !important;
		left: 0px !important;	
		font-size: 0.8rem !important;
		font-weight: bold !important;
		color: white;
	}

}

.timeline-info-popup {
	padding: 10px;
	transform: translateY(-8%);
	position: absolute;
	font-size: 16px;
	background-color: white;
	color: black;
	border-radius: 5px;
	margin: 10px;
	max-width: 250px;

}

/*
.timeline .year-track {
	top: 20px !important;
}

.timeline .year {
	top: 10px !important;
}

.timeline .o-slide {
	margin: 0 !important;
}

.timeline .o-slide__tick {
	top: -10px !important; 
}

.timeline .o-slide__tick-label {
	top: 22px !important;
}

.timeline .o-slide__tick--hidden {
	position: absolute;
	transform: translate(-50%, -50%);
	width: var(--oruga-slider-tick-width, 1px);
	background: var(--oruga-slider-tick-background, #4a4a4a);
	border-radius: var(--oruga-slider-tick-radius, 4px);
}
*/

</style>
