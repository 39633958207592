<template>

<Teleport to='body'>
<o-modal class='arkly-modal' contentClass='modal-content' v-show='isModalVisible' v-model:active='isModalActive' @close='closeModal'>
	<div id='close-modal-container'>
		<btn 
			label="close-modal-btn"
			backgroundColor='transparent'
			size='small' 
			color='grey' 
			icon='close'
			noPadding
			@click='closeModal'	
		/>
	</div>
	<slot />
</o-modal>
</Teleport>

</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'

export default {
	name: 'Modal',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
	},
	props: {
		modelValue: {
			type: Boolean,
		},
		background:{
			type: String,
			default: 'true'
		},
  		steps: {
			type: Array,
		},
		maskStyle: {
			default: {
				'width': 0,
				'height': 0,
			},
		},
		needHandle: {
			default: false,
		},
		mapHandle: {
			default: undefined,
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {

		}
	},
	created() {	
		document.addEventListener('keydown', this.handleEscape);
	},
	mounted() {	
	},
	unmounted() {
		document.removeEventListener('keydown', this.handleEscape);

	},
	computed: {
		isModalVisible: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		isModalActive() {
			return this.needHandle ? true : this.isModalVisible
		},
		...mapState(useConfigStore, ['isMobile']),
	},
	methods: {
		handleEscape (event) {
	        if (event.key === 'Escape') {
	         //if esc key was not pressed in combination with ctrl or alt or shift
	            const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
	            if (isNotCombinedKey) {
	                //console.log('Escape key was pressed with out any group keys')
	              	this.isModalVisible = false
	            }
	        }
		},
		closeModal() {
			this.isModalVisible = false
		},
		...mapActions(useConfigStore, []),
	},
	watch: {
		'maskStyle': {
			handler: function(state) {

			},
			deep: true,
		},
		modelValue(state) {

		},
	}

}

</script>

<style lang="scss">
@import "../style/oruga.scss";
@import "../style/arkly.scss";

.arkly-modal {	
	//border-radius: 5px;
	width: 100vw;
	height: 100vh;
	z-index: 99999;
}

.modal-title {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 7px;
	font-size: 25px;
	color: black;
	line-height: 1;
	width: 100%;
}

.arkly-modal #close-modal-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	height: auto;
	padding: $padding-heavy;
}

.arkly-modal .modal-content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	color: black;
	width: auto !important;
	background-color: white;	
	border-radius: 5px;
	border: solid black 2px;
	line-height: 1;
	max-height: auto;
	overflow: visible;
}

@media screen and (max-width: $mobile-breakpoint) {
	.arkly-modal {
		max-height: 100%;
	}

	.o-modal--mobile .o-modal__content {
		position: absolute;
		//max-height: calc(100vh - 10px) !important;
	}

	.o-modal--mobile .o-modal__content {
		height: auto;
		max-height: 100%;
	}

	.o-modal .o-modal--mobile .arkly-modal {
		position: fixed;
	}
}


</style>
