import { createRouter, createWebHistory } from 'vue-router'
import Result from '@/views/Result'
import Arkly from '@/views/Arkly'
import Location from '@/views/Location'
import Home from '@/views/Home'
import Map from '@/views/Map'
import Login from '@/views/Login'
import SuccessfulPayment from '@/views/SuccessfulPayment'
import FailedPayment from '@/views/FailedPayment'
import Terms from '@/views/TermsOfService'
import Privacy from '@/views/PrivacyPolicy'

import { isLoggedIn, getUserInfo } from '@/helpers/auth.js'
import { tryDecodeURLComponent } from '@/helpers/decode.js'

// Views that exist in arkly.com and hightide.ai
let routes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			platform: 'arkly',
			requiresAuth: false,
		},
		beforeEnter() {
			// Arkly and HighTide have different Homepages
			if (window.location.hostname.includes('hightide')) {
				router.push({name: 'login'})
			}
		}
	},
	{
		path: '/pr',
		name: 'puertoRicoHome',
		component: Home,
		meta: {
			platform: 'arkly',
			requiresAuth: false,
			platform: 'arkly',
		},
		beforeEnter() {
			// Arkly and HighTide have different Homepages
			if (window.location.hostname.includes('hightide')) {
				router.push({name: 'login'})
			}
		}
	},
	{
		path: '/hightide.ai',
		name: 'hightide',
		beforeEnter() {location.href = 'http://hightide.ai'}
	},
	{
		path: '/termsOfUse',
		name: 'terms',
		component: Terms,
		meta: {
			requiresAuth: false,
		}
	},
	{
		path: '/privacyPolicy',
		name: 'privacy',
		component: Privacy,
		meta: {
			requiresAuth: false,
		}
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			platform: 'govSub',
			requiresAuth: false,
		},
	},
	{
		path: '/payment/success',
		name: 'sucessfulPayment',
		component: SuccessfulPayment,
		meta: {
			requiresAuth: false,
		}
	},
	{
		path: '/payment/failure',
		name: 'failedPayment',
		component: FailedPayment,
		meta: {
			requiresAuth: false,
		}
	},
	{
		path: '/payment/canceled',
		name: 'failedPayment',
		component: FailedPayment,
		meta: {
			requiresAuth: false,
		},
		beforeEnter() { window.close()}
	},
	{
		path: '/map',
		name: 'map',
		component: Map,
		meta: {
			requiresAuth: true,
		},
	},
]

// Routes Specific to Arkly.com
const arklyRoutes = [
	{
		path: '/pr/:id',
		name: 'puertoRico',
		component: Arkly,
		meta: {
			platform: 'arkly',
			requiresAuth: false,
			region: 'PR',
			regionFull: 'puertorico',
		},	
	},
	/*
	{
		path: '/:region/:id',
		name: 'region',
		component: Arkly,
		meta: {
			platform: 'arkly',
			requiresAuth: false,
		},
	},
	*/
	{
		path: '/:id',
		name: 'arkly',
		component: Arkly,
		meta: {
			platform: 'arkly',
			requiresAuth: false,
			region: 'FL',
			regionFull: 'florida',
		},
		beforeEnter: (to, from) => {
			// Arkly and HighTide have different Homepages
			if (window.location.hostname.includes('hightide')) {
				router.push({name: 'login'})
			}
		}
	},
	/*
	{
		path: '/:pathMatch(.*)*',
		name: 'notFound',
		component: Arkly,
		meta: {
			platform: 'arkly',
			requiresAuth: false,
		},
	}
	*/
]

// Routes Specific to HighTide.ai
const govRoutes = [
	{
		path: '/:type/:id',
		name: 'result',
		meta: {
			platform: 'govSub',
			requiresAuth: false,
			region: 'FL',
			regionFull: 'florida',
		},
		redirect: to => {
			return { name: 'economic', params: {
				'view': 'economic', 'type': to.params.type, 'id': to.params.id 
			} }
		},	
	},
	{
		path: '/:view/:type/:id',
		name: 'economic',
		component: Result,
		meta: {
			platform: 'govSub',
			requiresAuth: true,
			region: 'FL',
			regionFull: 'florida',

		},
	},
]

// Create different landing pages for Arkly and Hightide.ai
if (window.location.hostname.includes('hightide')) {	
	routes = routes.concat(govRoutes)
} else if (window.location.hostname.includes('arkly')) {	
	routes = routes.concat(arklyRoutes)	
} else {
	routes = routes.concat(arklyRoutes).concat(govRoutes)
}

const router = createRouter({
	history: createWebHistory(),	
	routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 }
	},
})

router.beforeEach((to, from, next) => {
	if (to.meta.platform == 'arkly') {
		if (to.params.id != undefined && to.params.id.split('_')[3] == undefined) {
			next()
		} else if (to.name == 'puertoRico') {
			const state = to.params.id.split('_')[3]	
			const oldState = to.params.id.split('_')[2]	
			 if (state != 'PR' && oldState != 'PR') {
				console.log('changing state. Next State: FL', 'current State: ', state)
				router.push({name: 'arkly', params: to.params, query: to.query})
			} 
		} else if (to.name == 'arkly') {
			const state = to.params.id.split('_')[3]
			 if (state != 'FL') {
				console.log('changing state. Next State: ', state, 'current State: FL')
				router.push({name: 'puertoRico', params: to.params, query: to.query})
			}	
		} 
	}

	if (to.meta.requiresAuth && !isLoggedIn()) {
		next({
			path: '/login',
			query: { 
				name: to.name,
				view: to.params.view,
				type: to.params.type,
				id: to.params.id,
			}
		})
	}
	else {
		// Required to completely refresh Map
		// I think the issue is caused by data that mapbox has cached.
		// Unfortunately, they don't have any public functions to manage
		// that
		if ((from.name == 'home' || from.name == 'login') && to.name == 'result'){	
			window.location.href = window.location.origin + to.fullPath
		} else {
			next() 
		}
	} 
})

export default router
