<template>
<div class='share-container' >
	<!-- v-if='false' because user is being redirected to arkly.rocketflood.com -->
	<modal v-model='isRocketFloodVisible' v-show='isRocketFloodVisible'>
		<carousel
			v-if="state == 'info'"
			variant='modal' 
			class='rocketflood-modal'
			:lastButton="textContent['rocket_flood']['see-offers-button']['text']"
			:validation="{
				'foundation-type': Boolean(selected.foundationType),
				'number-of-steps': Boolean(selected.frontDoorType >=0 ),
				'basement-type': Boolean(selected.basementType),
				'raised-house-type': Boolean(selected.raisedHouseType),
				'garage-type': Boolean(selected.garageType),
				'house-details': Boolean(yearBuilt) && Boolean(area) && Boolean(constructionType) && Boolean(numberOfStories), 
				'personal-info': Boolean(isPhoneAndEmailValid && user['first_name'] && user['last_name']),
			}"
			@hasValidationError="state => hasValidationError = state"
			@lastButton='sendVerificationCode'
			:restartCarousel='restart'
			@hasRestarted='(el) => restart = false '
			@slideChanged='slideChanged'
			@closeCarousel='closeCarousel'
			noScroll
			
		>
			<!-- House Type Selections -->
			<div class='info-container' id='getting-started'>
				<span>
				<h5 class='title'> {{ textContent['rocket_flood']['getting-started-slide']['title'] }} </h5>
				<p> {{ textContent['rocket_flood']['getting-started-slide']['text'] }} </p>
				</span>
				<div class='info-content'>
					<div class='info'>
						<p> {{ textContent['rocket_flood']['getting-started-slide-option1']['text']}} {{location.address.street}}? </p>
						<div class='select'>
							<div>	
								<input type="radio" id='is-owner' :value='true' v-model='user.isOwner' />
								<label> {{ textContent['buttons']['btn-yes']['label']}} </label>
							</div>
							<div>
								<input type="radio" id='is-owner' :value='false' v-model='user.isOwner' />
								<label> No </label>
							</div>
						</div>
					</div>
					<div class='info'>
						<p> {{ textContent['rocket_flood']['getting-started-slide-option2']['text']}}</p>
						<div class='select'>
							<div> 
								<input type="radio" id='is-shopping' :value='true' v-model='user.isShopping' />
								<label> {{ textContent['rocket_flood']['getting-started-slide-option2-answers']['text']}}</label>
							</div>
							<div>
								<input type="radio" id='isShopping' :value='false' v-model='user.isShopping' />
								<label> {{ textContent['rocket_flood']['getting-started-slide-option2-answers']['title']}} </label>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- House Type Selections -->
			<div class='selection-container' id='occupancy-type'>
				<h5 class='title'> {{ textContent['rocket_flood']['occupancy-type-slide']['text'] }}</h5>
				<div id='occupancy-type' class='selection-content' > 
					<div 
						class='option' 
						:class="{'selected': selected.occupancyType == option.rocketflood}"
						v-for='option in occupancyTypes' 
						@click='selected.occupancyType = option.rocketflood'
					>		
						<inline-svg	
							class='selection-icon'
							:src='iconSRC(option.icon)'
							:title='option.icon'
						/>
						<div class='label'>
							<label for="option.icon">{{ option.label }}</label>
							<input type="radio" :id="option.icon" :value="option.rocketflood" v-model="selected.occupancyType" />	
						</div>
					</div>
				</div>
			</div>
			<div class='selction-container' id='foundation-type'>
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['rocket_flood']['foundation-type-slide']['title'] }}</h5>
				<h5 class='title' v-show="!hasValidationError"> {{ textContent['rocket_flood']['foundation-type-slide']['text'] }}</h5>
				<div id='foundation-type' class='selection-content'> 
					
					<div 
						class='option' 
						:class="{'selected': selected.foundationType == option.rocketflood}"
						v-for='option in foundationTypes' 
						@click='selected.foundationType = option.rocketflood'
					>
						
						<inline-svg	
							class='selection-icon'
							:src='iconSRC(option.icon)'
							:title='option.icon'
						/>
						<div class='label'>
							<label for="option.icon">{{ option.label }}</label>
							<input type="radio" :id="option.icon" :value="option.rocketflood" v-model="selected.foundationType" />	
						</div>
					</div>
				</div>
			</div>

			<!-- If House is on Slab -->
			<div 
				class='selection-container' 
				id='number-of-steps'
				v-if="selected.foundationType == 'Slab'"
			>
			<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['rocket_flood']['slab-step-slide']['title'] }} </h5>
			<h5 class='title' v-show="!hasValidationError"> {{ textContent['rocket_flood']['slab-step-slide']['text'] }} </h5>
				<div id='front-door-type' class='selection-content'> 	
					<div 
						class='option' 
						:class="{'selected': selected.frontDoorType == option.rocketflood}"
						v-for='option in frontDoorTypes'
						@click='selected.frontDoorType = option.rocketflood'
					>	
						<inline-svg	
							class='selection-icon'
							:src='iconSRC(option.icon)'
							:title='option.icon'

						/>

						<div class='label'>
							<label for="option.icon">{{ option.label }}</label>
							<input type="radio" :id="option.icon" :value="option.rocketflood" v-model="selected.frontDoorType" />	
						</div>
					</div>
				</div>
			</div>
	
			<!-- If House is on Basement -->
			<div 
				class='selection-container' 
				id='basement-type'
				v-else-if="selected.foundationType == 'Basement'"
			>
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['rocket_flood']['basement-slide']['title'] }}</h5>
				<h5 class='title' v-show="!hasValidationError"> {{ textContent['rocket_flood']['basement-slide']['text'] }} </h5>
				<div id='basement-type' class='selection-content'> 	
					<div 
						class='option' 
						:class="{'selected': selected.basementType == option.rocketflood}"
						v-for='option in basementTypes'
						@click='selected.basementType = option.rocketflood'
					>	
						<inline-svg	
							class='selection-icon'
							:src='iconSRC(option.icon)'
							:title='option.icon'
						/>

						<div class='label'>
							<label for="option.icon">{{ option.label }}</label>
							<input type="radio" :id="option.icon" :value="option.rocketflood" v-model="selected.basementType" />	
						</div>
					</div>
				</div>
			</div>

			<!-- If House is raised -->
			<div 
				class='selection-container' 
				id='raised-house-type'
				v-else-if="selected.foundationType == 'Stilts Pilings'"
			>	
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['rocket_flood']['raised-house-slide']['title'] }}</h5>
				<h5 class='title' v-show="!hasValidationError"> {{ textContent['rocket_flood']['raised-house-slide']['text'] }}</h5>
				<div id='raised-house-type' class='selection-content'> 	
					<div 
						class='option' 
						:class="{'selected': selected.raisedHouseType == option.rocketflood}"
						v-for='option in raisedHouseTypes'
						@click='selected.raisedHouseType = option.rocketflood'
					>	
						<inline-svg	
							class='selection-icon'
							:src='iconSRC(option.icon)'
							:title='option.icon'
						/>

						<div class='label'>
							<label for="option.icon">{{ option.label }}</label>
							<input type="radio" :id="option.icon" :value="option.rocketflood" v-model="selected.raisedHouseType" />	
						</div>
					</div>
				</div>
			</div>

			<!-- If House on Garage --> 
			<div 
				class='selection-container' 
				id='garage-type'
				v-else-if="selected.foundationType == 'Garage'"
			>
				<h5 class='title' style="color:red;" v-show="hasValidationError"> {{ textContent['rocket_flood']['garage-slide']['title'] }}</h5>
				<h5 class='title' v-show="!hasValidationError"> {{ textContent['rocket_flood']['garage-slide']['text'] }}</h5>
				<div id='raised-house-type' class='selection-content'> 	
					<div 
						class='option' 
						:class="{'selected': selected.garageType == option.rocketflood}"
						v-for='option in garageTypes'
						@click='selected.garageType = option.rocketflood'
					>	
						<inline-svg
							class='selection-icon'
							:src='iconSRC(option.icon)'
							:title='option.icon'
						/>

						<div class='label'>
							<label for="option.icon">{{ option.label }}</label>
							<input type="radio" :id="option.icon" :value="option.rocketflood" v-model="selected.garageType" />	
						</div>
					</div>
				</div>
			</div>

			<!-- House Info -->
			<div id='house-details' class='info-container'>
				<div class='title'>
					<h5> {{ textContent['rocket_flood']['house-details-slide']['title'] }} </h5>
					<p> {{ textContent['rocket_flood']['house-details-slide']['text'] }}</p>
					<div class="error-msg" v-show="hasValidationError">{{ textContent['rocket_flood']['house-details-slide-error-msg']['text'] }}</div>
				</div>
				<div class='info-content'>
					<div class='info'>
						<div>
							<label> {{ textContent['rocket_flood']['house-details-slide-options']['text'].split(',')[0] }}</label>
							<o-field 
								:rootClass="isValid(yearBuilt) ? 'success' : 'missing'" 
								:message="validationMessage(yearBuilt, textContent['rocket_flood']['house-details-slide-error-msg']['title'])"
							>	
							<o-input inputClass='arkly-input' v-model="yearBuilt" type='number' placeholder="" maxlength="2100" min='1800' :max='currentYear' ref='yearBuilt'/>
							</o-field>
						</div>
						<div>
							<label> {{ textContent['rocket_flood']['house-details-slide-options']['text'].split(',')[1] }}</label>

							<o-field>			
								<o-input inputClass='arkly-input' v-model="area" type='number' :placeholder="this.area" maxlength="2100" :validationMessage="textContent['rocket_flood']['house-details-slide-error-msg2']['text']" ref='area'/>
							</o-field>	
						</div>
					</div>
					<div class='info'>
						<div>
							<p> {{ textContent['rocket_flood']['house-details-slide-options']['text'].split(',')[2] }} </p>
							<div class='select'>
								<div>	
									<input type="radio" id='construction-type' value='Frame' v-model='constructionType' />
									<label> {{ textContent['rocket_flood']['house-details-slide-options']['title'].split('|')[0].split(',')[0] }} </label>
								</div>
								<div>
									<input type="radio" id='construction-type' value='Steel' v-model='constructionType' />
									<label> {{ textContent['rocket_flood']['house-details-slide-options']['title'].split('|')[0].split(',')[1] }} </label>
								</div>
								
								<div> 
									<input type="radio" id='construction-type' value='Superior Non Combustible' v-model='constructionType' />
									<label> {{ textContent['rocket_flood']['house-details-slide-options']['title'].split('|')[0].split(',')[2] }} </label>
								</div>
								
								<div>
									<input type="radio" id='construction-type' value='Masonry' v-model='constructionType' />
									<label> {{ textContent['rocket_flood']['house-details-slide-options']['title'].split('|')[0].split(',')[3] }} </label>
								</div>
							</div>
						</div>
						<div>
							<p> {{ textContent['rocket_flood']['house-details-slide-options']['text'].split(',')[3] }} </p>
							<div class='select'>
								<div> 
								<input type="radio" id='one-story' :value='1' v-model='numberOfStories' />
								<label> {{ textContent['rocket_flood']['house-details-slide-options']['title'].split('|')[1].split(',')[0] }}</label>
								</div>
								<div>
									<input type="radio" id='two-story' :value='2' v-model='numberOfStories' />
									<label> {{ textContent['rocket_flood']['house-details-slide-options']['title'].split('|')[1].split(',')[1] }}</label>
								</div>
								<div>	
									<input type="radio" id='more-story' :value='3' v-model='numberOfStories' />
									<label> {{ textContent['rocket_flood']['house-details-slide-options']['title'].split('|')[1].split(',')[2] }}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id='personal-info' class='info-container' ref='personalInfo'>
				<div class='title'>
					<h5> {{ textContent['rocket_flood']['personal-details-slide']['title'] }}</h5>
					<p> {{ textContent['rocket_flood']['personal-details-slide']['text'] }}</p>
				</div>
				<div class='info-content'>
					<div class='info'>
						<o-field 
							:variantClass="isValid(user.email) ? 'success' : 'missing'" 
							:message="validationMessage(user.email, textContent['rocket_flood']['personal-details-slide-error-msg']['text'].split(',')[0])"
						>
							<o-input 
								inputClass='arkly-input' 
								v-model="user.email" 
								type="email" 
								:placeholder="textContent['rocket_flood']['personal-details-slide-options']['text'].split(',')[0]"
								name = "email"
								autocomplete="email"
								maxlength="30" 
								:validationMessage="textContent['rocket_flood']['personal-details-slide-error-msg']['title'].split(',')[0]"
								ref='emailInput'
							/>
						</o-field>
						<o-field
							:rootClass="isValid(user.first_name) ? 'success' : 'missing'" 
							:message="validationMessage(user.first_name, textContent['rocket_flood']['personal-details-slide-error-msg']['text'].split(',')[1])"
						>
							<o-input 
								inputClass='arkly-input' 
								v-model="user.first_name" 
								:placeholder="textContent['rocket_flood']['personal-details-slide-options']['text'].split(',')[1]"
								validationMessage='Please Enter a Number'
								name = "fname"
								autocomplete="given-name"
							/>
						</o-field>
						<o-field
							:rootClass="isValid(user.last_name) ? 'success' : 'missing'" 
							:message="validationMessage(user.last_name, textContent['rocket_flood']['personal-details-slide-error-msg']['text'].split(',')[2])"
						>
							<o-input 
								inputClass='arkly-input' 
								v-model="user.last_name" 
								:placeholder="textContent['rocket_flood']['personal-details-slide-options']['text'].split(',')[2]"
								name = "lname"
								autocomplete="family-name"
							/>
						</o-field>
						<o-field
							:variantClass="false ? 'success' : 'missing'" 
							:message="validationMessage(user.phone, textContent['rocket_flood']['personal-details-slide-error-msg']['text'].split(',')[3])"
						>
							<o-input 
								inputClass='arkly-input' 
								v-model="user.phone" 
								:placeholder="textContent['rocket_flood']['personal-details-slide-options']['text'].split(',')[3]"
								type="tel" 
								pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
								:validationMessage="textContent['rocket_flood']['personal-details-slide-error-msg']['title'].split(',')[1]"
								name = "phone"
								autocomplete="tel"
								ref='phoneInput'
							/>
						</o-field>
					</div>
				</div>
			</div>	

		</carousel>

		<carousel
			v-else-if="state == 'verify-phone'"
			variant='modal' 
			class='rocketflood-modal result-container'
			lastButton='Verify Phone'
			@lastButton='testVerificationCode()'
			noScroll
		>

			<!-- Verify Phone Number -->
			<div id='verify-phone'>
				<h5 class='title'> {{ textContent['rocket_flood']['verify-slide']['title'] }} </h5>
				<p> {{ textContent['rocket_flood']['verify-slide']['text'] }} {{ user.phone }} </p>
				<div class='center'>
					<o-field
							:variantClass="false ? 'success' : 'missing'" 
							:message="validationMessage(user.verification_code, textContent['rocket_flood']['verify-slide-error-msg']['text'])"
						>
							<o-input 
								inputClass='arkly-input' 
								v-model="user.verification_code" 
								:placeholder="textContent['rocket_flood']['verify-slide-error-msg']['title']"
								type="number" 
								pattern="[0-9]{6}"
								:validationMessage="textContent['rocket_flood']['verify-slide-error-msg']['text']"
								ref='phoneVerificationInput'
								autocomplete="one-time-code"
							/>
					</o-field>
				</div>
			</div>
		
		</carousel>

		<carousel
			v-else
			variant='modal' 
			class='rocketflood-modal result-container'
			:lastButton='undefined'
			:advanceSlide='advanceQuoteSlide'
			@slideAdvanced='() => advanceQuoteSlide = false'
			noControls
			noScroll
		>
			<!-- Loading Quotes -->
			<div class='selection-container' v-if='isLoading' >
				<h5 class='title'> <div v-html="textContent['rocket_flood']['loading-quotes']['title']"></div> {{ user.first_name }} </h5>
				<p v-html="textContent['rocket_flood']['loading-quotes']['text']"></p>
				<div class='center'>
					<div class='loading-spinner' />
						
					<div class='taking-too-long' v-if='hasExceededTime'>
						<p v-html="textContent['rocket_flood']['taking-too-long']['text']"></p>
						<btn 
							variant='arkly' 
							:backgroundColor='buttonColor' 
							width='72px !important' 
							icon='arrow-right'
							@click='startSalesCall("time-exceeded")'
						/>
					</div>
					
				</div>
			</div>

			<div class='selection-container' v-if='returnedError' >
				<h5 class='title'> {{ textContent['rocket_flood']['quote-error-slide']['title'].split('|')[0] }} {{ user.first_name }} {{ textContent['rocket_flood']['quote-error-slide']['title'].split('|')[1] }} </h5>
				<div class="quote-error">
					<p> {{ textContent['rocket_flood']['quote-error-slide']['text'] }}</p>
					<btn 
						variant='arkly' 
						:backgroundColor='buttonColor' 
						width='72px !important' 
						icon='arrow-right'
						@click='startSalesCall("returned-error")'
					/>
				</div>
			</div>

			<!-- Returned Quotes -->
			<div class='quote-container' v-if='!isLoading && !returnedError' >
				<div v-html="textContent['rocket_flood']['quote-results']['text']">
				</div>
				<div class='quotes'>
					<div class='quote' v-show="formattedQuotes['lowest']['premium'] && (formattedQuotes['lowest']['premium'] < formattedQuotes['NFIP']['premium'] || formattedQuotes['NFIP']['premium'] === undefined)">
						<div> 
							<div> {{ textContent['rocket_flood']['quote-names']['title'] }} </div>
							<strong> ${{ formattedQuotes['lowest']['premium'].toLocaleString() }} </strong> /{{ textContent['rocket_flood']['quote-results']['title'] }}
						</div>

						<btn variant='arkly' :backgroundColor='buttonColor' width='72px !important' icon='arrow-right' @click='startSalesCall("private")'/>
					</div>
					<div class='quote'>
						<div >
							<div style='margin-right: 5px'> {{ textContent['rocket_flood']['quote-names']['text'] }}  </div>
							<div v-if="formattedQuotes['NFIP']['premium']"> 
								<strong> ${{ formattedQuotes['NFIP']['premium'].toLocaleString() }} </strong> /{{ textContent['rocket_flood']['quote-results']['title'] }} 
							</div>
							<div v-else>
								{{ textContent['rocket_flood']['still-loading']['text'] }}
							</div>
						</div>

						<btn variant='arkly' :backgroundColor='buttonColor' width='72px !important' icon='arrow-right' @click='startSalesCall("NFIP")'/>
					</div>
				</div>

			</div>

			<!-- Returned Quotes -->
			<div class='quote-container' v-if='!isLoading' >
				<div v-if='isWorkingHours'> 
					<h5 class='title'> {{ textContent['rocket_flood']['quote-working-hours-slide']['title'] }} </h5>
					<p v-html="textContent['rocket_flood']['quote-working-hours-slide']['text']"></p>
				</div>
				<div v-else>
					<h5 class='title'> {{ textContent['rocket_flood']['quote-non-working-hours-slide']['title'] }} </h5>
					<p> {{ textContent['rocket_flood']['quote-non-working-hours-slide']['text'] }} </p>
				</div>
			</div>

			<template v-slot:control-replacement>
				<p id='footer'> {{ textContent['rocket_flood']['footer']['title'] }} </p>
			</template>
		</carousel>
	</modal>
</div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useConfigStore } from '@/store/config'
import { useSurfStore } from '@/store/surf'
import { socketState, connectToSocket } from '@/socket.js'
import { event } from 'vue-gtag'
import axios from 'axios'
import Button from '@/components/Button'
import InlineSvg from 'vue-inline-svg'
import Modal from '@/components/Modal'
import Carousel from '@/components/Carousel'

const controller = new AbortController()

//console.log(process.env, process.env.VUE_APP_API_URL)

export default {
	name: 'RocketFlood',
	components: {
		'inline-svg': InlineSvg,
		'btn': Button,
		'modal': Modal,
		'carousel': Carousel,
	},
	props: {
		modelValue: {
			type: Boolean,
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			runningFunction: undefined,
			state: 'info',
			isLoading: false,
			returnedError: false,
			hasExceededTime: false,
			timesCalled: 0,
			isTesting: false,
			infoSlideNumber: 0,
			currentPage: 2,
			restart: false,
			carousel: undefined,
			advanceQuoteSlide: false,
			hasValidationError: false,
			isPhoneAndEmailValid: false,
			isWorkingHours: true,
			quoteRequests: {
				twoSeconds: undefined,
				thirtySeconds: undefined,
				oneMinute: undefined,
				twoMinutes: undefined,
			},
			user: {
				isOwner: 'not_answered',
				isShopping: 'not_answered',
				first_name: undefined,
				last_name: undefined,
				phone: undefined,
				email: undefined,
				verification_code: undefined,
				verified: undefined,
			},
			selected: {
				foundationType: undefined,
				frontDoorType: undefined,
				basementType: undefined,
				raisedHouseType: undefined,
				garageType: undefined,
				houseType: undefined,
			},
			area: undefined,
			yearBuilt: undefined,
			constructionType: undefined,
			numberOfStories: undefined,
			isOwner: [
				{
					label: 'Yes',
					state: 'isOwner',
					value: true,
				},
				{
					label: 'No',
					state: 'isNotOwner',
					value: false,
				}
			],
			isShopping: [
				{
					label: 'Yes',
					state: 'isShopping',
					value: true,
				},
				{
					label: 'No',
					state: 'isNotShopping',
					value: false,
				}
			],
			foundationTypes: [
				{
					label: 'House on Slab',
					icon: 'house-on-slab',
					rocketflood: 'Slab',
				},
				{
					label: 'House on Crawl Space',
					icon: 'house-on-crawl-space',
					rocketflood: 'Crawlspace',
				},
				{
					label: 'House on Basement',
					icon: 'house-on-basement',
					rocketflood: 'Basement',
				},
				{
					label: 'House on Stilts',
					icon: 'house-on-stilts',
					rocketflood: 'Stilts Pilings',
				},
				{
					label: 'House over Garage',
					icon: 'house-on-garage',
					rocketflood: 'Garage',
				},
			],
			frontDoorTypes: [
				{
					label: 'No Steps',
					icon: 'door-no-steps',
					rocketflood: 0,
				},
				{
					label: '1 Step',
					icon: 'door-one-step',
					rocketflood: 1,
				},
				{
					label: '2 Steps',
					icon: 'door-two-steps',
					rocketflood: 2,
				},
				{
					label: '3+ Steps',
					icon: 'door-more-steps',
					rocketflood: 3,
				},
			],
			basementTypes: [
				{
					label: 'Full Basement',
					icon: 'house-on-basement',
					rocketflood: 'Full Basement'
				},
				{
					label: 'Walkout Basement',
					icon: 'house-on-basement',
					rocketflood: 'Walkout Basement'
				}
			],
			basementFinished: [
				{
					label: 'House Over Unfinished Basement',
					icon: 'house-on-basement',
					rocketflood: false,
				},
				{
					label: 'House Over Finished Basement',
					icon: 'house-on-basement',
					rocketflood: true,
				}
			],
			raisedHouseTypes: [
				{
					label: 'Completely Open Ground Floor',
					icon: 'house-on-stilts',
					rocketflood: 'Open',
				},
				{
					label: 'Over Unfinished Ground Floor Level',
					icon: 'house-on-stilts',
					rocketflood: 'Solid Encolsure - Unfinished',
				},
				{
					label: 'Over Finished Ground Floor Level',
					icon: 'house-on-stilts',
					rocketflood: 'Solid Encolsure - Living Space',
				}
			],
			garageTypes: [
				{
					label: 'Unfinished Garage',
					icon: 'house-on-garage',
					rocketflood: false,
				},
				{
					label: 'Finished Garage',
					icon: 'house-on-garage',
					rocketflood: true,
				}
			],
			occupancyTypes: [
				{
					label: 'Single Family',
					icon: 'single-family-home',
					rocketflood: 'Single Family',
				},
				{
					label: 'Two to Four Family Home',
					icon: 'two-to-four-family-home',
					rocketflood: '2-4 Family',
				},
				{
					label: 'Townhouse',
					icon: 'townhome',
					rocketflood: 'Townhouse/Rowhouse',
				},
				{
					label: 'Condo',
					icon: 'condo',
					rocketflood: 'Condo'
				},
				/*
				{
					label: 'Mobile Home',
					icon: 'mobile-home',
				},
				*/
			],
			applicationId: undefined,
			rocketFloodApplication: undefined,
			quotes: [],
			socket: undefined,
		}
	},
	mounted: async function() {
		this.setTestingValues()
		if (this.attributes) {
			this.area = parseInt(this.buildingArea*0.75)
			if (this.attributes.yearBuilt) {
				this.yearBuilt = this.attributes.yearBuilt
			}
		}
		
		// If an application has already been submitted
		if (this.mapConfig.routesWithInsurance.includes(this.$route.name) && this.$route.params.id != 'Location'){
			this.getRocketFloodQuotes(true)
		}
		
		
		// When a new quote is sent to the socket
		// add it to the quotes array
	},
	unmounted: async function() {
		//cancel requests
		controller.abort()

		if (this.socket) {
			this.socket.disconnect()

		}
	},
	methods: {
		capitalizeFirstLetter(word) {
			const strWord = String(word)
			const firstLetter = strWord.charAt(0)
			const firstLetterCap = firstLetter.toUpperCase()
			const remainingLetters = strWord.slice(1)
			const capitalizedWord = firstLetterCap + remainingLetters
			return capitalizedWord
		},
		sourceCallback() {
		// assuming 'map' is defined globally, or you can use 'this'
			this.timesCalled = this.timesCalled + 1
				if (this.timesCalled > 90) {
					this.hasExceededTime = true
					clearInterval(this.runningFunction)
				}
		},
		closeCarousel(value) {
			//console.log(value)
			if(value) {
				this.isRocketFloodVisible = false
			}

		},
		slideChanged(newSlide) {
			if (this.user.first_name) {
				this.user.first_name = this.capitalizeFirstLetter(this.user.first_name)
			}
			if (newSlide.id == 'foundation-type'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', occupancyType: ' + this.selected.occupancyType)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', occupancyType: ' + this.selected.occupancyType)
				event('rocketflood_occupancy_type', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'occupancy_type' : this.selected.occupancyType, 'location_ID' : window.location.href.split('/').slice(-1)})

			} else if (newSlide.id == 'house-details'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', foundationType: ' + this.selected.foundationType)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', foundationType: ' + this.selected.foundationType + ', steps: ' + this.selected.frontDoorType + ', raisedHouseType: ' + this.selected.raisedHouseType + ', garageType ' +  this.selected.garageType + ', basementType' + this.selected.basementType)
				event('rocketflood_foundation_type', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'foundationType' : this.selected.foundationType, 'numSteps' : this.selected.frontDoorType, 'raisedHouseType' : this.selected.raisedHouseType, 'garageType' : this.selected.garageType, 'basementType' : this.selected.basementType, 'location_ID' : window.location.href.split('/').slice(-1)})

			} else if (newSlide.id == 'personal-info'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', yearBuilt: ' + parseInt(this.yearBuilt) + ', area: ' + parseInt(this.area) + ', stories: ' + parseInt(this.numberOfStories) + ', constructionType: ' + this.constructionType)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', yearBuilt: ' +  + ', area: ' + parseInt(this.area) + ', stories: ' + parseInt(this.numberOfStories) + ', constructionType: ' + this.constructionType)
				event('rocketflood_house_details', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'yearBuilt' : parseInt(this.yearBuilt), 'area' : parseInt(this.area), 'stories' : parseInt(this.numberOfStories), 'constructionType' : this.constructionType, 'location_ID' : window.location.href.split('/').slice(-1)})

			} else if (newSlide.id == 'quotes'){
				//console.log('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', current slide id: ' + newSlide.id + ', userInfo: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', userInfo: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
				event('rocketflood_personal_info', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
			}
			//console.log(newSlide)
		},
		startSalesCall(source) {
			if (!this.isTesting) {
				this.isLoading = false
				this.advanceQuoteSlide = true
				event('conversion', {'event_category': 'rocketflood', 'send_to': this.AdsConversionGtagID, 'callSource': source, 'location_ID' : window.location.href.split('/').slice(-1)})
				event('conversion', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'callSource': source, 'location_ID' : window.location.href.split('/').slice(-1)})
				confection.submitEvent('rocketflood_funnel','location ID: ' + window.location.href.split('/').slice(-1) + ', sales call requested: ' + source)

				const instance = axios.create()

				let url = `${this.url}/auth/sales-request`	

				var data = new FormData()
				data.append('phone_number', this.user.phone)
				try {
					console.log(this.rocketFloodApplication['_id'])
					if ('_id' in this.rocketFloodApplication) {
						data.append('rocketflood_application', this.rocketFloodApplication['_id'])
					} else {
						data.append('rocketflood_application', this.rocketFloodApplication['application_details']['application_id'])
					}
					
				} catch (e) {
					console.log(e,this.rocketFloodApplication)
					data.append('rocketflood_application', `Error: ${e}`)
				}


				return	instance.post(
					url, data, {
						signal: controller.signal,
						headers: {
							"Content-Type": "multipart/form-data",
						},	
					},
				).then(response => {
					this.isWorkingHours = response.data.call_status
					//console.log('call status: ', response.data.call_status)

				})
			} else {
				console.log('if not in testing mode, then call would start now') 
			}
		},
		setTestingValues() {
			if (this.isTesting) {
				this.yearBuilt = '1993'
				this.user.phone = process.env.VUE_APP_PHONE_NUMBER ? process.env.VUE_APP_PHONE_NUMBER : '555-555-5555'
				this.user.first_name = process.env.VUE_APP_FIRST_NAME ? process.env.VUE_APP_FIRST_NAME : 'John'
				this.user.last_name = process.env.VUE_APP_LAST_NAME ? process.env.VUE_APP_LAST_NAME : 'Doe'
				this.user.email = process.env.VUE_APP_EMAIL ? process.env.VUE_APP_EMAIL : 'jdoe@email.com',
				this.selected.foundationType = 'Slab'
			}
		},
		addNewQuote(...args) {
			console.log('received a new quote')
			// Push the New Quote into the array of quotes	
			this.quotes.push(args[0])
			
			//sort Quotes
			this.sortQuotes(this.quotes)

			console.log('add new quote', args[0])
		},
		sortQuotes(quotes) {
			// Sort the Array of quotes, so that the new
			// quote is added in the proper position
			this.quotes = quotes.filter(quote => {
				return 'premium' in quote && quote.premium && quote.premium > 0
			}).sort((quoteA, quoteB) => {
				return  quoteA.premium - quoteB.premium
			}).map(quote => {
				return {
					premium: quote.premium,
					url: undefined,
					carrier: quote.carrier,
				}		
			})

			this.testForValidQuotes()
		},
		isValid(variable) {
			return Boolean(variable) && !this.hasValidationError
		},
		validationMessage(variable, message) {
			if ((!Boolean(variable) && this.hasValidationError)) {
				return message
			} else {
				return ''
			}
		},
		iconSRC(icon) {
			return require(`@/assets/icons/${icon}.svg`)
		},
		sendVerificationCode: async function() {
			if (!this.hasValidationError){
				// If testing is on or we used the secret hightide testing phone and email, then skip phone verification
				if (this.isTesting == true || (this.user.email == 'info@hightide.ai' && this.user.phone == '098-765-4321')) {
					this.submitRocketFloodRequest()
					this.state = 'quotes'
					return
				}

				const instance = axios.create()

				let url = `${this.url}/auth/send/phone-number`

				var data = new FormData()
				data.append('phone_number', this.user.phone)

				return	instance.post(
					url, data, {
						headers: {
							"Content-Type": "multipart/form-data",
						},	
					},
				)
					.then(response => {
						this.state = 'verify-phone'
						event('rocketflood_verify_sent', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
						confection.submitEvent('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', verify code sent to: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
						//console.log('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', verify code sent to: ' + this.user.phone + ',' + this.user.first_name + ',' + this.user.last_name + ',' + this.user.email)
										
					}).catch(error => {
						console.log(error)
						event('rocketflood_verify_error', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'errorMsg' : error.response.data.detail.message, 'location_ID' : window.location.href.split('/').slice(-1)})
						confection.submitEvent('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', verify error: ' + error)
						
						if (error.response.data.detail.message.startsWith('Unable to create record: Invalid parameter `To`:')) {
							this.state = 'info'
						} else {
							this.state = 'verify-phone'
						}
					})
			}
		},
		testVerificationCode: async function() {	
			const instance = axios.create()

			const locationId = this.$route.params.id //'952-39th-Ct_West-Palm-Beach_FL'

			let url = `${this.url}/auth/verify/phone-number`
			
			var data = new FormData()
			data.append('phone_number', this.user.phone)
			data.append('token', this.user.verification_code)

			return	instance.post(
				url, data, {
					timeout: 1000 * 5,
					headers: {
						"Content-Type": "multipart/form-data",
					},	
				},
			)
				.then(response => {
					if (response.data.status == 'approved') {
						event('rocketflood_verify_correct', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
						confection.submitEvent('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', correct code entered')
						//console.log('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', correct code entered')
						this.submitRocketFloodRequest()
					} else {
						event('rocketflood_verify_incorrect', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
						confection.submitEvent('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', incorrect code entered')
						//console.log('rocketflood_verify','location ID: ' + window.location.href.split('/').slice(-1) + ', incorrect code entered')
						this.$refs.phoneVerificationInput.setValidity({}, 'Incorrect Code')
					}
				})
		},
		getRocketFloodQuotes: async function(isFirstRun = false) {
			//console.log('getRocketFloodQuotes')
			const instance = axios.create()

			const locationId = this.$route.params.id //'952-39th-Ct_West-Palm-Beach_FL'

			let url = `${this.url}/rocketflood/quotes/${locationId}`

			return	instance.get(
				url, {
					/*signal: controller.signal,*/
					headers: {
						"Content-Type": "application/json",
					},	
				},
			)
				.then(response => {	
					this.rocketFloodApplication = response.data
					if (response.data != null) {
						this.applicationId = response.data['_id']
						if (response.data.quote) {
							this.sortQuotes(response.data.quote)
							this.state = isFirstRun ? this.state : 'quotes'
						}
					
						if (response.data.status != 'complete') {
							this.postRocketFloodQuotes().then(newQuotes => {
								if (newQuotes.quote) {
									this.state = isFirstRun ? this.state : 'quotes'
								}
							})
						}

						return response.data 
					} else {
						return undefined
					}
				})
		},
		createRocketFloodApplication: async function(application) {

			const instance = axios.create()
			
			const body = application

			const locationId = this.$route.params.id 

			//console.log(application)

			//console.log(this.rocketFloodApplication)
			
			if (!this.rocketFloodApplication || this.rocketFloodApplication.location != locationId || this.rocketFloodApplication.status != 'complete') {

				//console.log('loading quotes')

				let url = `${this.url}/rocketflood/create-application/${locationId}`

				return	instance.post(
					url, application, {
						/*signal: controller.signal,*/
						//timeout: 1000 * 100,
						headers: {
							"Content-Type": "application/json",
						},	
					},
				)
				.then(response => {
					return response
				}).catch(error => {
					this.returnedError = true
					this.isLoading = false
					console.log(error['response']['status'],error['response']['data'])
					console.log(error)
				})
			} else {
				//this.isLoading = false
			}
		},
		postRocketFloodQuotes: async function() {
			
			event('rocketflood_quotes_query', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('rocketflood_quotes','current location ID: ' + window.location.href.split('/').slice(-1) + ', querying for quotes')
			//console.log('rocketflood_quotes','current location ID: ' + window.location.href.split('/').slice(-1) + ', querying for quotes')

			const instance = axios.create()

			const locationId = this.$route.params.id //'952-39th-Ct_West-Palm-Beach_FL'

			let url = `${this.url}/rocketflood/quotes/${locationId}`

			return	instance.post(
				url, {
					/*signal: controller.signal,*/
					timeout: 1000 * 30,
					headers: {
						"Content-Type": "application/json",
					},	
				},
			)
				.then(response => {
					//console.log('post_quotes: ', response)	

					if (response.data != null) {
						if (response.data.quote != null) {
								this.sortQuotes(response.data.quote)	
						}

						return response.data
					} else {

						return null
					}
				})
		},
		postUserInfo: async function(application) {
			const instance = axios.create()
			
			let url = `${this.url}/rocketflood/user-info`

			return instance.post( 
				url, application, { headers: { "Content-Type": "application/json", } }
			).then(response => {
				
			})
		},
		submitRocketFloodRequest: async function() {	
			// Submit Phone Validation

			const application = JSON.parse(JSON.stringify({
				"address1": this.location.address.street,
				"city": this.location.address.city,
				"state": this.location.address.state,
				"zip": String(parseInt(this.location.address.zip)),
				"first_name": this.user.first_name,
				"last_name": this.user.last_name,
				"phone": this.user.phone,
				"email": this.user.email,
				//"is_owner": this.user.isOwner.toString(),
				//"is_shopping": this.user.isShopping.toString(),
				"foundation_type": this.selected.foundationType,
				"occupancy_type": this.selected.occupancyType,
				"year_built": parseInt(this.yearBuilt),
				"sqft": parseInt(this.area),
				"num_of_stories": parseInt(this.numberOfStories),
				"stairs_leading_to_front_door": this.selected.frontDoorType,
				"stilts_pilings":  this.selected.raisedHouseType,
				"garage_finished": this.selected.garageType,
				"basement_type": this.selected.basementType,	
				"basement_finished": this.selected.basementFinished,
				"construction_type": this.constructionType,
				"flood_zone": this.floodZone.split('|')[0]
			}))
			
			event('rocketflood_quote_request', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'location_ID' : window.location.href.split('/').slice(-1)})
			confection.submitEvent('rocketflood_qr','current location ID: ' + window.location.href.split('/').slice(-1) + ', user email: ' + this.user.email + ', phone: ' + this.user.phone)
		
			this.state = 'quotes'
			this.isLoading = true	
			this.timesCalled = 0
			var t = setInterval(this.sourceCallback,1000)
			this.runningFunction = t
			//setTimeout(() => {
			//	this.hasExceededTime = true
			//},1.5 * 60 * 1000)		
			
			this.postUserInfo(application)
			this.createRocketFloodApplication(application).then(response => {	

				// Check Quotes after 2 Seconds
				this.quoteRequests.twoSeconds = setTimeout(() => this.postRocketFloodQuotes(),  2 * 1000)

				// Check Quotes after 30 Seconds
				this.quoteRequests.thirtySeconds = setTimeout(() => this.postRocketFloodQuotes(),  30 * 1000)

				// Check Quotes after 60 Seconds
				this.quoteRequests.oneMinute = setTimeout(() => this.postRocketFloodQuotes(),  60 * 1000)

				// Check Quotes after 2 Minutes
				this.quoteRequests.twoMinutes = setTimeout(() => {
					// Disconnect WebSocket After 2 Minutes, to reduce network traffic
					if (this.socket) {
						this.socket.disconnect()
					}
					this.postRocketFloodQuotes()
				},  2 * 60 * 1000)

				if (response['data']['code'] != 200 && response['data']['code'] != 422){
					console.log('create application request failed...')
					console.log("Code: ",response['data']['code'])
					console.log("Message: ",response['data']['message'])
				}
				
			})	
		},	
		testForValidQuotes(){
			if (this.quotes.length > 0) {
				if (this.quotes[0]['premium']) {
					if (this.quotes[0]['premium'] > 0) {
						this.isLoading = false
						this.timesCalled = 0
						clearInterval(this.runningFunction)
					}
				}
			}
		},
		...mapActions(useSurfStore, []),
	},
	computed: {
		socketQuotes() {
			return socketState.quotes
		},	
		surfId() {
			return this.$route.params.id
		},
		formattedQuotes() {
			this.testForValidQuotes()

			let NFIP = this.quotes.find(quote => quote.carrier == 'NFIP')
			return {
				'lowest': this.quotes[0] ? { premium: this.quotes[0]['premium']} : { premium: undefined},
				'NFIP': NFIP ? { premium: NFIP.premium} : { premium: undefined},
			}
		},
		currentYear() {
			const currentYear = new Date().getFullYear()
			return currentYear
		},
		isRocketFloodVisible: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		currentSlide: {
			get() {
				return this.currentPage
			},
			set(value, oldValue) {
				//const oldValue = this.currentPage
				this.currentPage = value
			}
		},
		...mapState(useConfigStore, ['userLanguage','mapConfig','AdsConversionGtagID','userIP','colors', 'componentRefs', 'url', 'gtagID','textContent']),
		...mapState(useSurfStore, ['attributes', 'fullAddress', 'location', 'rocketflood', 'buildingArea','floodZone']),
	},
	watch: {
		userLanguage(){
			var typeLabels = this.textContent['rocket_flood']['occupancy-types']['text'].split(',')
			for(var i=0; i<this.occupancyTypes.length; i++){
				this.occupancyTypes[i].label = typeLabels[i]
			}
			typeLabels = this.textContent['rocket_flood']['foundation-types']['text'].split(',')
			for(var i=0; i<this.foundationTypes.length; i++){
				this.foundationTypes[i].label = typeLabels[i]
			}
			typeLabels = this.textContent['rocket_flood']['slab-step-types']['text'].split(',')
			for(var i=0; i<this.frontDoorTypes.length; i++){
				this.frontDoorTypes[i].label = typeLabels[i]
			}
			typeLabels = this.textContent['rocket_flood']['basement-types']['text'].split(',')
			for(var i=0; i<this.basementTypes.length; i++){
				this.basementTypes[i].label = typeLabels[i]
			}
			typeLabels = this.textContent['rocket_flood']['raised-house-types']['text'].split(',')
			for(var i=0; i<this.raisedHouseTypes.length; i++){
				this.raisedHouseTypes[i].label = typeLabels[i]
			}
			typeLabels = this.textContent['rocket_flood']['garage-types']['text'].split(',')
			for(var i=0; i<this.garageTypes.length; i++){
				this.garageTypes[i].label = typeLabels[i]
			}
		},
		async applicationId(newId, oldId) {
			if (newId && this.socket == undefined) {
				connectToSocket(newId).then(socket => {
					this.socket = socket
					const test = () => {
						console.log('received new quote')
					}
					socket.on('new_quote', this.addNewQuote)
				})
			}
		},
		state(newState) {
			if (newState != 'info') {
				this.slideChanged({'current': 1, 'numberOfSlides':1, 'id': newState})
			} 
		},
		buildingArea(newArea) {
			this.area = newArea 
		},
		hasValidationError(newState) {

		},	
		isRocketFloodVisible(newState) {

			//console.log('rocketflood_visible','location ID: ' + window.location.href.split('/').slice(-1) + ', visibility state: ' + newState)
			confection.submitEvent('rocketflood_visible','location ID: ' + window.location.href.split('/').slice(-1) + ', visibility state: ' + newState)
			event('rocketflood_visible', {'event_category': 'rocketflood', 'send_to': this.gtagID, 'rocketflood_visibility' : newState, 'location_ID' : window.location.href.split('/').slice(-1)})

			if (newState == false) {
				this.hasExceededTime = false
				clearInterval(this.runningFunction)
				this.timesCalled = 0

				//Cancel Timeouts
				Object.entries(this.quoteRequests).forEach(([key, request]) => {

					//console.log('cancelling request: ', `${key} ${request}`)

					// If the timeout request has an id set,
					if (typeof request === 'number') {
						// then cancel the timeout
						clearTimeout(request)
						this.quoteRequests[key] = undefined
					}
				})

				this.restart = true		
			} else {
				
				//confection.submitEvent('rocketflood_click','IP: ' + this.userIP + ', current location ID: ' + window.location.href.split('/').slice(-1))


				// Redirecting User to Rocketflood.com for right now
				//window.location.href = 'https://arkly.rocketflood.com'
			}
		},
		socketQuotes: {
			handler: function(result) {
				console.log(result)	
			},
			deep: true,
		},
		user: {
			handler: function(newInfo, oldInfo) {

				if (this.mapConfig.routesWithInsurance.includes(this.$route.name) && this.$route.params.id != 'Location' && newInfo.phone){
					const phone = newInfo.phone
					
					if (phone && (phone.length == 3 || phone.length == 7)) {
							this.user.phone = this.user.phone + '-'
					}
					
					/* Currently too slow
					if (newInfo.phone) {
						// Force Input to follow 999-999-9999 convention
						this.user.phone = newInfo.phone.replace(/\D/g, '')

						if (newInfo.phone[3] && newInfo.phone[3] != '-') {
							this.user.phone = newInfo.phone.splice(3,0,'-')
						}

						if (newInfo.phone[7] && newInfo.phone[7] != '-') {
							this.user.phone = newInfo.phone.splice(7,0,'-')
						}
					
					}
					*/

					this.isPhoneAndEmailValid = Boolean(this.$refs.phoneInput && newInfo.email && newInfo.phone && this.$refs.phoneInput.checkHtml5Validity() && this.$refs.emailInput.checkHtml5Validity())
				}
			},
			deep: true,
		},
		quotes: {
			handler: function(newQuote) {
				//this.isLoading = false
			},
			deep: true
		},
		currentPage: {
			handler: function(newPage, oldPage) {
				if (newPage == 3 && oldPage == 2) {
					this.currentPage = 2
					//console.log(this.currentPage)
				}
			},
		},
		attributes(newAttributes) {
			this.area = parseInt(newAttributes.area*0.75)
			if (newAttributes.yearBuilt) {
				this.yearBuilt = newAttributes.yearBuilt
			}
		
		},
		surfId: function(newAddress) {
			//console.log('new surf id: ', newAddress)
			
			if ( this.socket) {
				this.socket.disconnect()
			}

			this.hasExceededTime = false
			clearInterval(this.runningFunction)
			this.timesCalled = 0

			// Reset all data to original values, when 
			// on a new property
			Object.assign(this.$data, this.$options.data())

			// setting restart has to happen after reset all data
			this.restart = true
			
			/*
			if (this.socket != undefined) {
				console.log('socket exists')
				this.socket.emit('applicationId', 'test')
			}
			*/

			this.rocketFloodApplication = undefined
			this.setTestingValues()
			if (this.mapConfig.routesWithInsurance.includes(this.$route.name) && this.$route.params.id != 'Location'){
				// if current property has changed, rerun getRocketFloodQuotes
				this.getRocketFloodQuotes(true)
			}

		},
	}
}
</script>

<style lang="scss" scoped>
@import "../../style/oruga.scss";
@import "../../style/style.scss";
@import "../../style/arkly.scss";

.error-msg {
	color: red;
	text-align: center;
	font-weight: bolder;
	font-size: larger;
}

.p .o-field__message {
	border: 7px solid red !important;
	position: relative !important;
	top: -7px !important;
}

#controls {
	background-color: $sand;
}

.title {
	flex-direction: column;
	grid-column: 1/-1;
}

.selection-content {
	display: flex;
	max-height: 100%;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5px;
	height: calc(100% - 40px);
	overflow: none;
	overflow-y: scroll;
}

.switch {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 20px;
	background: #FFFFFF;

	/* Base/Overlay 60 */
	border: 2px solid rgba(131, 126, 118, 0.79);
	border-radius: 4px;
}

.quote-error {
	display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    align-content: flex-end;
}

.switch > .option:hover {
	color: $blue;
	border: 2px solid $blue;
	background-color: #{$blue}10;		
}


.option {
	/* Auto layout */
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 20px;
	gap: 21px;

	//width: 330px;
	height: auto;
	min-height: 147.53px;
	max-width: 175px;

	background: #FFFFFF;

	/* Base/Overlay 60 */
	border: 2px solid rgba(131, 126, 118, 0.79);
	border-radius: 4px;

	/* Inside auto layout */
	//flex: none;
	//order: 0;
	//align-self: stretch;
	//flex-grow: 0;

	flex: 1 1 0px;
}

.option > .label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.selection-icon {
	width: 100%;
	max-width: 100%;
	height: auto;
}

.selection-content .option {
	flex: 1 1 0px;
	flex-direction: row;
	min-height: auto;
	min-width: calc(50% - 10px);
	padding: 10px;
	justify-content: space-between;
}

.selection-content .option:hover {
	color: $blue;
	border: 2px solid $blue;
	background-color: #{$blue}10;	
}

.selection-content .selected {
	color: $blue;
	border: 2px solid $blue;
	background-color: #{$blue}10;
}

.selection-content .option > .label {
	color: inherit;
	flex-direction: row;
	gap: 8px;
}

.selection-content .option > .label > input[type="radio" i] {
	min-width: 25px;
}

.selection-content .option:hover > .label > input[type="radio" i] {
	outline: 2px solid $blue;
}

.selection-content .option .selection-icon {
	width: auto;
	height: 100%;
	max-width: 40%;
	fill: black;
}

.selection-content .option:hover > .selection-icon {
	fill: $blue;
	color: $blue;
}

.info-container {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.info-content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-gap: 30px;
	height: min-content;
}

#getting-started .info-content .select {
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr;	
}

.info-container .info {
	display: flex;
	flex-direction: column;
	gap: 20px;
	max-width: 400px;
	height: auto;
}

.info-container .info > * {
	margin: 0;
}

.info-container .info > input, .info-container .info .arkly-input {
	padding-top: 18px;
	padding-bottom: 14px;
	border: none;
	border-bottom: 2px solid black;
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 1rem;
}

.info-container .info > input::placeholder, .info-container .info .arkly-input::placeholder {
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 1rem; 
}

.info-container .select {
	display: grid;
	grid-template-columns: 1fr 1fr;
	background-color: $oat;
	padding: 8px;
	border-radius: 5px;
	grid-gap: 5px;
}

.info-container .select > div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
}

.info-container .select label {
	font-size: 14px;
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 1rem;
	height: 10px;
	white-space: nowrap;
}

.taking-too-long {
	height: 75px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.quote-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	height: 100%;
	width: 100%;
}

#footer  {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: flex-end;
	padding: 0 20px 5px;
}

.quotes {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-gap: 10px;
	width: 100%;
	height: auto;
}

.quotes::last-child {
	top: 80px;
}

.quotes .quote {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	padding-bottom: 8px;
	border: none;
	border-bottom: 2px solid black;
	font-family: $header-family;
	font-weight: normal; 
	color: black;
	margin: 0;
	text-shadow: 0 0.5px 0 #585858;
	font-size: 0.8rem;
}

.quotes .quote:first-of-type {
	background-color: rgba(197, 153, 1, 0.4);
}

.quotes .quote strong {
	font-family: $header-family;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 160%;

	/* or 38px */
	letter-spacing: 1px;
	font-feature-settings: 'pnum' on, 'lnum' on;
}

.result-modal {
	padding: 0 5px;
}

@media screen and (max-width: $mobile-breakpoint) {

	.quotes {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 10px;
		width: 100%;
		height: auto;

	}

	.selection-container {
		overflow: clip;
	}

	.selection-content {
		display: flex;
		flex-direction: column;
		max-height: calc(100% - 100px);
		height: 100%;
		overflow: none;
		overflow-y: scroll;
	}

	.selection-content .option {
		flex: 1 1 0px;
		min-height: auto;
		padding: 10px;
		justify-content: space-between;
	}

	.selection-content .option > .label {
		flex-direction: row;
		gap: 8px;
	}

	.selection-content .option .selection-icon {
		width: auto;
		height: 100%;
	}

	.info-container {
		overflow-y: scroll;
		padding-top: 0;
		padding-bottom: 0;
	}

	.selection-container {
		overflow-y: scroll;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.carousel > * {
		overflow-y: scroll;
		padding-top: 0;
		padding-bottom: 0;
	}

	.option {
		flex-direction: row;
		max-width: 100%;
	}

	.info-content {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-gap: 30px;
		height: min-content;
	}
}
</style>
